import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;
import {  OnInit } from '@angular/core';
import { KFoodService } from 'src/app/services/k-food.service'

@Component({
  selector: 'app-kfood-list',
  templateUrl: './kfood-list.component.html',
  styleUrls: ['./kfood-list.component.css']
})

export class KfoodListComponent implements OnInit {
  spinnerForMEUnitListings : boolean = false;
  MEUnitListings: any;
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private KFoodService:KFoodService
  ) {}
  ngOnInit(): void {
    this.fetchDistricts();
    this.currentPage = this.KFoodService.getCurrentPage();
    this.totalPages = this.KFoodService.getTotalPages();
    this.selectedDistrict = this.KFoodService.getSelectedDistrict();
    this.type = this.KFoodService.getType();
  //  this.getKfoodListings()
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.getKfoodListings();  }
  //  
  else if (this.isDistrictAdmin() ||this.isMarketingDistrictAdmin ) {
   
    this.getMEUnitsListingsForDistrictAdmin();
  }

  // this.getKfoodListings();
  
   
    // Initialize component logic here
  }
  goToMeUnitDescription(profileID: string) {
    this.router.navigate(['/addKfood'], {
      queryParams: {
        profileID: profileID,
      },
    });
  
}


getTypeLabel(typeId: string): string {
  switch (typeId) {
    case 'ID_PMC':
      return 'Premium Cafe';
    case 'ID_JAH':
      return 'Janakeeya Hotel';
    case 'ID_CHN':
      return 'Kerala Chicken';
    case 'ID_NMA':
      return 'Nano Market';
    case 'ID_SHO':
      return 'Shopee';
    default:
      return 'Unknown';

  }
}

districts: any[] = [];
type: string = "";
unitTypeOptions: { id: string; label: string }[] = [
  { id: "ID_PMC", label: "Premium Cafe" },
  { id: "ID_JAH", label: "Janakeeya Hotel" },
  { id: "ID_CHN", label: "Kerala Chicken" },
  { id: "ID_NMA", label: "Nano Market" },
  { id: "ID_SHO", label: "Shopee" },
];
selectedDistrict:string = '';
currentPage: number = 1; // Current page number
totalPages: number = 1; // Total number of pages

getKfoodListings(page: number = this.currentPage, limit: number = 10) { // Added pagination parameters

  console.log('the selectedDistrict value is',this.selectedDistrict)
  console.log('the type value is',this.type)


  this.KFoodService.setCurrentPage(page); // Set current page to service
  this.KFoodService.setTotalPages(this.totalPages); // Set total pages to service
  this.KFoodService.setSelectedDistrict(this.selectedDistrict); // Set selected district to service
  this.KFoodService.setType(this.type); // Set type to service




  this.spinnerForMEUnitListings = true;
  const token = this.Authservice.getToken();
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
const params = new HttpParams()
      .set('selectedDistrict', this.selectedDistrict)
      .set('type', this.type);
  this.http
    .get(`${QS_ADMIN_URL}/getKfoods?page=${page}&limit=${limit}`, { headers, params }) // Updated API call for pagination
    .subscribe(
      (data:any) => {
        this.spinnerForMEUnitListings = false;
        this.MEUnitListings = data.items; // Assuming the response has an 'items' field
        this.currentPage = data.page; // Set current page from response
        this.totalPages = Math.ceil(data.total / limit); // Calculate total pages
        console.log('MEUnitListings....', this.MEUnitListings);
        // ... existing code ...
      },
      (error) => {
        // ... existing error handling ...
      }
    );
}

changePage(page: number) {
  if (page > 0 && page <= this.totalPages) {
    this.getKfoodListings(page); // Fetch listings for the new page
  }
}

getVisiblePages(): number[] {
  const pages: number[] = [];
  const maxVisiblePages = 5; // Maximum number of page links to show
  const startPage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(this.totalPages, startPage + maxVisiblePages - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  return pages;
}






getMEUnitsListingsForDistrictAdmin() {
  console.log('the district admin api is working')
  this.spinnerForMEUnitListings = true;
  const token = this.Authservice.getToken();
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
 
  // Get the user's district and role from the AuthService
  const userDistrict = this.Authservice.getDecodedDistrict();
  
console.log("gdfgdfgdfg",userDistrict);

  
  // Add query parameters for district and role
 
  
    // .set('roleType', roleType);
   
  this.http
    .get(QS_ADMIN_URL + '/getMEUnitsListingsForDistrictAdmin', { headers })
    .subscribe(
      (data:any) => {
        this.spinnerForMEUnitListings = false;
        console.log(data,"daaaaaataaa")

        this.MEUnitListings = data.filter((item: any) => item.address.districtName==userDistrict);
        console.log('MEUnitListings for district admin', this.MEUnitListings);
        // Handle success response if needed
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForMEUnitListings = false;
        }
        // H
        // ... (error handling remains the same)
      }
    );
}



isSuperAdmin(): boolean {
  return (
    this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
  );
}
isStateAdmin(): boolean {
  return (
    this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
  );
}
isDistrictAdmin(): boolean {
  return (
    this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
  );
}
isMarketingDistrictAdmin(): boolean {
  return (
    this.Authservice.isAuthenticated() && this.Authservice.isMarketingDistrictAdmin()
  );
}

search(){

 
  this.getSellerListingsForStateAdmin();
}
getSellerListingsForStateAdmin()
{

}
// selectedDistrict: string = '';
districtOFDistrictAdmin: any[] = [];
districtName: string = '';
// districts: any[] = [];

fetchDistricts(): void {
  this.http
    .get<any>("assets/cloudKitchenPlaces/districtBlocks/districts.json")
    .subscribe((data) => {
      this.districts = data.districts;
      console.log(this.districts);
      this.districtOFDistrictAdmin = [];
      for (let i = 0; i < this.districts.length; i++) {
        if (this.districts[i].name === this.districtName) {
          this.districtOFDistrictAdmin[0] = this.districts[i];
          console.log(this.districtOFDistrictAdmin);
          break; // Stop the loop once you find the matching district
        }
      }
    });
}
}

