<app-side-bar></app-side-bar>
<app-header></app-header>
<div class="container py-4 mainContainer" *ngIf="isAuthenticated">
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <!-- Tabs Navigation -->
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                [class.active]="activeTab === 'payouts'"
                (click)="setActiveTab('payouts')"
                href="javascript:void(0)"
              >
                Payout Management
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [class.active]="activeTab === 'reports'"
                (click)="setActiveTab('reports')"
                href="javascript:void(0)"
              >
                Seller Reports
              </a>
            </li>
          </ul>
        </div>

        <!-- Payouts Tab Content -->
        <div class="card-body" *ngIf="activeTab === 'payouts'">
          <div class="row align-items-center mb-4">
            <div class="col">
              <div class="alert alert-info" role="alert">
                <i class="fas fa-info-circle me-2"></i>
                Click "Generate Payouts" to process payouts for eligible orders
                (delivered orders older than 10 days with no replacements).
              </div>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary"
                (click)="generatePayouts()"
                [disabled]="generating"
              >
                <span
                  *ngIf="generating"
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ generating ? "Generating..." : "Generate Payouts" }}
              </button>
            </div>
          </div>

          <!-- Payouts Table -->
          <div class="table-responsive mb-4">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th>Payout ID</th>
                  <th>Generated On</th>
                  <th>
                    Final Amount<br />
                    <small class="text-muted">(incl. logistics)</small>
                  </th>
                  <th>Status</th>
                  <th class="text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="loading">
                  <td colspan="4" class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="!loading && payouts.length === 0">
                  <td colspan="4" class="text-center">
                    No payouts generated yet
                  </td>
                </tr>
                <tr *ngFor="let payout of payouts">
                  <td>
                    <span class="text-primary">{{ payout.payoutID }}</span>
                  </td>
                  <td>
                    <small>{{ payout.payoutDate | date : "medium" }}</small>
                  </td>
                  <td>
                    <strong class="text-success"
                      >₹{{
                        payout.payoutAmount + payout.totalLogisticsCharges
                      }}</strong
                    >
                  </td>
                  <td>
                    <span [class]="payout.payoutStatus === 'completed' ? 'badge bg-success' : 'badge bg-warning'">
                      {{ payout.payoutStatus | titlecase }}
                    </span>
                    <br>
                    <small class="text-muted" *ngIf="payout.completedDate">
                      Completed on: {{ payout.completedDate | date:'medium':'IST' }}
                    </small>
                  </td>
                  <td>
                    <div class="d-flex justify-content-end align-items-center gap-2">
                      <button
                        class="btn btn-sm"
                        [class.btn-success]="payout.payoutStatus === 'pending'"
                        [class.btn-warning]="payout.payoutStatus === 'completed'"
                        (click)="updatePayoutStatus(payout.payoutID, payout.payoutStatus)"
                        [disabled]="updatingStatus || payout.payoutStatus === 'completed'"
                        *ngIf="payout.payoutStatus !== 'completed'"
                      >
                        <i class="fas" [class.fa-check-circle]="payout.payoutStatus === 'pending'" [class.fa-undo]="payout.payoutStatus === 'completed'"></i>
                        {{ payout.payoutStatus === 'pending' ? 'Mark Completed' : 'Mark Pending' }}
                      </button>
                      <button
                        class="btn btn-sm btn-outline-success"
                        (click)="downloadExcel(payout.payoutID)"
                        title="Download Excel"
                      >
                        <i class="fas fa-file-excel"></i> Download Excel
                      </button>
                      <button
                        class="btn btn-sm btn-outline-danger"
                        (click)="openDeleteModal(payout)"
                        title="Delete Payout"
                        *ngIf="payout.payoutStatus !== 'completed'"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Pagination -->
          <div
            class="d-flex justify-content-center mt-4"
            *ngIf="totalPages > 1"
          >
            <nav aria-label="Page navigation">
              <ul class="pagination">
                <li class="page-item" [class.disabled]="currentPage === 1">
                  <a
                    class="page-link"
                    (click)="onPageChange(currentPage - 1)"
                    role="button"
                  >
                    Previous
                  </a>
                </li>
                <li
                  class="page-item"
                  *ngFor="let page of [].constructor(totalPages); let i = index"
                  [class.active]="currentPage === i + 1"
                >
                  <a
                    class="page-link"
                    (click)="onPageChange(i + 1)"
                    role="button"
                  >
                    {{ i + 1 }}
                  </a>
                </li>
                <li
                  class="page-item"
                  [class.disabled]="currentPage === totalPages"
                >
                  <a
                    class="page-link"
                    (click)="onPageChange(currentPage + 1)"
                    role="button"
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <!-- Reports Tab Content -->
        <div class="card-body" *ngIf="activeTab === 'reports'">
          <div class="alert alert-info mb-4">
            Generate a report of TDS and TCS collected from sellers within a
            date range
          </div>
          <div class="row g-3 align-items-end mb-4">
            <div class="col-auto">
              <label class="form-label">From Date</label>
              <input type="date" class="form-control" [(ngModel)]="fromDate" />
            </div>
            <div class="col-auto">
              <label class="form-label">To Date</label>
              <input type="date" class="form-control" [(ngModel)]="toDate" />
            </div>
            <div class="col-auto">
              <button
                class="btn btn-success"
                (click)="generateSellerReport()"
                [disabled]="generatingReport"
              >
                <span
                  *ngIf="generatingReport"
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ generatingReport ? "Generating..." : "Generate Report" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Payout</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-4">
            <h6>Payout Details:</h6>
            <div class="table-responsive">
              <table class="table table-sm table-borderless" *ngIf="selectedPayout">
                <tr>
                  <td class="text-muted">Payout ID:</td>
                  <td>{{ selectedPayout.payoutID }}</td>
                </tr>
                <tr>
                  <td class="text-muted">Generated On:</td>
                  <td>{{ selectedPayout.payoutDate | date:'medium' }}</td>
                </tr>
                <tr>
                  <td class="text-muted">Amount:</td>
                  <td>₹{{ selectedPayout.payoutAmount + selectedPayout.totalLogisticsCharges }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="alert alert-danger">
            <i class="fas fa-exclamation-triangle me-2"></i>
            Are you sure you want to delete this payout? This action cannot be undone.
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" (click)="confirmDelete(selectedPayout.payoutID)">
            Delete Payout
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
