<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="analytics"><u>Products Listings</u></p>
    </div>
    <div class="col">
      <p class="pageReference">Products > Products Listing</p>
    </div>
  </div>

  <!-- Add tabs for search methods -->
  <ul class="nav nav-tabs mb-0">
    <li class="nav-item">
      <a
        class="nav-link rounded-top"
        [ngClass]="{ 'active selectedTab  text-white': activeTab === 'date' }"
        (click)="setDateTab()"
        >Search by Date</a
      >
    </li>
    <li class="nav-item" *ngIf="isStateAdmin() || isSuperAdmin()">
      <a
        class="nav-link rounded-top"
        [ngClass]="{ 'active selectedTab text-white': activeTab === 'filters' }"
        (click)="setFiltersTab()"
        >Search by District</a                                                                                              
      >
    </li>
    <!-- <li class="nav-item" *ngIf="isStateAdmin() || isSuperAdmin()">
      <a
        class="nav-link rounded-top"
        [ngClass]="{
          'active selectedTab text-white': activeTab === 'verification'
        }"
        (click)="setFiltersVerificationTab()"
        >Search by Verification / Approval</a
      >
    </li> -->
  </ul>

  <!-- Filter-based on verfication search card -->
  <!-- <div
    class="card mb-3"
    *ngIf="activeTab === 'verification'"
    style="border-top-left-radius: 0"
  >
    <div
      class="card-body bg-dark-blue"
      style="border-top-right-radius: 0.25rem"
    >
      <div class="row mb-3" *ngIf="isStateAdmin() || isSuperAdmin()">
        <div class="col-md-3">
          <label for="districtFilter" style="color: white" class="form-label"
            >Filter by Verification:</label
          >
          <select
            id="districtFilter"
            class="form-select"
            [(ngModel)]="selectedVerification"
          >
            <option value="" selected>All</option>
            <option value="true">Verified</option>
            <option value="false">Not Verified</option>
          </select>
        </div>

        <div class="col-md-3">
          <label for="roleTypeFilter" style="color: white" class="form-label"
            >Filter by Approval:</label
          >
          <select
            id="roleTypeFilter"
            class="form-select"
            [(ngModel)]="selectedApprovedStatus"
          >
            <option value="" selected>All</option>
            <option value="true">Approved</option>
            <option value="false">Not Approved</option>
          </select>
        </div>

        <div class="col-md-3">
          <label for="roleTypeFilter" style="color: white" class="form-label"
            >Filter by Rejected:</label
          >
          <select
            id="roleTypeFilter"
            class="form-select"
            [(ngModel)]="selectedRejectedStatus"
          >
            <option value="" selected>All</option>
            <option value="true">Rejected</option>
            <option value="false">Not Rejected</option>
          </select>
        </div>

        <div class="col-md-3 d-flex align-items-end">
          <button
            *ngIf="!spinnerForSearch"
            (click)="searchByFiltersVerification()"
            class="btn btn-light"
          >
            Search
          </button>
          <div
            *ngIf="spinnerForSearch"
            class="spinner-border text-light"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Filter-based search card -->
  <div
    class="card mb-3"
    *ngIf="activeTab === 'filters'"
    style="border-top-left-radius: 0"
  >
    <div
      class="card-body bg-dark-blue"
      style="border-top-right-radius: 0.25rem"
    >
      <div class="row mb-3" *ngIf="isStateAdmin() || isSuperAdmin()">
        <div class="col-md-3">
          <label for="districtFilter" style="color: white" class="form-label"
            >Filter by District:</label
          >
          <select
            id="districtFilter"
            class="form-select"
            [(ngModel)]="selectedDistrict"
          >
            <option value="" selected>All Districts</option>
            <option *ngFor="let district of districts" [value]="district.name">
              {{ district.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <label for="roleTypeFilter" style="color: white" class="form-label"
            >Filter by Role Type:</label
          >
          <select
            id="roleTypeFilter"
            class="form-select"
            [(ngModel)]="selectedRoleType"
          >
            <option value="">All Role Types</option>
            <option
              *ngFor="let category of adminCategory"
              [value]="category.value"
            >
              {{ category.label }}
            </option>
          </select>
        </div>


        <div class="col-md-3">
          <label for="districtFilter" style="color: white" class="form-label"
            >Filter by Verification:</label
          >
          <select
            id="districtFilter"
            class="form-select"
            [(ngModel)]="selectedVerification"
          >
            <option value="" selected>All</option>
            <option value="true">Verified</option>
            <option value="false">Not Verified</option>
          </select>
        </div>

        <div class="col-md-3">
          <label for="roleTypeFilter" style="color: white" class="form-label"
            >Filter by Approval:</label
          >
          <select
            id="roleTypeFilter"
            class="form-select"
            [(ngModel)]="selectedApprovedStatus"
          >
            <option value="" selected>All</option>
            <option value="true">Approved</option>
            <option value="false">Not Approved</option>
          </select>
        </div>

      </div>


      <div class="row mb-3" *ngIf="isStateAdmin() || isSuperAdmin()">

        <div class="col-md-3">
          <label for="roleTypeFilter" style="color: white" class="form-label"
            >Filter by Rejected:</label
          >
          <select
            id="roleTypeFilter"
            class="form-select"
            [(ngModel)]="selectedRejectedStatus"
          >
            <option value="" selected>All</option>
            <option value="true">Rejected</option>
            <!-- <option value="false">Not Rejected</option> -->
          </select>
        </div>
        <div class="col-md-3">
          <label for="roleTypeFilter" style="color: white" class="form-label"
            >Filter by Order Status </label
          >
          <select
            id="roleTypeFilter"
            class="form-select"
            [(ngModel)]="selectOrderStatus"
          >
            <option value="" selected>All</option>
            <option value="true">Online</option>
            <option value="false">Offline</option>

            <!-- <option value="false">Not Rejected</option> -->
          </select>
        </div>

         <div class="col-md-3 d-flex align-items-end">
          <button
            *ngIf="!spinnerForSearch"
            (click)="searchByFilters()"
            class="btn btn-light"
          >
            Search
          </button>
          <div
            *ngIf="spinnerForSearch"
            class="spinner-border text-light"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>


      </div>
    </div>
  </div>

  <!-- Date-based search card -->
  <div
    class="card mb-3"
    *ngIf="activeTab === 'date'"
    style="border-top-left-radius: 0"
  >
    <div
      class="card-body bg-dark-blue"
      style="border-top-right-radius: 0.25rem"
    >
      <div class="row">
        <div class="col">
          <div class="row card headerCard p-1 m-1">
            <div class="col pt-2">
              <mat-form-field appearance="fill" style="margin-right: 10px">
                <mat-label>Enter your start date</mat-label>
                <input
                  matInput
                  [max]="maxDate"
                  [(ngModel)]="startDate"
                  [matDatepicker]="firstPicker"
                  (dateChange)="onDateChange($event, 'First Date')"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="firstPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #firstPicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Enter your end date</mat-label>
                <input
                  matInput
                  [max]="maxDate"
                  [matDatepicker]="secondPicker"
                  [(ngModel)]="endDate"
                  (dateChange)="onDateChange($event, 'Second Date')"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="secondPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #secondPicker></mat-datepicker>
              </mat-form-field>

              <button
                *ngIf="!spinnerForSearch"
                (click)="searchByDate()"
                style="margin-left: 15px"
                type="button"
                class="btn btn-success"
              >
                Search
              </button>

              <div
                *ngIf="spinnerForSearch"
                style="margin-left: 15px"
                class="spinner-border text-success"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col ">Product ID</th>
            <th scope="col">Name</th>
            <th scope="col">Admin</th>
            <th scope="col">District</th>
            <th scope="col">Date</th>
            <th scope="col">Views</th>
            <th scope="col">Contacts</th>
            <th scope="col">Active</th>
            <th scope="col">District Verification</th>
            <th scope="col">State Approval</th>

            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody *ngFor="let productList of productList; let i = index">
          <tr>
            <th scope="row">{{ (currentPage - 1) * limit + i + 1 }}</th>
            <td  >
              <span
              *ngIf="!productList.sellOnline"
              class="badge rounded-pill bg-danger  "
              >Offline </span
            >
            
            <span
            *ngIf="productList.sellOnline"
            class="badge rounded-pill bg-success "
            >Online </span
          >
              {{ productList.productID }}
            </td>
            <td
              style="
                width: 200px;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ productList.productName }}
              <br />
              <span
                *ngIf="isProductRejected(productList.rejectStatus)"
                class="badge rounded-pill bg-danger"
              >
                {{ getReadableStatus(productList.rejectStatus) }}
              </span>
            </td>
            <!-- <td>
              {{ productList.productName }}
              <br>
              <span *ngIf="isProductRejected(productList.rejectStatus)" class="badge rounded-pill bg-danger">
                {{ getReadableStatus(productList.rejectStatus) }}
              </span>
            </td> -->
            <td>{{ roleTypeToLabel(productList.userCategory) }}</td>
            <td>{{ productList.userDistrict }}</td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>

              {{ convertUtcToIst(productList.productRegistrationDate) }}
            </td>
            <td>{{ productList.views }}</td>
            <td>
              {{ productList.contacts }}
            </td>
            <td>
              <span
                *ngIf="!productList.instock"
                class="badge rounded-pill bg-danger"
                >Inactive</span
              >
              <span
                *ngIf="productList.instock"
                class="badge rounded-pill bg-success"
                >Active</span
              >
            </td>
            <td>
              <span
                *ngIf="!productList.verifiedStatus"
                class="badge rounded-pill bg-danger"
                >Not Verified</span
              >
              <span
                *ngIf="productList.verifiedStatus"
                class="badge rounded-pill bg-success"
                >Verified</span
              >
            </td>
            <td>
              <span
                *ngIf="!productList.approvalStatus"
                class="badge rounded-pill bg-danger"
                >Not Approved</span
              >
              <span
                *ngIf="productList.approvalStatus"
                class="badge rounded-pill bg-success"
                >Approved</span
              >
            </td>

            <td>
              <button
                (click)="goToProductDescription(productList.productID)"
                type="button"
                class="btn btn-link"
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Your existing product list display -->
  <div *ngIf="productList && productList.length > 0">
    <!-- Display your products here -->
  </div>

  <!-- Pagination controls -->
  <div *ngIf="productList && productList.length > 0">
    <nav aria-label="Product list pagination" *ngIf="totalPages > 1">
      <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" (click)="onPageChange(currentPage - 1)"
            >Previous</a
          >
        </li>
        <li
          class="page-item"
          *ngFor="let page of getVisiblePages()"
          [class.active]="page === currentPage"
        >
          <a class="page-link" (click)="onPageChange(page)">{{ page }}</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" (click)="onPageChange(currentPage + 1)">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
