import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KFoodService {

  private currentPage: number = 1;
  private totalPages: number = 1;
  private selectedDistrict: string = '';
  private type: string = '';

  constructor() { }

  // Setters
  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setTotalPages(pages: number) {
    this.totalPages = pages;
  }

  setSelectedDistrict(district: string) {
    this.selectedDistrict = district;
  }

  setType(type: string) {
    this.type = type;
  }

  // Getters
  getCurrentPage(): number {
    return this.currentPage;
  }

  getTotalPages(): number {
    return this.totalPages;
  }

  getSelectedDistrict(): string {
    return this.selectedDistrict;
  }

  getType(): string {
    return this.type;
  }
}
