<div class="container sideBar">
  <div class="row p-4">
    <div class="col">
      <p class="sideBarHeading">
        <!-- <img
          class="logoImage"
          src="../../../assets/logo/quickServe.jpg"
          alt=""
        /> -->
        Products
      </p>
    </div>
  </div>

  <div class="row pt-2 ps-4 pe-4">
    <div class="col ps-0 pe-0">
      <p style="margin-bottom: 0; color: #c2bfbf; font-size: small">MENU</p>
    </div>
  </div>

  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isSuperAdmin() ||
      isStateAdmin() ||
      isDistrictAdmin() ||
      isMarketingAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToAnalyticsPage()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-chart-line" style="color: #ffffff"></i> Analytics
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isSuperAdmin() || isStateAdmin()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToCreateDistrictAdmin()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-plus" style="color: #ffffff"></i> Create District
        Admin
      </button>
    </div>
  </div>

  <div class="row pt-4 ps-4 pe-4" *ngIf="isSuperAdmin() || isStateAdmin()">
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToCreateMarketingDistrictAdmin()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-plus" style="color: #ffffff"></i> Create Marketing
        Admin
      </button>
    </div>
  </div>

  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isDistrictAdmin() ||
      isStateAdmin() ||
      isSuperAdmin() ||
      isMarketingAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToAddSellerPage()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-user-plus"></i> Add Seller
      </button>
    </div>
  </div>

  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isDistrictAdmin() ||
      isStateAdmin() ||
      isSuperAdmin() ||
      isMarketingAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToSellerList()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-list" style="color: #ffffff"></i> Sellers List
      </button>
    </div>
  </div>

  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isSuperAdmin() ||
      isStateAdmin() ||
      isDistrictAdmin() ||
      isMarketingAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToProductsListings()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-rectangle-list" style="color: #ffffff"></i>
        Products List
      </button>
    </div>
  </div>

  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isDistrictAdmin() ||
      isStateAdmin() ||
      isSuperAdmin() ||
      isMarketingAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToOrderDetailsPage()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-dolly" style="color: #ffffff"></i> Order Details
      </button>
    </div>
  </div>

  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isDistrictAdmin() ||
      isStateAdmin() ||
      isSuperAdmin() ||
      isMarketingAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToOnlineOrderDetailsPage()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-dolly" style="color: #ffffff"></i>Online Order
        Details
      </button>
    </div>
  </div>

  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="isStateAdmin() || isSuperAdmin() || isMarketingAdmin()"
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToPayoutsPage()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-cash-register" style="color: #ffffff"></i>Payouts
      </button>
    </div>
  </div>

  <!-- <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="isSuperAdmin() || isStateAdmin() || isDistrictAdmin()"
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToNotifications()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-bell"></i> Notifications
      </button>
    </div>
  </div> -->
  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isSuperAdmin() ||
      isDistrictAdmin() ||
      isMarketingAdmin() ||
      isStateAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goToAddKfoodDetails()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-bowl-food"></i> Cafe, Hotels & Shopees
      </button>
    </div>
  </div>
  <div
    class="row pt-4 ps-4 pe-4"
    *ngIf="
      isSuperAdmin() ||
      isStateAdmin() ||
      isDistrictAdmin() ||
      isMarketingAdmin()
    "
  >
    <div class="col ps-0 pe-0">
      <button
        type="button"
        (click)="goKFoodsListings()"
        class="btn btn-btn btn-secondary analyticsButton"
      >
        <i class="fa-solid fa-rectangle-list" style="color: #ffffff"> </i>
        Cafe, Hotels & Shopees List
      </button>
    </div>
  </div>
</div>
