import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestListingsService {
  public startDate: any = null;
  public endDate: any = null;

  public pendingRequests: boolean = false;
  public processingRequests: boolean = false;
  public assignedRequests: boolean = false;
  public acceptedRequests: boolean = false;
  public completedRequests: boolean = false;
  public cancelledRequests: boolean = false;
  private activeTab: string = 'date'; // Default to 'date' tab

  private currentPage: number = 1;
  private totalPages: number = 1;
  private limit: number = 25;

  constructor(private http: HttpClient) {}

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  getActiveTab(): string {
    return this.activeTab;
  }

  setDates(startDate: any, endDate: any): void {
    console.log('the start date is', startDate);
    console.log('the end date is', endDate);
    this.startDate = startDate;
    this.endDate = endDate;
    this.pendingRequests = false;
    this.processingRequests = false;
    this.assignedRequests = false;
    this.acceptedRequests = false;
    this.completedRequests = false;
    this.cancelledRequests = false;
    //  this.selectedOrderStatus = ''; 
  }

  getDates(): { startDate: any; endDate: any } {
    return { startDate: this.startDate, endDate: this.endDate };
  }

  selectedDistrict: string = '';
  districtOFDistrictAdmin: any[] = [];
  districtName: string = '';
  districts: any[] = [];
  selectedRoleType: string = '';

  selectedRejectedStatus: string = '';
  selectedApprovedStatus: string = '';
  selectedVerification: string = '';
  selectedOrderStatus:string='';
  selectOrderStatus:string=''
  
  private cachedProductList: any[] = [];
  private lastSearchParams: any = null;

  setCurrentPage(page: number): void {
    this.currentPage = page;
  }

  setTotalPages(total: number): void {
    this.totalPages = total;
  }

  searchForServiceListings(
    token: string,
    selectedVerification: string,
    selectedApprovedStatus: string,
    selectedRejectedStatus: string,
    districtName: string,
    roleType: string,
    selectedOrderStatus:string,
    page: number = 1
  ): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    let params = new HttpParams();
    if (this.startDate) params = params.set('startDate', this.startDate);
    if (this.endDate) params = params.set('endDate', this.endDate);
    if (selectedVerification && selectedVerification !== '') params = params.set('selectedVerification', selectedVerification);
    if (selectedApprovedStatus && selectedApprovedStatus !== '') params = params.set('selectedApprovedStatus', selectedApprovedStatus);
    if (selectedRejectedStatus && selectedRejectedStatus !== '') params = params.set('selectedRejectedStatus', selectedRejectedStatus);
    if (districtName && districtName !== '') params = params.set('district', districtName);
    if (roleType && roleType !== '') params = params.set('roleType', roleType);
    params = params.set('page', page.toString());
    if (this.limit) params = params.set('limit', this.limit.toString());
    if (this.selectedOrderStatus && this.selectedOrderStatus !== '') params = params.set('selectOrderStatus', this.selectedOrderStatus);

    console.log('the params are.....', params,selectedOrderStatus);

    return this.http
      .get<any>(`${QS_ADMIN_URL}/getProductList`, { headers, params })
      .pipe(
        map((response) => {
          this.cachedProductList = response.products;
          this.lastSearchParams = params;
          this.currentPage = response.currentPage;
          this.totalPages = response.totalPages;
          return response;
        })
      );
  }

  getCurrentPage(): number {
    return this.currentPage;
  }

  getTotalPages(): number {
    return this.totalPages;
  }

  searchForProductsListingsForDistrictAdmin(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('startDate', this.startDate)
      .set('endDate', this.endDate);

    return this.http.get<any>(
      `${QS_ADMIN_URL}/getProductListForDistrictAdmin`,
      { headers, params }
    );
  }

  searchForProductsListingsForMarketingDistrictAdmin(
    token: string
  ): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('startDate', this.startDate)
      .set('endDate', this.endDate);

    return this.http.get<any>(
      `${QS_ADMIN_URL}/getProductListForMarketingDistrictAdmin`,
      { headers, params }
    );
  }

  setCachedProductList(productList: any[]): void {
    this.cachedProductList = productList;
  }

  setSelectedDistrict(district: string): void {
    this.selectedDistrict = district;
  }

  getSelectedDistrict(): string {
    return this.selectedDistrict;
  }

  setSelectedRoleType(roleType: string): void {
    this.selectedRoleType = roleType;
  }

  getSelectedRoleType(): string {
    return this.selectedRoleType;
  }

  // to set the verification value
  setSelectedVerification(roleType: string): void {
    this.selectedVerification = roleType;
  }

  // to get the verification value
  getSelectedVerification(): string {
    return this.selectedVerification;
  }

 
  // to set the approval value
  setSelectedApprovedStatus(roleType: string): void {
    this.selectedApprovedStatus = roleType;
  }

  // to get the approval value
  getSelectedApprovedStatus(): string {
    return this.selectedApprovedStatus;
  }

   // to set the rejected value
   setSelectedRejectedStatus(roleType: string): void {
    this.selectedRejectedStatus = roleType;
  }

  // to get the rejected value
  getSelectedRejectedStatus(): string {
    return this.selectedRejectedStatus;
  }
   setSelectedOrderStatus(selectOrderStatus:string):void
  {
    this.selectedOrderStatus=selectOrderStatus
  }
  getSelectedOrderStatus():string{
  return this.selectedOrderStatus;

  }
}
