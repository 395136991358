<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Seller Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Kudumbashree Products > Seller Listings</p>
    </div>
  </div>

  <br />
 <!-- Add district filter -->
 <div class="card  mb-3" style="background-color: #0C4A6C;">
  <div class="card-body">
    <div class="row mb-3" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col-md-3">
        <label for="districtFilter" style="color: white;" class="form-label">Filter by District:</label>
        <select id="districtFilter" class="form-select" [(ngModel)]="selectedDistrict">
          <option value="" selected>All Districts</option>
          <option *ngFor="let district of districts" [value]="district.name">
            {{ district.name }}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <label for="roleTypeFilter" style="color: white;" class="form-label">Filter by Role Type:</label>
        <select id="roleTypeFilter" class="form-select" [(ngModel)]="selectedRoleType">
          <option value="">All Role Types</option>
          <option *ngFor="let category of adminCategory" [value]="category.value">
            {{category.label}}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <label for="sellerTypeFilter" style="color: white;" class="form-label">Filter by Seller Type:</label>
        <select id="sellerTypeFilter" class="form-select" [(ngModel)]="selectedSellerType">
          <option value="">All Sellers</option>
          <option value="online">Online Sellers</option>
          <option value="offline">Offline Sellers</option>
        </select>
      </div>

      <div class="col-md-3 d-flex align-items-end">
        <button (click)="search()" class="btn btn-light">Search</button>
      </div>
    </div>

    <!-- for marketing district admin -->
    <div class="row mb-3" *ngIf="isMarketingAdmin()">


      <div class="col-md-3">
        <label for="roleTypeFilter" style="color: white;" class="form-label">Filter by Role Type:</label>
        <select id="roleTypeFilter" class="form-select" [(ngModel)]="selectedRoleType">
          <option value="">All Role Types</option>
          <option *ngFor="let category of adminCategory" [value]="category.value">
            {{category.label}}
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <label for="sellerTypeFilter" style="color: white;" class="form-label">Filter by Seller Type:</label>
        <select id="sellerTypeFilter" class="form-select" [(ngModel)]="selectedSellerType">
          <option value="">All Sellers</option>
          <option value="online">Online Sellers</option>
          <option value="offline">Offline Sellers</option>
        </select>
      </div>

      <div class="col-md-3 d-flex align-items-end">
        <button (click)="search()" class="btn btn-light">Search</button>
      </div>
    </div>


    <div class="row mb-3" *ngIf="isDistrictAdmin()">


      <!-- <div class="col-md-3">
        <label for="roleTypeFilter" style="color: white;" class="form-label">Filter by Role Type:</label>
        <select id="roleTypeFilter" class="form-select" [(ngModel)]="selectedRoleType">
          <option value="">All Role Types</option>
          <option *ngFor="let category of adminCategory" [value]="category.value">
            {{category.label}}
          </option>
        </select>
      </div> -->

      <div class="col-md-3">
        <label for="sellerTypeFilter" style="color: white;" class="form-label">Filter by Seller Type:</label>
        <select id="sellerTypeFilter" class="form-select" [(ngModel)]="selectedSellerType">
          <option value="">All Sellers</option>
          <option value="online">Online Sellers</option>
          <option value="offline">Offline Sellers</option>
        </select>
      </div>

      <div class="col-md-3 d-flex align-items-end">
        <button (click)="search()" class="btn btn-light">Search</button>
      </div>
    </div>


  </div>
</div>


  <button
    *ngIf="spinnerForListingPage"
    class="btn btn-primary"
    type="button"
    disabled
  >
    <span
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
    ></span>
    Loading...
  </button>

  <div class="row" *ngIf="!spinnerForListingPage">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">ProfileID</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Role</th>
            <th scope="col">District</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Approval Status</th>
            <th scope="col">Account Status</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tr *ngIf="!sellerListings || sellerListings.length === 0">
          <td colspan="10" class="text-center py-4">
            No orders found
          </td>
        </tr>
        <tbody *ngFor="let sellerListing of sellerListings; let i = index">

          <tr>
            <!-- <th scope="row">{{ i + 1 }}</th> -->
            <th scope="row">{{ (currentPage - 1) * limit + i + 1 }}</th>

            <td> {{ sellerListing.meUnitName  }}
              <div><span
              *ngIf="!sellerListing.onlineSeller"
              class="badge rounded-pill bg-danger"
              >Offline </span>

              <span
            *ngIf="sellerListing.onlineSeller"
            class="badge rounded-pill bg-success"
            >Online </span></div></td>

            <td>{{ sellerListing.profileID }}</td>
            <td>{{ sellerListing.phoneNumber }}</td>
            <td>{{ roleTypeToLabel(sellerListing.category) }}</td>
            <td>{{ sellerListing.address.district }}</td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(sellerListing.registeredDate) }}
            </td>

            <td>
              <span
                [class.active]="sellerListing.approvalStatus"
                [class.pending]="!sellerListing.approvalStatus"
              >
                {{ !sellerListing.approvalStatus ? "Inactive" : "Active" }}
              </span>
            </td>

            <td>
              <span
                [class.active]="!sellerListing.accountSatus"
                [class.pending]="sellerListing.accountSatus"
              >
                {{ !sellerListing.accountSatus ? "Active" : "Deleted" }}
              </span>
            </td>
            <td
              style="text-align: center"
              (click)="goToDetails(sellerListing.profileID)"
            >
              <i
                class="fa-solid fa-square-caret-right fa-2xl"
                style="color: #0fbd5a"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>



    <nav aria-label="Page navigation example" *ngIf="totalPages > 1">
      <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" (click)="changePage(currentPage - 1)" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item" *ngFor="let page of getVisiblePages()" [class.active1]="currentPage === page">
          <a class="page-link" (click)="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" (click)="changePage(currentPage + 1)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
    </div>
  </div>
</div>

