<app-side-bar></app-side-bar>
<app-header></app-header>
<div class="container p-4 mainContainer">
  <div class="container1 mt-5">
    <div class="d-flex justify-content-center">
      <div class="card shadow-lg p-4" style="width: 28rem">
        <h2 class="text-center mb-4">REGISTRATION FORM</h2>
        <h4 class="text-center mb-4">
          <b
            >(Premium Cafe,Janakeeya Hotel,Kerala Chicken,Nano Market,Shopee)</b
          >
        </h4>
        <div class="row">
          <div class="col"></div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <!-- unit name -->
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p style="margin-bottom: 0; text-align: center">
                          <b>ME Unit Details</b>
                        </p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label"
                            >ME Unit Name
                            <span style="color: red">*</span></label
                          >
                          <input
                           
                            type="text"
                            [(ngModel)]="meUnitName"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="meUnitName"
                            maxlength="100"
                            placeholder="Enter ME Unit Name"
                            (keypress)="allowOnlyAlphanumericAndSpace($event)"
                          />
                        </div>
                      </div>

                      <div class="col">
                        <div class="mb-3">
                          <label for="inputState" class="form-label">
                            Enter Unit Type<span style="color: red">*</span>
                          </label>
                          <!-- <select
                          id="inputState"
                          class="form-select inputBoxes"
                          [(ngModel)]="type"
                          [ngModelOptions]="{ standalone: true }"
                          [disabled]="isTypeDisabled"
                        >
                          <option value="" disabled selected>Select an option</option>
                          <option *ngFor="let option of unitTypeOptions" [value]="option">
                            {{ option }}
                          </option>
                        </select> -->
                          <select
                            [(ngModel)]="type" 
                            name="type"
                            id="inputState"
                            [disabled]="isEdit"
                            class="form-select inputBoxes"
                          >
                            <option value="" disabled selected>
                              Select Unit Type
                            </option>
                            <option
                              *ngFor="let option of unitTypeOptions"
                              [value]="option.id"
                            >
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label"
                            >Phone Number
                            <span style="color: red">*</span></label
                          >
                          <input
                           [disabled]="isEdit"
                            type="text"
                            class="form-control"
                            [(ngModel)]="phoneNumber"
                            [ngModelOptions]="{ standalone: true }"
                            id="meUnitName"
                            placeholder="Enter Phone Number"
                            #phone1
                            (input)="checkphone1(phone1)"
                            (keypress)="validateAge($event)"
                            maxlength="10"
                            minlength="10"
                            pattern="[0-9]{10}"
                            required
                          />
                          <small
                            class="text-danger"
                            *ngIf="
                              phoneNumber &&
                              phoneNumber.toString().length !== 10
                            "
                          >
                            Phone number must be exactly 10 digits long.
                          </small>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label"
                            >WhatsApp Number
                            </label
                          >
                          <input
                          
                            type="text"
                            [(ngModel)]="whatsAppNumber"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="mewhatsapp"
                            maxlength="10"
                            minlength="10"
                            pattern="[0-9]{10}"
                            required
                            placeholder="Enter WhatsApp Number"
                            #whatsApp
                            (input)="checkWhatsApp(whatsApp)"
                            (keypress)="validateAge($event)"
                          />
                          <small
                            class="text-danger"
                            *ngIf="
                              whatsAppNumber &&
                              whatsAppNumber.toString().length !== 10
                            "
                          >
                            Whatsapp number must be exactly 10 digits long.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label">
                            ME Registration ID
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                           
                            [(ngModel)]="registrNo"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="meUnitId"
                            maxlength="25"
                            placeholder="Enter ME Registration  ID"
                            #regId
                            
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label"
                            >FSSAI ID</label
                          >
                          <input
                            type="text"
                           
                            [(ngModel)]="fssaiID"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="meUnitName"
                            maxlength="14"
                            placeholder="Enter FSSAI ID"
                            (keypress)="validateAge($event)"
                          />
                        </div>
                      </div>

                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label"
                            >GST Number</label
                          >
                          <input
                            type="text"
                            
                            [(ngModel)]="GSTNumber"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="meUnitName"
                            maxlength="15"
                            placeholder="Enter GST Number"
                            (keypress)="allowOnlyAlphanumeric($event)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <label for="productDescription" class="form-label">
                          Description </label
                        >
                        <textarea
                          class="form-control"
                          rows="3"
                          placeholder="Enter  Description "
                          id="floatingTextarea"
                          [(ngModel)]="description"
                          [ngModelOptions]="{ standalone: true }"
                          maxlength="1000"
                         
                        ></textarea>
                      </div>
                    </div>

                    <!----------------------------->
                  </mat-expansion-panel>
                </mat-accordion>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p style="margin-bottom: 0">
                          <b>Address</b>
                        </p>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label"
                            >Building Name
                            <span style="color: red">*</span></label
                          >
                          <input
                           
                            type="text"
                            [(ngModel)]="buildingName"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="meUnitName"
                            placeholder="Enter Building Name"
                            maxlength="100"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="meUnitName" class="form-label"
                            >Street Name
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            [(ngModel)]="streetName"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="meUnitName"
                            placeholder="Enter Street Name"
                            maxlength="150"
                            (keypress)="allowOnlyAlphanumericAndSpace($event)"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label for="meUnitName" class="form-label"
                              >Landmark </label
                            >
                            <input
                              type="text"
                              [(ngModel)]="landmark"
                              [ngModelOptions]="{ standalone: true }"
                              class="form-control"
                              id="meUnitName"
                              placeholder="Enter Landmark"
                              maxlength="150"
                              (keypress)="allowOnlyAlphanumericAndSpace($event)"
                            />
                          </div>
                        </div>

                        <div class="col" >
                          <div class="mb-0">
                            <label for="meUnitType" class="form-label"
                              >Pincode <span style="color: red">*</span></label
                            >
                            <input
                              type="text"
                              [(ngModel)]="pincode"
                              [ngModelOptions]="{ standalone: true }"
                              class="form-control"
                              id="meUnitName"
                              placeholder="Enter Pincode"
                              maxlength="6"
                              minlength="6"
                              pattern="[0-9]{6}"
                              (keypress)="validatePincode($event)"
                              
                              required
                            />
                            <small
                              class="text-danger"
                              *ngIf="pincode && pincode.toString().length !== 6"
                            >
                              Pincode must be exactly 6 digits long.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <br />

                        <div class="col" *ngIf="!isEdit">
                          <label class="form-label">
                            <p class="labelText">Select local body<span style="color: red">*</span></p>
                          </label>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="level"
                              id="blockLevel"
                              value="blockLevel"
                              [(ngModel)]="selectedLevel"
                              (change)="onLevelSelection('blockLevel')"
                              
                            />
                            <label class="form-check-label" for="blockLevel"
                              >Panchayat</label
                            >
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="level"
                              id="municipalityLevel"
                              value="municipalityLevel"
                              [(ngModel)]="selectedLevel"
                              (change)="onLevelSelection('municipalityLevel')"
                              [disabled]="!edit"
                            />
                            <label
                              class="form-check-label"
                              for="municipalityLevel"
                              >Municipality</label
                            >
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="level"
                              id="municipalCorporation"
                              value="municipalCorporation"
                              [(ngModel)]="selectedLevel"
                              (change)="
                                onLevelSelection('municipalCorporation')
                              "
                              [disabled]="!edit"
                            />
                            <label
                              class="form-check-label"
                              for="municipalCorporation"
                              >Municipal Corporation </label
                            >
                          </div>
                        </div>

                        <div class="col" *ngIf="!isEdit">
                          <label for="district" class="form-label required">
                            <p class="labelText">District <span style="color: red">*</span></p>
                          </label>
                          <!-- Dropdown for District -->
                          <select
                            *ngIf="isStateAdmin() || isSuperAdmin()"
                            class="form-select inputBoxes"
                            id="district"
                            [(ngModel)]="selectedDistrict"
                            name="district"
                            (change)="onDistrictChange()"
                          
                          >
                            <option value="" disabled selected>
                              Select District<span style="color: red">*</span>
                            </option>
                            <option
                              *ngFor="let district of districts"
                              [ngValue]="district"
                            >
                              {{ district.name }}
                            </option>
                          </select>

                          <select
                            *ngIf="isDistrictAdmin() || isMarketingAdmin()"
                            class="form-select inputBoxes"
                            id="district"
                            [(ngModel)]="selectedDistrict"
                            name="district"
                            (change)="onDistrictChange()"
                          >
                            <option value="" disabled selected>
                              Select District 
                            </option>
                            <option
                              *ngFor="let district of districtOFDistrictAdmin"
                              [ngValue]="district"
                            >
                              <div
                                *ngIf="district.name == districtName"
                                class="div"
                              >
                                {{ district.name }}
                              </div>
                              <!-- {{ district.name }} -->
                            </option>
                          </select>
                        </div>
                        <br />
                        <div class="col" *ngIf="selectedMunicipality">
                          <label for="municipality" class="form-label required">
                            <p class="labelText">Municipality</p>
                          </label>
                          <select
                            class="form-select inputBoxes"
                            id="municipality"
                            [(ngModel)]="selectedMunicipal"
                            name="municipality"
                            (click)="onMunicipalityChange()"
                          >
                            <option value="" disabled selected>
                              Select Municipality <span style="color: red">*</span>
                            </option>
                            <option
                              *ngFor="let municipality of selectedMunicipality"
                              [ngValue]="municipality"
                            >
                              {{ municipality.name }}
                            </option>
                          </select>
                        </div>
                        <br *ngIf="selectedMunicipality" />
                        <div class="col" *ngIf="selectedMunicipalCorporation">
                          <label
                            for="districtMunicipalCorporation"
                            class="form-label required"
                          >
                            <p class="labelText">
                              District Municipal Corporation <span style="color: red">*</span>
                            </p>
                          </label>
                          <select
                            class="form-select inputBoxes"
                            id="districtMunicipalCorporation"
                            [(ngModel)]="selectedMunicipalCorp"
                            name="districtMunicipalCorporation"
                            (click)="onMunicipalCorporationChange()"
                          >
                            <option value="" disabled selected>
                              Select District Municipal Corporation
                            </option>
                            <option
                              *ngFor="let mnc of selectedMunicipalCorporation"
                              [ngValue]="mnc"
                            >
                              {{ mnc.name }}
                            </option>     
                          </select>
                        </div>
                        <br *ngIf="selectedMunicipalCorporation" />
                        <div
                          class="col"
                          *ngIf="selectedDistrict && gramPanchayatsLoaded"
                        >
                          <label for="block" class="form-label required">
                            <p class="labelText">  Block <span style="color: red">*</span></p>
                          </label>
                          <!-- Dropdown for Block -->
                          <select
                            class="form-select inputBoxes"
                            id="block"
                            [(ngModel)]="selectedBlk"
                            name="block"
                            (change)="onBlockChange()"
                          >
                            <option value="" disabled selected>
                             
                            </option>
                            <option
                              *ngFor="let block of blocks"
                              [ngValue]="block"
                            >
                              {{ block.name }}
                            </option>
                          </select>
                        </div>
                        <br *ngIf="selectedDistrict && gramPanchayatsLoaded" />
                        <div class="col" *ngIf="selectedBlock">
                          <label
                            for="gramPanchayat"
                            class="form-label required"
                          >
                            <p class="labelText">Grama Panchayat <span style="color: red">*</span></p>
                          </label>
                          <!-- Dropdown for Gram Panchayat -->
                          <select
                            class="form-select inputBoxes"
                            id="gramPanchayat"
                            [(ngModel)]="selectedGP"
                            name="gramPanchayat"
                            (change)="onPChange()"
                          >
                            <option value="" disabled selected>
                              Select Grama Panchayat
                            </option>
                            <option
                              *ngFor="let gp of gramPanchayats"
                              [ngValue]="gp"
                            >
                              {{ gp.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row mt-0"  >
                        <div class="col">
                          <div class="mb-1" *ngIf="isEdit" >
                            <label for="selectedDistrictName" class="form-label">Selected District</label>
                            <input 
                              type="text"
                              class="form-control"
                              id="selectedDistrictName"
                             
                              value={{districtName}}
                              readonly
                              [disabled]="isEdit"
                            />
                          </div>
                        </div>
                        <div class="col" >
                          <div class="mb-3"  *ngIf="isEdit">
                            <label for="selectedBlockName" class="form-label">Selected Block</label>
                            <input
                              type="text"
                              class="form-control"
                              id="selectedBlockName"
                             value={{blockName}}
                              readonly
                              [disabled]="isEdit"
                             
                            />
                          </div>
                        </div>
                        <div class="col" >
                          <div class="mb-3" *ngIf="isEdit">
                            <label for="selectedGPName" class="form-label">Selected GP/Munci/Corp</label>
                            <input
                              type="text"
                              class="form-control"
                              id="selectedGPName"
                            value={{localBodyName}}
                              readonly
                              [disabled]="isEdit"
                            />
                          </div>
                        </div>
                        
                      </div>
                     
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label for="latitude" class="form-label"
                              >Latitude <span style="color: red">*</span></label
                            >
                            <input
                              type="text"  
                              [(ngModel)]="latitude"
                              [ngModelOptions]="{ standalone: true }"
                              class="form-control"
                              id="latitude"
                              placeholder="Enter Latitude"
                              pattern="[0-9]"
                              (keypress)="validateCoordinate($event, true)" 
                        
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label for="longitude" class="form-label"
                              >Longitude <span style="color: red">*</span></label
                            >
                            <input
                            type="text"  
                            [(ngModel)]="longitude"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="latitude"
                            placeholder="Enter Latitude"
                            pattern="[0-9]"
                            (keypress)="validateCoordinate($event, true)" 
                          
                          />
                            <!-- <input
                              type="text"  
                              [(ngModel)]="longitude"
                              [ngModelOptions]="{ standalone: true }"
                              class="form-control"
                              id="longitude"
                              placeholder="Enter Longitude"
                              pattern="[0-9]"
                              (keypress)="validateCoordinate($event, false)"
                              (paste)="onPasteCoordinate($event)"
                            /> -->
                          </div>
                        </div>
                        <a href="#" (click)="openLocationHelpModal($event)">
                          How to select exact location?
                        </a>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="locationHelpModal" tabindex="-1" aria-labelledby="locationHelpModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 style="font-size: medium;"   class="modal-title" id="locationHelpModalLabel">How to Select Exact Location</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <!-- Add your instructions here -->
                <p ><b>To select the exact location:</b></p>
                <ol>
                  <li>Open <b>Google Maps </b>in a new tab.</li>
                  <li>Search for your desired <b>location</b>.</li>
                  <li>Click on the <b>exact spot</b> on the map.</li>
                  
                  <li>The <b>latitude and longitude </b> will appear in the info card at the bottom.(eg: 10.761199, 76.707080)</li>
                  <li><b>Copy these coordinates and paste them into the respective fields.</b>
                 ( In the above eg: latitude will be 10.761199 and Longitude will be 76.707080).</li>
                </ol>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p style="margin-bottom: 0">
                  <b>Working Hours & Days Details <span style="color: red">*</span></b>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- <div class="col-md-6" *ngIf="!isEdit">
                <div class="mb-3">
                
                </div>
              </div> -->
            



<!-- first -->

              <div class="col-md-6" *ngIf="!isEdit">
                <div class="working-days-container" >
                  <div class="form-check" *ngFor="let day of workingDays; let i = index">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [id]="'day-' + i"
                      [value]="day" 
                      [checked]="isSelected(day)"
                      (change)="onCheckboxChange($event)"

                    />
                    <label class="form-check-label" [for]="'day-' + i">
                      {{ day }}
                    </label>
                  </div>
                </div>
              </div>
              


                  <!-- ---------------------------------------------------------------------------------------------------------------------------- -->
             
<!-- ............................................................................................................................................... -->
              <div class="col-md-6" *ngIf="isEdit">
                <div class="working-days-container">
                  <div class="form-check" *ngFor="let day of workingDays; let i = index">
                    <input
                    class="form-check-input"
                    type="checkbox"
                    [value]="day"
                    [checked]="isSelected(day)"
                    (change)="onCheckboxChange($event)"
                  />
                    <label class="form-check-label" [for]="'day-' + i">
                      {{ day }}
                    </label>
                  </div>
                </div>
              </div>
           
              
               
           
            </div>
           
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="openingTime" class="form-label"
                    >Opening Time<span style="color: red">*</span></label
                  >
                  <input
                    type="time"
                    class="form-control"
                    id="openingTime"
                    [(ngModel)]="openingTime"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="closingTime" class="form-label"
                    >Closing Time <span style="color: red">*</span></label
                  >
                  <input
                    type="time"
                    class="form-control"
                    id="closingTime"
                    [(ngModel)]="closingTime"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </div>
            <!-- ... rest of the code ... -->
          </mat-expansion-panel>
        </mat-accordion>
        <br />

        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p style="margin-bottom: 0; text-align: center">
                  <b>Menu Details</b>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <button
                    type="button"
                    [disabled]="menuItems.length >= 10"
                    class="btn btn-primary"
                    (click)="showAddMenuItemForm()"
                  >
                    Add Menu Items
                  </button>
                </div>
              </div>
            </div>

            <!-- New form for adding menu items -->
            <div *ngIf="showMenuItemForm" class="mt-3" >
              <h4>Add Menu Item</h4>
              <form (ngSubmit)="addMenuItem()" >
                <div class="mb-3">
                  <label for="menuItemName" class="form-label"
                    >Item Name </label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="menuItemName"
                    [(ngModel)]="newMenuItem.itemName"
                    name="name"
                    
                  />
                </div>
                <div class="mb-3">
                  <label for="menuItemDescription" class="form-label"
                    >Description</label
                  >
                  <textarea
                    class="form-control"
                    id="menuItemDescription"
                    [(ngModel)]="newMenuItem.itemDescription"
                    name="description"
                    rows="3"
                  ></textarea>
                </div>
                <div class="mb-3">
                  <label for="menuItemPrice" class="form-label"
                    >Price </label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="menuItemPrice"
                    [(ngModel)]="newMenuItem.itemPrice"
                    name="price"   maxlength="5"
                    (input)="validatePrice($event)"

                    
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  [disabled]="menuItems.length >= 10"
                >
                  Add Item
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ms-2"
                  (click)="cancelAddMenuItem()"
                >
                  Cancel
                </button>
                
              </form>
            </div>

            <!-- Display added menu items -->
            <!-- <div *ngIf="menuItems.length > 0" class="mt-3">
              <h4>Menu Items</h4>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Description</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of menuItems">
                    <td>
                      <strong>{{ item.itemName }}</strong>
                    </td>
                    <td>{{ item.itemDescription || "N/A" }}</td>
                    <td>₹{{ item.itemPrice }}</td>
                  </tr>
                </tbody>
              </table>
            </div> -->

 <div *ngIf="menuItems.length > 0" class="mt-3" >
      <h4>Menu Items</h4>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Description</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of menuItems; let i = index">
            <td>
              <strong>{{ item.itemName }}</strong>
            </td>
            <td>{{ item.itemDescription || "N/A" }}</td>
            <td>₹{{ item.itemPrice }}</td>
            <td>
              <button class="btn btn-sm btn-danger" (click)="removeMenuItem(i)"  >Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>




          </mat-expansion-panel>
        </mat-accordion>

        <div class=" p-2 mb-3 " >
          <div class="row ">
            <div class="col"> 
              <!--Image upload section starts-->
              <div
                *ngIf="
                  (checkProductImage &&
                    checkProductImage.endsWith('_thumbnailImage.jpg')) ||
                  checkProductImage == ''
                "
              >
                <div class="row">
                  <label>
                    <h6 style="color: rgb(15, 15, 15); font-size: small;">
                    <b>  Upload Images:</b><span style="color: red">*</span>
                      <span style="color: rgb(15, 15, 15)"> <b>(3 images are required. Image size should be less than</b><b style="color: red">  5 MB.</b> </span>
                    </h6>
                  </label>
                </div>
                <div class="container p-3 ">
                  <div class="row">
                    <div class="col">
                      <div class="imagePreview">
                        <img
                          class="uploadedImage"
                          [src]="
                            selectedImageUrls[0] ||
                            productImage1 ||
                            defaultImages
                          "
                          alt="selected images"
                        
                        />
                        <button
                          type="button"
                          (click)="fileInput1.click()"
                          class="btn btn-light imageUploadButtons btn-sm transparent-button"
                        >
                          Front side
                          <span style="color: red">*</span>
                        </button>
                        <input
                          type="file"
                          accept="image/*"
                          #fileInput1
                          style="display: none"
                          (change)="onImageSelected($event, 0)"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <ng-container>
                        <!-- Show "+" sign when the first image is not selected -->
                        <div
                          class="add-image-placeholder"
                          *ngIf="!selectedImageUrls[0] && !productImage1"
                        >
                          <i class="fas fa-plus"></i>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="selectedImageUrls[0] || productImage1"
                      >
                        <div class="imagePreview">
                          <img
                            class="uploadedImage"
                            [src]="
                              selectedImageUrls[1] ||
                              productImage2 ||
                              defaultImages
                            "
                            alt="selected images"
                           
                          />
                          <button
                            type="button"
                            (click)="fileInput2.click()"
                            class="btn btn-light imageUploadButtons btn-sm transparent-button"
                          >
                            Back side
                            <span style="color: red">*</span>
                          </button>
                          <input
                            type="file"
                            accept="image/*"
                            #fileInput2
                            style="display: none"
                            (change)="onImageSelected($event, 1)"
                          />
                        </div>
                      </ng-container>
                    </div>
                    <div class="col">
                      <ng-container>
                        <!-- Show "+" sign when the first image is not selected -->
                        <div
                          class="add-image-placeholder"
                          *ngIf="!selectedImageUrls[1] && !productImage2"
                        >
                          <i class="fas fa-plus"></i>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="selectedImageUrls[1] || productImage2"
                      >
                        <div class="imagePreview">
                          <img
                            class="uploadedImage"
                            [src]="
                              selectedImageUrls[2] ||
                              productImage3 ||
                              defaultImages
                            "
                            alt="selected images"
                           
                          />
                          <button
                            type="button"
                            (click)="fileInput3.click()"
                            class="btn btn-light imageUploadButtons btn-sm transparent-button"
                          >
                            Select Image
                          </button>
                          <input
                            type="file"
                            accept="image/*"
                            #fileInput3
                            style="display: none"
                            (change)="onImageSelected($event, 2)"
                          />
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <!--Image upload section ends-->
            </div>
          </div>
        </div>  

        <div class="row mt-4">
          <div class="col"></div>
          <div class="col"></div>

          <div class="col text-center">
            <button class="btnsubmit" 
              *ngIf="!isEdit"
              type="button"
              class="btn btn-primary btn-lg"
              (click)="submitKFoodsData()"
            >
              Submit Registration
            </button>
          </div>
<!-- <div class="col"></div> -->
         
<div class="col">
            <button *ngIf="isEdit"
              type="button"
              class="btn btn-primary btn-lg"
              (click)="updateKFoodsData()"
              
            >
              Update
            </button>
          </div>
          <div class="col">
            <button *ngIf="isEdit"
              type="button"
              class="btn btn-danger btn-lg"
              (click)="deleteKFoodsData()"
            >
              Delete
            </button>
          </div>
          </div>
        </div>
      </div>
  
    </div>
  
</div>
