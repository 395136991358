import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';

const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.css'],
})
export class PayoutsComponent implements OnInit {
  payouts: any[] = [];
  loading: boolean = false;
  generating: boolean = false;
  currentPage: number = 1;
  totalPages: number = 0;
  itemsPerPage: number = 10;
  tdsPercentage: number = 0.1; // Default 0.1%
  tcsPercentage: number = 1.0; // Default 1%
  isAuthenticated: boolean = false;
  showSellerReport: boolean = false;
  fromDate: string = '';
  toDate: string = '';
  generatingReport: boolean = false;
  activeTab: string = 'payouts';
  updatingStatus: boolean = false;
  private deleteModal: Modal | null = null;
  selectedPayout: any = null;

  constructor(
    private http: HttpClient,
    private Authservice: AuthServiceService
  ) {}

  ngOnInit(): void {
    this.verifyPin();
    // Initialize the modal
    const modalElement = document.getElementById('deleteModal');
    if (modalElement) {
      this.deleteModal = new Modal(modalElement);
    }
  }

  dontVerifyPin() {
    this.isAuthenticated = true;
    this.fetchPayouts();
  }

  verifyPin() {
    Swal.fire({
      title: 'Enter Passcode',
      input: 'password',
      inputAttributes: {
        maxlength: '6',
        pattern: '[0-9]*',
        autocomplete: 'off',
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter the Passcode';
        }
        if (value.length !== 6 || !/^\d+$/.test(value)) {
          return 'Passcode is invalid';
        }
        return null;
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: 'Verify',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.validatePin(result.value);
      } else {
        // If user cancels, redirect them away from this page
        window.history.back();
      }
    });
  }

  validatePin(pin: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .post(`${QS_ADMIN_URL}/verifyPayoutPin`, { pin }, { headers })
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.isAuthenticated = true;
            this.fetchPayouts();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Invalid Passcode',
              text: 'Please try again',
            }).then(() => {
              this.verifyPin();
            });
          }
        },
        error: (error) => {
          console.error('Error verifying Passcode:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to verify Passcode',
          }).then(() => {
            this.verifyPin();
          });
        },
      });
  }

  fetchPayouts(page: number = 1) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.loading = true;
    this.http
      .get(`${QS_ADMIN_URL}/payouts?page=${page}&limit=${this.itemsPerPage}`, {
        headers,
      })
      .subscribe({
        next: (response: any) => {
          this.payouts = response.data;
          this.currentPage = response.currentPage;
          this.totalPages = response.totalPages;
          this.loading = false;
          console.log('payouts:', this.payouts);
        },
        error: (error) => {
          console.error('Error fetching payouts:', error);
          this.loading = false;
        },
      });
  }

  generatePayouts() {
    Swal.fire({
      title: 'Confirm Payout Generation',
      html: `
        <div class="mb-3">
          <p>Please confirm the following deduction rates:</p>
          <div class="text-start">
            <p><strong>TDS Rate:</strong> ${this.tdsPercentage}%</p>
            <p><strong>TCS Rate:</strong> ${this.tcsPercentage}%</p>
          </div>
        </div>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, generate payouts',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.executePayoutGeneration();
      }
    });
  }

  executePayoutGeneration() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.generating = true;
    this.http
      .post(
        `${QS_ADMIN_URL}/generatePayouts`,
        {
          tdsPercentage: this.tdsPercentage,
          tcsPercentage: this.tcsPercentage,
        },
        { headers }
      )
      .subscribe({
        next: (response: any) => {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Payouts generated successfully',
            timer: 2000,
            showConfirmButton: false,
          });
          this.generating = false;
          this.fetchPayouts(1);
        },
        error: (error) => {
          console.error('Error generating payouts:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to generate payouts',
            timer: 2000,
            showConfirmButton: false,
          });
          this.generating = false;
        },
      });
  }

  onPageChange(page: number) {
    this.fetchPayouts(page);
  }

  downloadExcel(payoutID: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(`${QS_ADMIN_URL}/downloadPayoutExcel/${payoutID}`, {
        headers,
        responseType: 'blob', // Important for file download
      })
      .subscribe({
        next: (blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `Payout_${payoutID}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: (error) => {
          console.error('Error downloading excel:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to download excel file',
          });
        },
      });
  }

  openDeleteModal(payout: any) {
    this.selectedPayout = payout;
    this.deleteModal?.show();
  }

  confirmDelete(payoutID: string) {
    this.deleteModal?.hide();
    Swal.fire({
      title: 'Enter PIN to Delete',
      text: 'Please enter the delete PIN to confirm this action',
      input: 'password',
      inputAttributes: {
        maxlength: '6',
        pattern: '[0-9]*',
        autocomplete: 'off',
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter the PIN';
        }
        if (value.length !== 6 || !/^\d+$/.test(value)) {
          return 'PIN must be 6 digits';
        }
        return null;
      },
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#dc3545',
      showLoaderOnConfirm: true,
      preConfirm: (pin) => {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        return this.http
          .post(`${QS_ADMIN_URL}/verifyPayoutPin`, 
            { pin, action: 'delete' }, 
            { headers }
          )
          .toPromise()
          .then((response) => {
            if (response && response['success']) {
              return true;
            }
            throw new Error('Invalid PIN');
          })
          .catch((error) => {
            Swal.showValidationMessage('Invalid delete PIN');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((pinResult) => {
      if (pinResult.isConfirmed) {
        this.deletePayout(payoutID);
      }
    });
  }

  deletePayout(payoutID: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .delete(`${QS_ADMIN_URL}/deletePayout/${payoutID}`, { headers })
      .subscribe({
        next: (response: any) => {
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Payout has been deleted successfully',
            timer: 2000,
            showConfirmButton: false,
          });
          this.fetchPayouts(this.currentPage);
        },
        error: (error) => {
          console.error('Error deleting payout:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to delete payout',
            timer: 2000,
            showConfirmButton: false,
          });
        },
      });
  }

  toggleSellerReport() {
    this.showSellerReport = !this.showSellerReport;
  }

  generateSellerReport() {
    if (!this.fromDate || !this.toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please select both From and To dates',
      });
      return;
    }

    this.generatingReport = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(
        `${QS_ADMIN_URL}/downloadSellerPayoutsReport?fromDate=${this.fromDate}&toDate=${this.toDate}`,
        {
          headers,
          responseType: 'blob',
        }
      )
      .subscribe({
        next: (blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `Seller_Payouts_${this.fromDate}_to_${this.toDate}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          this.generatingReport = false;
        },
        error: (error) => {
          console.error('Error generating report:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to generate report',
          });
          this.generatingReport = false;
        },
      });
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  updatePayoutStatus(payoutID: string, currentStatus: string) {
    if (currentStatus === 'completed') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Cannot update status of completed payout',
      });
      return;
    }

    const newStatus = currentStatus === 'pending' ? 'completed' : 'pending';
    
    Swal.fire({
      title: 'Confirm Status Change',
      text: `Are you sure you want to mark this payout as completed? This action cannot be undone.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, complete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.updatingStatus = true;
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

        this.http.put(`${QS_ADMIN_URL}/updatePayoutStatus/${payoutID}`, 
          { status: newStatus },
          { headers }
        ).subscribe({
          next: (response: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Updated!',
              text: 'Payout has been marked as completed.',
              timer: 2000,
              showConfirmButton: false
            });
            this.fetchPayouts(this.currentPage);
          },
          error: (error) => {
            console.error('Error updating payout status:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: error.error.message || 'Failed to update payout status',
              timer: 2000,
              showConfirmButton: false
            });
          },
          complete: () => {
            this.updatingStatus = false;
          }
        });
      }
    });
  }
}
