import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location, Time } from "@angular/common";
import { environment } from "../../environments/environment";
const BACKEND_URL = environment.qsAdminUrl;
import Swal from "sweetalert2";
import { AuthServiceService } from "src/app/auth-service.service";
import { ReturnStatement } from "@angular/compiler";
import { MatDialog } from '@angular/material/dialog';
import { Modal } from 'bootstrap';

@Component({
  selector: "app-add-kfood-details",
  templateUrl: "./add-kfood-details.component.html",
  styleUrls: ["./add-kfood-details.component.css"],
})
export class AddKfoodDetailsComponent implements OnInit{
  profileID: string = "";
  meUnitName: string = "";
  edit: boolean = true;
  phoneNumber: number = null;
  whatsAppNumber: number = null;
  legalBody: string;
  GSTNumber: string;
  fssaiID: string;
  buildingName: string;
  streetName: string;
  landmark: string;
  localBodyType: string = "Panchayat";
  localBodyName: string = "";
  districtName: string = "";
  blockName: string = "";
  // editText:Boolean=false

  unitTypeOptions: { id: string; label: string }[] = [
    { id: "ID_PMC", label: "Premium Cafe" },
    { id: "ID_JAH", label: "Janakeeya Hotel" },
    { id: "ID_CHN", label: "Kerala Chicken" },
    { id: "ID_NMA", label: "Nano Market" },
    { id: "ID_SHO", label: "Shopee" },
  ];
  type: string = "";
  description: string = "";
  pincode: number;
  openingTime: string = "";
  closingTime: string = "";
  showMenuItemForm = false;
  adminCat: string = "";
  phoneNumber1: number;
  registrNo: string;
  defaultImage: string = "../../../assets/profileImage/profileImage.png";
  selectedImageUrls: (string | ArrayBuffer)[] = [];
  checkProductImage = "";
  productImage1: string;
  productImage2: string;
  productImage3: string;
  defaultImages = "assets/defaultImage/defaultimage.jpg";
  productImageData: File[] = [];
  productThumbNailImage: File;
  selectedMunicipal: any = "";

  workingDays: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  selectedWorkingDays: string[] = [];
  selectedMunicipalCorp: any = "";
  newMenuItem: { itemName: string; itemDescription: string; itemPrice: number } = {
    itemName: '',
    itemDescription: '',
    itemPrice:null,
  };
  menuItems: any[] = [];
  // menuItems: { name: string; description: string; price: number }[] = [];
  selectedDistrict: any;
  selectedBlock: any;
  selectedGramPanchayat: any;
  districts: any[] = [];
  blocks: any[] = [];
  gramPanchayats: any[] = [];
  districtOFDistrictAdmin: any[] = [];
  municipalities: any;
  selectedMunicipality: any;
  selectedMunicipalityId: any;
  selectedMunicipalCorporation: any;
  selectedMunicipalCorporationId: any;
  gramPanchayatsLoaded: boolean = false;
  selectedLevel: string = "blockLevel";
  municipalCorporations: any = {};
  selectedImage: any;
  isTypeDisabled: boolean = false;
  selectedGP: any = ""; // Property to store the selected level
  showWorkingDays: boolean = false;
  //for images
  latitude: Number = null ;
  longitude: Number = null ;

  uploadButton1Enabled: boolean = true;
  uploadButton2Enabled: boolean = false;
  uploadButton3Enabled: boolean = false;

  spinnerButton: boolean = false;

  editProductDBID: any = undefined;
  ingredients: string = "";

  isEdit: boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    private http: HttpClient, // private router: Router, // private http: HttpClient,
    private Authservice: AuthServiceService,
  
    private activatedRoute: ActivatedRoute // private route: ActivatedRoute, // private location: Location, // private elementRef: ElementRef,
  ) {}

  goBack() {
    this.location.back();
  }
  
  openLocationHelpModal(event: Event): void {
    event.preventDefault();
    const modalElement = document.getElementById('locationHelpModal');
    if (modalElement) {
      const modal = new Modal(modalElement);
      modal.show();
    }
  }
  validatePhoneNumbers(): boolean {
    const phoneNumberValid = this.validatePhoneNumber(this.phoneNumber);

    if (!phoneNumberValid) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Phone Number',
        text: ' Phone Number must be 10 digits long.',
      });
      return false;
    }
    return true;
  }

  validatewhatsapNumbers(): boolean {
    if (this.whatsAppNumber !== null && this.whatsAppNumber !== undefined) {
      const whatsAppNumberValid = this.validatePhoneNumber(this.whatsAppNumber);
      if (!whatsAppNumberValid) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid WhatsApp Number',
          text: 'WhatsApp Number must be 10 digits long.',
        });
        return false;
      }
    }
    return true;
  }
  validatePhoneNumber(number: number | null): boolean {
    return number !== null && /^\d{10}$/.test(number.toString());
  }

  validateCoordinate(event: KeyboardEvent, isLatitude: boolean): boolean {
    const input = event.key;
    const value = (event.target as HTMLInputElement).value;
    const regex = /^-?\d*\.?\d*$/;
    
    if (!regex.test(value + input)) {
      event.preventDefault();
      return false;
    }
  
    // Additional checks for valid range
    const numValue = parseFloat(value + input);
    if (isLatitude && (numValue < -90 || numValue > 90)) {
      event.preventDefault();
      return false;
    }
    if (!isLatitude && (numValue < -180 || numValue > 180)) {
      event.preventDefault();
      return false;
    }
  
    return true;
  }
  isSelected(day: string): boolean {
    console.log(day,"day")
    return this.selectedWorkingDays.includes(day);
  }
  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const day = checkbox.value;
    const isChecked = checkbox.checked;
  
    if (isChecked) {
      if (!this.selectedWorkingDays.includes(day)) {
        this.selectedWorkingDays.push(day);
      }
    } else {
      const index = this.selectedWorkingDays.indexOf(day);
      if (index !== -1) {
        this.selectedWorkingDays.splice(index, 1);
      }
    }
    console.log('Updated selectedWorkingDays:', this.selectedWorkingDays);
  }
  isDaySelected(day: string): boolean {
    return this.selectedWorkingDays.includes(day);
  }


  ngOnInit(): void {
    this.getDistrictName();
    console.log("districtName", this.getDistrictName());
    this.districtName = this.getDistrictName();
    this.fetchDistricts();

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.profileID = queryParams["profileID"];
    });

    if (this.profileID) {
      this.isEdit = true;
      this.fetchKfoodDetails();
      // this.fetchKfoodDetails();
    } else {
      this.profileID = "PR" + new Date().getTime().toString().substr(3, 10);
      this.isEdit = false;
    }
 
  }

  validatePrice(event: any) {
    const input = event.target;
    const value = input.value;
    
    // Remove any non-digit characters
    const newprice = value.replace(/[^0-9]/g, '');
    
    // Limit to 4 digits
    const editedValue = newprice.slice(0, 4);
    
    // Update the input value
    input.value = editedValue;
    
    // Update the model
    this.newMenuItem.itemPrice = editedValue;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  getTypeLabel(typeId: string): string {
    switch (typeId) {
      case "ID_PMC":
        return "Premium Cafe";
      case "ID_JAH":
        return "Janakeeya Hotel";
      case "ID_CHN":
        return "Kerala Chicken";
      case "ID_NMA":
        return "Nano Market";
      case "ID_SHO":
        return "Shopee";
      default:
        return "Unknown";
    }
  }
  
  allowOnlyAlphanumericAndSpace(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z0-9 ]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  checkphone1(input: HTMLInputElement) {
    const value = input.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length > 10) {
      input.value = value.slice(0, 10);
    } else if (value.length < 10) {
      // Optionally, you can show an error message here
      console.log("Phone number must be 10 digits");
    }
    this.phoneNumber = parseInt(input.value, 10);
  }
  checkWhatsApp(input: HTMLInputElement) {
    const value = input.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length > 10) {
      input.value = value.slice(0, 10);
    } else if (value.length < 10) {
      // Optionally, you can show an error message here
      console.log("WhatsApp number must be 10 digits");
    }
    this.whatsAppNumber = parseInt(input.value, 10);
  }
  allowOnlyAlphanumeric(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    // Allow only alphanumeric characters (a-z, A-Z, 0-9)
    if (!/^[a-zA-Z0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  onLevelSelection(selectedLevel: string): void {
    this.selectedDistrict = "";
    this.selectedBlock = ""; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = "";
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    this.selectedMunicipality = null;
    this.selectedMunicipalCorporation = null;
    this.selectedLevel = selectedLevel;
    // this.selectedMunicipalCorp = "";

    console.log(this.selectedMunicipalityId);

    if (this.selectedLevel === "blockLevel") {
      this.legalBody = "PAN";
      this.localBodyType = "Panchayat";
      this.fetchDistricts();
      // this.localBodyType = 'Panchayat';
    }
    if (this.selectedLevel === "municipalityLevel") {
      this.legalBody = "MUN";
      this.localBodyType = "Municipality";
      this.fetchDistricts();
      this.fetchMunicipalitiesForDistrict();
    }
    if (this.selectedLevel === "municipalCorporation") {
      this.legalBody = "MNC";
      this.localBodyType = "Corporation";

      this.fetchDistrictsforMunicipalCorporation();
      this.fetchMunicipalCorporationsForDistrict();
    }
    console.log("legalBody", this.legalBody);
  }
  fetchMunicipalCorporationsForDistrict(): void {
    // Assuming you are fetching data from a JSON file named municipal_corporations.json
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/municipalCorporations/municipal_corporation.json"
      )
      .subscribe((data) => {
        this.municipalCorporations = data.municipal_corporations || {};
        console.log(this.municipalCorporations);
      });
  }
  fetchDistricts(): void {
    this.http
      .get<any>("assets/cloudKitchenPlaces/districtBlocks/districts.json")
      .subscribe((data) => {
        this.districts = data.districts;
        console.log("district", this.districts);
        this.districtOFDistrictAdmin = [];
        for (let i = 0; i < this.districts.length; i++) {
          console.log("dis");

          console.log(this.districtName);
          console.log("district", this.districtName);
          if (this.districts[i].name === this.districtName) {
            console.log("if condition works....");
            this.districtOFDistrictAdmin[0] = this.districts[i];
            console.log(this.districtOFDistrictAdmin);
            break; // Stop the loop once you find the matching district
          }
        }
      });
  }
  fetchDistrictsforMunicipalCorporation(): void {
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/municipalCorporations/districtsCorp.json"
      )
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
        this.districtOFDistrictAdmin = [];
        for (let i = 0; i < this.districts.length; i++) {
          if (this.districts[i].name === this.districtName) {
            this.districtOFDistrictAdmin[0] = this.districts[i];
            console.log(this.districtOFDistrictAdmin);
            break; // Stop the loop once you find the matching district
          }
        }
      });
  }
  onMunicipalCorporationChange(): void {
    this.selectedMunicipalCorporationId = this.selectedMunicipalCorp.id;
    this.localBodyName = this.selectedMunicipalCorp.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("Local body name: ", this.localBodyName);
    // Implement actions to handle the change in selected municipality
    //  console.log('Selected municipality:', this.selectedMunicipalCorporationId);
    // You can perform additional actions here based on the selected municipality
  }
  fetchBlocksForDistrict(districtId: string): void {
    console.log("districtId: ", districtId);
    this.http
      .get<any>("assets/cloudKitchenPlaces/districtBlocks/blocks.json")
      .subscribe((data) => {
        this.blocks = data[districtId];
        console.log(this.blocks);
      });
  }

  tempBlocks: any;
  fetchGramPanchayatsForDistrict(districtId: string): void {
    console.log(districtId);
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/gramaPanchayats/" + districtId + ".json"
      )
      .subscribe((data) => {
        console.log(data);

        this.tempBlocks = data[`${this.selectedBlock}`];
        console.log(this.tempBlocks);
        this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
        this.gramPanchayats = this.tempBlocks;
        console.log(this.gramPanchayats);
      });
  }
  onDistrictChange(): void {
    this.selectedBlock = ""; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = "";
    this.selectedMunicipalityId = "";
    this.selectedMunicipalCorporationId = "";
    this.selectedMunicipality = null;
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    if (this.selectedDistrict) {
      this.districtName = this.selectedDistrict.name;
      console.log("District Name: ", this.districtName);
      if (this.selectedLevel === "municipalityLevel") {
        this.selectedMunicipality =
          this.municipalities[this.selectedDistrict.id];
        console.log("municipality", this.selectedMunicipality);

        return;
      } else if (this.selectedLevel == "municipalCorporation") {
        this.selectedMunicipalCorporation =
          this.municipalCorporations[this.selectedDistrict.id];
        console.log("municipalCorporation", this.selectedMunicipalCorporation);

        return;
      } else {
        console.log("this.selectedDistrict", this.selectedDistrict.id);

        this.fetchBlocksForDistrict(this.selectedDistrict.id);
        // Preload gram panchayats data for the selected district
        this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
      }
    }
  }
  selectedBlk: any = "";
  onBlockChange(): void {
    this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
    this.selectedBlock = this.selectedBlk.id;
    this.blockName = this.selectedBlk.name;
    console.log(this.selectedBlock);

    console.log("onBlockchnge", this.selectedDistrict.id);
    this.selectedGramPanchayat = ""; // Reset selected gram panchayat when block changes
    if (this.selectedBlock) {
      this.selectedGramPanchayat = ""; // Reset selected gram panchayat when block changes
      // this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
    }
    // Set the gram panchayats data based on the selected block
    if (this.selectedBlock && this.blocks[this.selectedBlock]) {
      this.gramPanchayats =
        this.gramPanchayats[this.selectedDistrict][this.selectedBlock];
      console.log(this.gramPanchayats);
    }
  }

  // selectedGP: any = "";
  onPChange(): void {
    this.selectedGramPanchayat = this.selectedGP.name;
    this.localBodyName = this.selectedGramPanchayat;
    // this.blockName=this.selectedBlk.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("blockName: ", this.blockName);
    console.log("Local body name: ", this.localBodyName);
    // console.log(
    //   this.districtName,
    //   this.blockName,

    //   this.selectedGramPanchayat
    // );
  }
  toggleWorkingDays() {
    this.showWorkingDays = !this.showWorkingDays;
  }
  fetchMunicipalitiesForDistrict(): void {
    this.http
      .get<any>("assets/cloudKitchenPlaces/municipalities/municipalities.json")
      .subscribe((data) => {
        this.municipalities = data.municipalities;
        console.log(data.municipalities);
      });
  }
  onMunicipalityChange(): void {
    // Implement actions to handle the change in selected municipality
    console.log("Selected municipality:", this.selectedMunicipal.name);
    this.selectedMunicipalityId = this.selectedMunicipal.id;
    this.localBodyName = this.selectedMunicipal.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("Local body name: ", this.localBodyName);

    // You can perform additional actions here based on the selected municipality
  }
  // selectedLevel: string = "blockLevel"; // Property to store the selected level

  // districts: any[] = [];
  // blocks: any;
  // gramPanchayats: any = {};

  // selectedDistrict: any = "";
  // selectedBlock: string = "";
  // selectedGramPanchayat: string = "";

  // gramPanchayatsLoaded: boolean = false;
  cancelAddMenuItem() {
    this.showMenuItemForm = false;
    this.newMenuItem = { itemName: "", itemDescription: "", itemPrice: 0 };
  }
  showAddMenuItemForm() {
    this.showMenuItemForm = true;
  }
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }

  getDistrictName(): any {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.getDecodedDistrict()
    );
  }

  // addMenuItem() {
  //   if (
  //     this.newMenuItem.itemName &&
  //     this.newMenuItem.itemPrice &&
  //     this.menuItems.length < 10
  //   ) {


  //     const newItem = {
  //       itemName: this.newMenuItem.itemName,
  //       itemDescription: this.newMenuItem.itemDescription,
  //       itemPrice: this.newMenuItem.itemPrice
  //     };
  //     this.menuItems.push(newItem);

  //     // this.menuItems.push({ ...this.newMenuItem });
  //     this.cancelAddMenuItem();
  //   }
  //   if (this.menuItems.length >= 10) {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Maximum Items Reached',
  //       text: 'You can add a maximum of 10 menu items.',
  //     });
  //     return;
  //   }
  // }
  addMenuItem() {
    if (this.menuItems.length >= 10) {
      Swal.fire({
        icon: 'warning',
        title: 'Maximum Items Reached',
        text: 'You can add a maximum of 10 menu items.',
      });
      return;
    }
  
    if (this.newMenuItem.itemName && this.newMenuItem.itemPrice) {
      const newItem = {
        itemName: this.newMenuItem.itemName,
        itemDescription: this.newMenuItem.itemDescription,
        itemPrice: this.newMenuItem.itemPrice
      };
      this.menuItems.push(newItem);
      this.cancelAddMenuItem();
    } else if (this.newMenuItem.itemName && !this.newMenuItem.itemPrice) {
      Swal.fire({
        icon: 'error',
        title: 'Missing Price',
        text: 'Please enter a price for the menu item.',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Incomplete Item',
        text: 'Please enter both the item name and price.',
      });
    }
  }
  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  //image upload code
  onImageSelected(event: any, index: number): void {
    const file: File = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (!allowedTypes.includes(file.type)) {
      Swal.fire({
        title: "Invalid file type",
        text: "Please select a PNG, JPEG, or JPG image.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      Swal.fire({
        title: "File too large",
        text: "Please upload an image less than 5 MB.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    this.compressImage(file, index)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result !== null) {
            this.selectedImageUrls[index] = reader.result;
          }
        };
        reader.readAsDataURL(compressedFile as Blob);

        const compressedFileName = this.getCompressedImageName(index);
        const compressedImageFile = new File(
          [compressedFile as Blob],
          compressedFileName
        );
        this.productImageData[index] = compressedImageFile;

        if (index === 0) {
          this.compressFurtherImage(compressedImageFile)
            .then((furtherCompressedFile) => {
              this.productThumbNailImage = furtherCompressedFile;
            })
            .catch((error) => {
              console.error("Thumbnail compression error:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Image compression error:", error);
      });

    if (index === 0) {
      this.uploadButton1Enabled = true;
      this.uploadButton2Enabled = !this.editProductDBID;
    } else if (index === 1) {
      this.uploadButton2Enabled = true;
      this.uploadButton3Enabled = !this.editProductDBID;
    } else if (index === 2) {
      this.uploadButton3Enabled = true;
      this.uploadButton2Enabled = true;
    }
  }

  getCompressedImageName(index: number): string {
    const profileID = this.profileID; // Replace with actual product ID
    return `${profileID}_image${index + 1}`;
  }

  compressImage(file: File, index: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            if (!ctx) {
              reject("Failed to get canvas context");
              return;
            }

            const size = Math.min(image.width, image.height);
            const xOffset = (image.width - size) / 2;
            const yOffset = (image.height - size) / 2;
            const maxWidth = 800;
            const maxHeight = 800;
            let width = size;
            let height = size;

            if (width > height && width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            } else if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(
              image,
              xOffset,
              yOffset,
              size,
              size,
              0,
              0,
              width,
              height
            );

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const compressedFile = new File(
                    [blob],
                    "compressed_image.jpg"
                  );
                  if (compressedFile.size > 300 * 1024) {
                    const quality = (300 * 1024) / compressedFile.size;
                    canvas.toBlob(
                      (furtherBlob) => {
                        if (furtherBlob) {
                          const furtherCompressedFile = new File(
                            [furtherBlob],
                            "further_compressed_image.jpg"
                          );
                          resolve(furtherCompressedFile);
                        } else {
                          reject("Further compressed blob is null");
                        }
                      },
                      "image/jpeg",
                      quality
                    );
                  } else {
                    resolve(compressedFile);
                  }
                } else {
                  reject("Blob is null");
                }
              },
              "image/jpeg",
              0.9
            );
          };
          image.src = event.target.result as string;
        } else {
          reject("FileReader event target or result is null");
        }
      };
      reader.onerror = (error) => {
        console.error("File reading error:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  compressFurtherImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (!ctx) {
            reject("Failed to get canvas context");
            return;
          }

          let width = image.width;
          let height = image.height;

          if (file.size > 300 * 1024) {
            const maxSize = 150;
            if (width > height) {
              height *= maxSize / width;
              width = maxSize;
            } else {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob: Blob | null) => {
                if (blob) {
                  const furtherCompressedFile = new File(
                    [blob],
                    `${this.profileID}_thumbnailImage.jpg`
                  );
                  resolve(furtherCompressedFile);
                } else {
                  reject("Thumbnail blob is null");
                }
              },
              "image/jpeg",
              1
            );
          } else {
            const size = Math.min(width, height);
            const xOffset = (width - size) / 2;
            const yOffset = (height - size) / 2;
            const squareSize = 150;

            canvas.width = squareSize;
            canvas.height = squareSize;
            ctx.drawImage(
              image,
              xOffset,
              yOffset,
              size,
              size,
              0,
              0,
              squareSize,
              squareSize
            );

            canvas.toBlob(
              (blob: Blob | null) => {
                if (blob) {
                  const croppedFile = new File(
                    [blob],
                    `${this.profileID}_thumbnailImage.jpg`
                  );
                  resolve(croppedFile);
                } else {
                  reject("Cropped blob is null");
                }
              },
              "image/jpeg",
              1
            );
          }
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => {
        console.error("Listing image file reading error:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  validateNumberInput(event: KeyboardEvent) {
    const charCode = event.charCode;
    const inputValue = (event.target as HTMLInputElement).value;

    // Allow only numbers and a single dot, and prevent other characters
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
    }

    // Prevent multiple dots
    if (charCode === 46 && inputValue.includes(".")) {
      event.preventDefault();
    }
  }

 
  onWorkingDayChange(day: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedWorkingDays.includes(day)) {
        this.selectedWorkingDays.push(day);
      }
    } else {
      const index = this.selectedWorkingDays.indexOf(day);
      if (index > -1) {
        this.selectedWorkingDays.splice(index, 1);
      }
    }
    console.log('Updated working days:', this.selectedWorkingDays);
  }
  validatePincode(event: KeyboardEvent): boolean {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
      return false;
    }

    // Check if the pincode would exceed 6 digits
    if (this.pincode && this.pincode.toString().length >= 6 ) {
      event.preventDefault();
      return false;
    }

    return true;
  }
  isPincodeValid(): boolean {
    return this.pincode !== undefined && this.pincode.toString().length === 6;
  }
  
  removeMenuItem(index: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to recover this menu item!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const itemToRemove = this.menuItems[index];
        
        // If the item doesn't have an _id, it's a new item that hasn't been saved to the backend
        if (!itemToRemove._id) {
          this.menuItems.splice(index, 1);
          Swal.fire(
            'Deleted!',
            'The menu item has been removed.',
            'success'
          );
          return;
        }
      
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      
        this.http.delete(`${BACKEND_URL}/deleteMenuItem`, {
          headers: headers,
          body: {
            profileID: this.profileID,
            itemId: itemToRemove._id
          }
        }).subscribe(
          (response: any) => {
            console.log('Menu item deleted successfully', response);
            this.menuItems.splice(index, 1);
            Swal.fire(
              'Deleted!',
              'The menu item has been removed.',
              'success'
            );
          },
          (error) => {
            console.error('Error deleting menu item', error);
            Swal.fire(
              'Error!',
              'Failed to delete menu item. Please try again.',
              'error'
            );
          }
        );
      }
    });
  }
  validateCompulsoryFields(): boolean {
    const compulsoryFields = [
      { name: 'ME Unit Name', value: this.meUnitName },
      { name: 'Unit Type',value: this.type},
      { name: 'Phone Number', value: this.phoneNumber },
      // { name: 'WhatsApp Number', value: this.whatsAppNumber },
      { name: 'Registration number', value: this.registrNo },
      
      { name: 'Building Name', value: this.buildingName },
      { name: 'Street Name', value: this.streetName },
      { name: 'Pincode', value: this.pincode },
      { name: 'Local Body Type', value: this.localBodyType },
      
      { name: 'District Name', value: this.districtName },

      { name: 'Latitude', value: this.latitude },
      { name: 'Longitude', value: this.longitude },

      { name: 'Working Days', value: this.workingDays },
      
      { name: 'Opening Time', value: this.openingTime },
      { name: 'Closing Time', value: this.closingTime },

     
    ];
    let imagesMissing = false;

    if (!this.isEdit || (this.isEdit && !this.hasExistingImages())) {
      if (!this.productImageData[0] && !this.productImage1) {
        compulsoryFields.push({ name: 'Image 1', value: null });
        imagesMissing = true;
      }
      if (!this.productImageData[1] && !this.productImage2) {
        compulsoryFields.push({ name: 'Image 2', value: null });
        imagesMissing = true;
      }
      if (!this.productImageData[2] && !this.productImage3) {
        compulsoryFields.push({ name: 'Image 3', value: null });
        imagesMissing = true;
      }
    }
    const emptyFields = compulsoryFields.filter(field => !field.value);

    if (emptyFields.length > 0) {
      const missingFields = emptyFields.map(field => field.name).join(' , ');
      Swal.fire({
        icon: 'error',
        title: 'Incomplete Form',
        text: `Please fill in the following compulsory fields: ${missingFields}`,
      });
      return false;
    }
    if (!this.isPincodeValid()) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Pincode',
        text: 'Pincode must be exactly 6 digits long.',
      });
      return false;
    }
    
    if (this.selectedWorkingDays.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Working Days Not Selected',
        text: 'Please select at least one working day.',
      });
      return false;
    }

    

    return true;
  }
  hasExistingImages(): boolean {
    return !!(this.productImage1 || this.productImage2 || this.productImage3);
  }
  //to post an product
  submitKFoodsData() {
   
    console.log("meUnitName", this.meUnitName);
    console.log("profileID", this.profileID);
    console.log("phoneNumber", this.phoneNumber);
    console.log("whatsAppNumber", this.whatsAppNumber);
    console.log("GSTNumber", this.GSTNumber);
    console.log("fssaiID", this.fssaiID);
    console.log("buildingName", this.buildingName);
    console.log("streetName", this.streetName);
    console.log("landmark", this.landmark);
    console.log("localBodyType", this.localBodyType);
    console.log("localBodyName", this.localBodyName);
    console.log("districtName", this.districtName);
    console.log("blockName", this.blockName);
    console.log("registrNo", this.registrNo);
    console.log("description", this.description);
    console.log("menuitems...",this.menuItems)
    console.log("days", this.selectedWorkingDays);
    console.log(this.selectedWorkingDays,"line 901")
    console.log("type",this.type)
    
    // console.log("menu",this.menuItems)
  
    if (!this.validateCompulsoryFields() || !this.validatewhatsapNumbers()) {
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with the  registration?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel",
      }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
        const params = new HttpParams().set("profileID", this.profileID);

        this.spinnerButton = true;
        let formData = new FormData();

        formData.append("meUnitName", this.meUnitName);
        formData.append("profileID", this.profileID);
        if (!this.validatePhoneNumbers()) {
          return;
        }
        formData.append("phoneNumber", this.phoneNumber.toString());
        // if (!this.validatewhatsapNumbers()) {
        //   return;
        // }
         // Handle whatsAppNumber
  if (this.whatsAppNumber !== null && this.whatsAppNumber !== undefined) {
    formData.append("whatsAppNumber", this.whatsAppNumber.toString());
  }
        //  formData.append("whatsAppNumber", this.whatsAppNumber.toString());
        formData.append("GSTNumber", this.GSTNumber||"");
        formData.append("fssaiID", this.fssaiID|| "");
        formData.append("buildingName", this.buildingName);
        formData.append("streetName", this.streetName);
        formData.append("landmark", this.landmark||"");
        formData.append("localBodyType", this.localBodyType);
        formData.append("localBodyName", this.localBodyName);
        formData.append("districtName", this.districtName);
        formData.append("blockName", this.blockName);
        formData.append("registrNo", this.registrNo);
        formData.append("type", this.type);
        formData.append("description", this.description);

        if (this.pincode) {
          formData.append("pincode", this.pincode.toString());
        }

        // formData.append(
        //   "workingDays",
        //   JSON.stringify(this.selectedWorkingDays)
        // );
        formData.append("openingTime", this.openingTime);
        formData.append("closingTime", this.closingTime);
        if (this.latitude !== null) {
          formData.append("latitude", this.latitude.toString());
        }
        if (this.longitude !== null) {
          formData.append("longitude", this.longitude.toString());
        }



        formData.append("menuItems", JSON.stringify(this.menuItems));
        console.log("blaaaa", this.menuItems);

        if (this.selectedWorkingDays.length > 0) {
          formData.append('workingDays', JSON.stringify(this.selectedWorkingDays));
        }

        // Append each selected file to the FormData object
        for (let i = 0; i < this.productImageData.length; i++) {
          formData.append("images", this.productImageData[i]);
        }

        // Append thumbnail image to formData
        if (this.productThumbNailImage) {
          formData.append("thumbnailImage", this.productThumbNailImage);
        }

        // Log formData to check values before sending
        console.log("upload image file: ", this.productImageData);
        console.log("The form data is:", formData);

        this.spinnerButton = true;

        // HTTP POST request
        this.http
          .post(BACKEND_URL + "/addKFoods", formData, { headers,params
          })
          .subscribe(
              (response) => {
              console.log("Response from server:", response);
              this.spinnerButton = false;

              Swal.fire({
                title: "Success",
                text: "Data saved successfully",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                  this.router.navigate(["/kFoodList"]); // Navigate to the home page
                }
              });
            },

            (error) => {
              console.error("Error occurred:", error);
              this.spinnerButton = false;
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              }   
              else if (error.status === 400) {
                if (error.error.errorCode === 'INVALID_COORDINATES') {
                  Swal.fire({
                    icon: "error",
                    title: "Invalid Coordinates",
                    text: error.error.message,
                  });
                }


            else if (error.error.errorCode === 'DUPLICATE_PHONE') {
                  Swal.fire({
                    icon: "error",
                    title: "Duplicate Phone Number",
                    text: error.error.message,
                  });
                }
            
             
            }
       } );
        
      } else {
        // If the user cancels, do nothing
        Swal.fire("Cancelled", "Product registration was cancelled.", "info");
      }
    });
  }

  //to edit products
  // fetchProduct(ID: string) {
  //   this.http.get(BACKEND_URL + `/getProductforEdit/${ID}`).subscribe(
  //     (productForEdit: any) => {
  //       console.log('the productForEdit is', productForEdit);

  //this.productImageURL = this.productForEdit.productImageURL[0];
  // this.checkProductImage = this.productForEdit.productThumbNailImage;

  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }
  editkfood: any;
  spinnerForKfoodListings = false;
  kfoodDetails: any;
  // getKfoodListings() {
  //   this.spinnerForKfoodListings = true;
  //   const token = this.Authservice.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   const params = new HttpParams().set('profileID', this.profileID);
  //   this.http
  //     .get(BACKEND_URL + '/kpAdmin/getKfoods', { headers,params }) // Changed from post to get
  //     .subscribe(
  //       (data) => {
  //         this.spinnerForKfoodListings = false;
  //         this.kfoodDetails = data;
  //         console.log('kfoodDetails', this.kfoodDetails);

  //             // Handle success response if needed
  //       },
  //       (error) => {
  //         console.error('Error', error);
  //         if (error.status === 401) {
  //           console.error('Unauthorized - logging out');
  //           this.Authservice.logOut();
  //         } else {
  //           console.error('Error fetching MEUnitProfilePage:', error);
  //           this.spinnerForKfoodListings = false;
  //         }
  //         // Handle error response
  //       }
  //     );
  // }
  inputfieldEdit() {
    this.toedit = !this.toedit;
  }
  editkFoods: String = "";
  images: [] = [];
  formatTimeForInput(time: string): string {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
  }
  status:boolean;
  isDelete:boolean;
  fetchKfoodDetails() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("profileID", this.profileID);

    this.http
      .get(`${BACKEND_URL}/getKfoodDescription`, { headers, params })
      .subscribe(
        (data: any) => {
          console.log("the kfoodForEdit is", data);
          this.editkFoods = data;
          console.log("FETCH works");
          this.meUnitName = data.meUnitName;
          this.phoneNumber = data.phoneNumber;
          this.whatsAppNumber = data.whatsAppNumber;
          this.GSTNumber = data.GSTNumber;
          this.fssaiID = data.fssaiID;
          this.buildingName = data.address.buildingName;
          this.streetName = data.address.streetName;
          this.landmark = data.address.landmark;
          this.localBodyType = data.address.localBodyType;
          this.localBodyName = data.address.localBodyName;
          this.districtName = data.address.districtName;
          this.blockName = data.address.blockName;
          this.type = data.type;
          this.description = data.description;
          this.pincode = data.address.pincode;
          this.openingTime = data.openDay.openTime;
          this.closingTime=data.openDay.closeTime,
          this.selectedWorkingDays = Array.isArray(
            data.openDay.selectedWorkingDays
          )
            ? data.openDay.selectedWorkingDays
            : [];
            
            this.isDelete=data.isDelete,
            this.status=data.status,
          

console.log("dfddfdsfds",this.isDelete,this.status)
        console.log("Fetched working days:", this.selectedWorkingDays);


          // this.selectedWorkingDays = data.openDay.selectedWorkingDays;
          console.log("fgert", this.selectedWorkingDays);

          // this.selectedWorkingDays = data.openDay.selectedWorkingDays;
          this.openingTime = this.formatTimeForInput(data.openDay.openTime);
          this.closingTime = this.formatTimeForInput(data.openDay.closeTime);
          // console.log("openingTime",this.openingTime)
          this.latitude = data.location.coordinates[1];
          this.longitude = data.location.coordinates[0];
          this.menuItems = data.menuItems || [];
          console.log("Loaded menu items:", this.newMenuItem);

          this.registrNo = data.registrNo;
          this.images = data.imageURLs;

          this.productImage1 = data.productImageURL[0];
          this.productImage2 = data.productImageURL[1];
          this.productImage3 = data.productImageURL[2];

          console.log("productImage1", this.productImage1);
          console.log("productImage2", this.productImage2);
          console.log("productImage3", this.productImage3);

          console.log("blockname", this.blockName);
          console.log("workingdays", this.selectedWorkingDays);
        },
        (error) => {
          console.error("Error fetching Kfood details:", error);
          // Handle error (e.g., show error message to user)
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          }   
        }
      );
  }

 


 


  updateKFoodsData() {
    console.log("Menu items:", this.menuItems);

    if (!this.validateCompulsoryFields() || !this.validatewhatsapNumbers()) {
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Update KFood details",
      text: "Do you want to continue?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerButton = true;
        
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
        const params = new HttpParams().set("profileID", this.profileID);
        
        let formData = new FormData();
        formData.append("profileID", this.profileID);
        formData.append("meUnitName", this.meUnitName);
        formData.append("type", this.type);
        formData.append("description", this.description);
        formData.append("phoneNumber", this.phoneNumber.toString());
        formData.append("registrNo", this.registrNo);
        formData.append("fssaiID", this.fssaiID);
        formData.append("GSTNumber", this.GSTNumber);
        formData.append("openingTime", this.openingTime);
        formData.append("closingTime", this.closingTime);
        formData.append("buildingName", this.buildingName);
        formData.append("streetName", this.streetName);
        formData.append("landmark", this.landmark);
        formData.append("pincode", this.pincode.toString());
        formData.append("localBodyType", this.localBodyType);
        formData.append("localBodyName", this.localBodyName);
        formData.append("districtName", this.districtName);
        formData.append("blockName", this.blockName);
        // formData.append("latitude", this.latitude.toString());
        // formData.append("longitude", this.longitude.toString());
        formData.append("workingDays", JSON.stringify(this.selectedWorkingDays));
        
        if (this.latitude !== null) {
          formData.append("latitude", this.latitude.toString());
        }
        if (this.longitude !== null) {
          formData.append("longitude", this.longitude.toString());
        }
        
        
     
      
        if (this.whatsAppNumber !== null && this.whatsAppNumber !== undefined) {
          formData.append("whatsAppNumber", this.whatsAppNumber.toString());
        }
        else if(this.whatsAppNumber==null){
          formData.append("whatsAppNumber", this.whatsAppNumber.toString());
        }

        formData.append("menuItems", JSON.stringify(this.menuItems));

        this.productImageData.forEach((file, index) => {
          if (file) {
            formData.append(`images`, file, file.name);
          }
        });

        if (this.productThumbNailImage) {
          formData.append('thumbnailImage', this.productThumbNailImage, this.productThumbNailImage.name);
        }

        this.http.put(BACKEND_URL + "/editKFoods", formData, { headers, params })
          .subscribe(
            (response) => {
              console.log("Account updated", response);
              this.spinnerButton = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "KFoods account updated successfully!",
              });
              this.fetchKfoodDetails();
              this.router.navigate(["/kFoodList"]);
              //  this.fetchKfoodDetails();
            

              

              // window.location.reload();
            },
            (error) => {
              this.spinnerButton = false;
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              } else if (error.error && error.error.errorCode === 'INVALID_COORDINATES') {
                Swal.fire({
                  icon: "error",
                  title: "Invalid Coordinates",
                  text: error.error.message,
                });
              } else {
                console.error('Failed updating KFoods data', error);
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Failed to update KFoods account. Please try again.",
                });
              }
            }
          );
      } else {
        console.log("User chose not to continue");
      }
    });
  }
  toedit: Boolean = false;
  toggleEdit() {
    this.toedit = true;
    // this.edit = !this.edit;
  }
  deleteKFoodsData()
  {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        const params = new HttpParams().set('profileID', this.profileID);
  
        this.http.delete(`${BACKEND_URL}/deleteKFoods`, { headers, params })
          .subscribe(
            (response: any) => {
              console.log('KFoods data deleted successfully', response);
              Swal.fire(
                'Deleted!',
                'Your KFoods data has been deleted.',
                'success'
              );
              // Navigate to the KFood list or home page after successful deletion
              this.router.navigate(['/kFoodList']);
            },
            (error) => {
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              }   
              else if (error.status === 400) {
                if (error.error.errorCode === 'INVALID_COORDINATES') {
                  Swal.fire({
                    icon: "error",
                    title: "Invalid Coordinates",
                    text: error.error.message,
                  });
                }
              }
              else 
              console.error('Error deleting KFoods data', error);
              Swal.fire(
                'Error!',
                'Failed to delete KFoods data. Please try again.',
                'error'
              );
            }
          );
      }
    });
  }
}
