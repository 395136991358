import { Component } from '@angular/core';
import {  OnInit } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Route, Router } from "@angular/router";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { ServiceRequestListingsService } from "../services/service-request-listings.service";
import { DateAdapter } from '@angular/material/core';

import { environment } from "src/environments/environment";
const QS_ADMIN_URL = environment.qsAdminUrl;
@Component({
  selector: 'app-online-order-listing',
  templateUrl: './online-order-listing.component.html',
  styleUrls: ['./online-order-listing.component.css'],
  providers: [DatePipe],
})
export class OnlineOrderListingComponent implements OnInit {
  ngOnInit(): void {
    this.fetchDistricts();
  }
  endDate: any = null;
  startDate: any = null;
  spinnerForSearch: boolean = false;
  maxDate: Date;

  orderList: any;
  currentPage: number = 1;
  totalPages: number = 0;
  totalItems: number = 0;
  itemsPerPage: number = 20;
  pages: number[] = [];

  Math = Math;  // Make Math available to the template

  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private ServiceRequestListingsService: ServiceRequestListingsService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.maxDate = new Date();
    // Optional: set locale if needed
    this.dateAdapter.setLocale('en-GB');
  }


  checkUser() {
    // this.currentPage = 1;
    this.paymentStatus = '';
    this.orderStatus = '';
    this.paymentMethod = '';
    this.searchTerm = '';
    this.searchType = 'subOrderId';
    this.selectedDistrict = '';
    this.isFilterApplied = false

    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.getOrderDetails();
    } else if (this.isDistrictAdmin()) {
      this.getOrderDetailsForDistrictAdmin();
    } else if (this.isMarketingAdmin()) {
      this.getOrderDetailsForMarketingDistrictAdmin();
    } else {
      console.log("please check login");
    }
  }

// for fetching the district list from json

districts: any[] = [];
selectedDistrict: string = '';
fetchDistricts(): void {
  // console.log("this is the district name", this.districtName);
  this.http
    .get<any>("assets/cloudKitchenPlaces/districtBlocks/districts.json")
    .subscribe((data) => {
      this.districts = data.districts;
      console.log("this is the district list", this.districts);
      // console.log(this.districts);
      // this.districtOFDistrictAdmin = [];
      // for (let i = 0; i < this.districts.length; i++) {
      //   if (this.districts[i].name === this.districtName) {
      //     console.log("this is the district name", this.districtName);
      //     this.districtOFDistrictAdmin[0] = this.districts[i];
      //     console.log(this.districtOFDistrictAdmin);
      //     break; // Stop the loop once you find the matching district
      //   }
      // }
    });
}






  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
    // this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
  }




  generatePageArray() {
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
  }

  isFilterApplied:boolean = false;
  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages && page !== this.currentPage) {
      this.currentPage = page;
      // this.getOrderDetails();
      // this.checkUser();
      if (this.isFilterApplied) {
        this.applyFilters();
      } else {
        this.checkUser();
      }
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.changePage(this.currentPage + 1);
    }
  }

  // filters
   // Filter variables
   searchType: string = 'subOrderId';
   searchTerm: string = '';
   paymentStatus: string = '';
   orderStatus: string = '';
   paymentMethod: string = '';

   loading: boolean = false;

 // Apply filters
 applyFilters() {
  console.log("this is the selected district: ", this.selectedDistrict);
  // this.currentPage = 1;

  // this.startDate = '';
  // this.endDate = '';
  this.loading = true;

  let params: any = {};

  // Add non-empty filters to params
  if (this.searchTerm) {
    params[this.searchType] = this.searchTerm;
  }
  if (this.selectedDistrict) {
    params.district = this.selectedDistrict;
  }
  if (this.paymentStatus) {
    params.paymentStatus = this.paymentStatus;
  }
  if (this.orderStatus) {
    params.orderStatus = this.orderStatus;
  }
  if (this.paymentMethod) {
    params.paymentMethod = this.paymentMethod;
  }
  console.log("params: ", params);

  // Pass params to getOrderDetails with the filter params
  if (this.isSuperAdmin() || this.isStateAdmin()) {
    this.getOrderDetailsForStateAdminFilter(params);
    this.isFilterApplied = true;
  } else if (this.isDistrictAdmin()) {
    this.getOrderDetailsForDistrictAdminFilter(params);
    this.isFilterApplied = true;
  } else if (this.isMarketingAdmin()) {
    this.getOrderDetailsForMarketingDistrictAdminFilter(params);
    this.isFilterApplied = true;
  } else {
    console.log("please check login");
  }
}

ara:any=[];

getOrderDetails(filterParams: any = {}) {
  if (!this.startDate) {
    Swal.fire("Please select start date");
    return;
  }
  if (!this.endDate) {
    this.endDate = this.startDate;
  }
  if (new Date(this.endDate) < new Date(this.startDate)) {
    this.endDate = this.startDate;
  }
  if (this.startDate && this.endDate) {
    console.log("startDate: " , this.startDate);
    console.log("endDate: " , this.endDate);
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

    // Create base params object
    let params: any = {
      startDate: this.startDate,
      endDate: this.endDate,
      page: this.currentPage.toString()
    };

    // Add individual filter params if they exist
    if (filterParams.subOrderId) {
      params.subOrderId = filterParams.subOrderId;
    }
    if (filterParams.mobile) {
      params.mobile = filterParams.mobile;
    }
    if (filterParams.paymentStatus) {
      params.paymentStatus = filterParams.paymentStatus;
    }
    if (filterParams.orderStatus) {
      params.orderStatus = filterParams.orderStatus;
    }
    if (filterParams.paymentMethod) {
      params.paymentMethod = filterParams.paymentMethod;
    }

    console.log("params: ", params);
    this.spinnerForSearch = true;
    this.http
      .get(QS_ADMIN_URL + "/orderDetailsForStateAdmin", { headers, params })
      .subscribe(
        (response: any) => {
          this.ara = []; // Clear the array before adding new items
          response.data.map((item: any) => item.subOrders.map((subItem:any) => {
            subItem.mobile = item.shippingAddress.mobile;
            console.log("subItem: ", subItem);
            this.ara.push(subItem);
          }));
          console.log("this.ara: ", this.ara);

          this.orderList = this.ara;
          console.log("this.orderList: ", this.orderList);
          this.currentPage = response.pagination.currentPage;
          this.totalPages = response.pagination.totalPages;
          this.totalItems = response.pagination.totalItems;
          this.itemsPerPage = response.pagination.itemsPerPage;
          this.generatePageArray();
          this.loading = false;
          this.spinnerForSearch = false;
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
          }
          this.loading = false;
          this.spinnerForSearch = false;
        }
      );
  }
}

getOrderDetailsForStateAdminFilter(filterParams: any = {}) {
  console.log("this is the filterParams: ", filterParams);
  // if (!this.startDate) {
  //   Swal.fire("Please select start date");
  //   return;
  // }
  if (!this.endDate) {
    this.endDate = this.startDate;
  }
  if (new Date(this.endDate) < new Date(this.startDate)) {
    this.endDate = this.startDate;
  }
  // if (this.startDate && this.endDate) {
    // console.log("startDate: " + this.startDate);
    // console.log("endDate: " + this.endDate);
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

    // Create base params object
    let params: any = {
      page: this.currentPage.toString()
    };

    // Add individual filter params if they exist
    if (filterParams.subOrderId) {
      this.startDate = '';
      this.endDate = '';
      params.subOrderId = filterParams.subOrderId;
    }
    if (filterParams.mobile) {
      params.mobile = filterParams.mobile;


    }
    if(filterParams.district){
      params.district = filterParams.district;
    }
    if (filterParams.paymentStatus) {
      params.paymentStatus = filterParams.paymentStatus;
    }
    if (filterParams.orderStatus) {
      params.orderStatus = filterParams.orderStatus;
    }
    if (filterParams.paymentMethod) {
      params.paymentMethod = filterParams.paymentMethod;
    }
    if(!filterParams.subOrderId){
      if(this.startDate && this.endDate){
        params.startDate = this.startDate;
        params.endDate = this.endDate;
      }
    }

    console.log("params: ", params);
this.spinnerForSearch = true;
    this.http
      .get(QS_ADMIN_URL + "/filterOrderDetailsForStateAdmin", { headers, params })
      .subscribe(
        (response: any) => {
          console.log("responserhes: ", response);
          this.ara = []; // Clear the array before adding new items
          response.data.map((item: any) => item.subOrders.map((subItem:any) => {
            subItem.mobile = item.shippingAddress.mobile;
            if (Object.keys(filterParams).length === 0) {
              console.log("nothing to see ghere")
              if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                this.ara.push(subItem);
              }
            } else {
              console.log("hello hello")
              if (filterParams.district) {
                console.log("data is comongh with distrcyt")
                if (subItem.product.userDistrict === filterParams.district) {
                  console.log(subItem,"hello o im fine2")
                  if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                    this.ara.push(subItem);
                  }
                }
              }
              if (filterParams.orderStatus) {
                console.log("data is comongh with suborder tstaus")
                console.log("subOrderStatus: ", filterParams.orderStatus);
                if (subItem.subOrderStatus === filterParams.orderStatus) {
                  console.log(subItem,"hello o im fine3")
                  console.log("maango", "blaaaa");
                  if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                    this.ara.push(subItem);
                  }
                }
              }
              if (filterParams.subOrderId) {
                console.log("data is comongh with suborder id")
                if (subItem.subOrderId === filterParams.subOrderId) {
                  console.log(subItem,"hello o im fine4")

                  if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                    this.ara.push(subItem);
                  }
                }
              }
              if (filterParams.paymentStatus) {
                console.log("data is comongh with payment statsyu")
                if (item.paymentStatus === filterParams.paymentStatus) {
                  console.log(subItem,"hello o im fine5")

                  if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                    this.ara.push(subItem);
                  }
                }
              }
            }
            if (filterParams.district) {
              this.ara = this.ara.filter(araitem =>
                araitem.product.userDistrict === filterParams.district &&
                (!filterParams.orderStatus || araitem.subOrderStatus === filterParams.orderStatus) &&
                (!filterParams.subOrderId || araitem.subOrderId === filterParams.subOrderId) &&
                (!filterParams.paymentStatus || item.paymentStatus === filterParams.paymentStatus)
              );
            }
            if (filterParams.subOrderId) {
              this.ara = this.ara.filter(araitem =>
                araitem.subOrderId === filterParams.subOrderId
              );
            }
          }));

          console.log("this.ara: ", this.ara);

          this.orderList = this.ara;
          console.log("this.orderList: ", this.orderList);
          this.currentPage = response.pagination.currentPage;
          this.totalPages = response.pagination.totalPages;
          this.totalItems = response.pagination.totalItems;
          this.itemsPerPage = response.pagination.itemsPerPage;
          this.generatePageArray();
          this.loading = false;
          this.spinnerForSearch = false;
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
          }
          this.loading = false;
          this.spinnerForSearch = false;
        }
      );
  // }
}
 // Reset filters
 resetFilters() {
  this.searchType = 'subOrderId';
  this.searchTerm = '';
  this.paymentStatus = '';
  this.orderStatus = '';
  this.paymentMethod = '';
  this.selectedDistrict = '';

}

  getOrderDetailsForDistrictAdmin(filterParams: any = {}) {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      console.log("startDate: " , this.startDate);
      console.log("endDate: " , this.endDate);
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.currentPage.toString()
      };

      if (filterParams.subOrderId) {
        params.subOrderId = filterParams.subOrderId;
      }
      if (filterParams.mobile) {
        params.mobile = filterParams.mobile;
      }
      if (filterParams.paymentStatus) {
        params.paymentStatus = filterParams.paymentStatus;
      }
      if (filterParams.orderStatus) {
        params.orderStatus = filterParams.orderStatus;
      }
      if (filterParams.paymentMethod) {
        params.paymentMethod = filterParams.paymentMethod;
      }

      console.log("params: ", params);
      this.spinnerForSearch = true;




      this.http
        .get(QS_ADMIN_URL + "/orderDetailsForDistrictAdmin", {
          headers,
          params,
        }) // Changed from post to get
        .subscribe(
          (response: any) => {
            this.ara = []; // Clear the array before adding new items
            response.data.map((item: any) => item.subOrders.map((subItem:any) => {
              subItem.mobile = item.shippingAddress.mobile;
              console.log("subItem: ", subItem);
              this.ara.push(subItem);
            }));
            console.log("this.ara: ", this.ara);

            this.orderList = this.ara;
            console.log("the orderList is: " + this.orderList);
            this.currentPage = response.pagination.currentPage;
            this.totalPages = response.pagination.totalPages;
            this.totalItems = response.pagination.totalItems;
            this.itemsPerPage = response.pagination.itemsPerPage;
            this.generatePageArray();
            this.loading = false;
            this.spinnerForSearch = false;
            // Handle success response if needed
          },
          (error) => {
            this.spinnerForSearch = false;
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
            // Handle error response
          }
        );
    }
  }

  getOrderDetailsForDistrictAdminFilter(filterParams: any = {}) {
    // if (!this.startDate) {
    //   Swal.fire("Please select start date");
    //   return;
    // }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    // if (this.startDate && this.endDate) {
    //   console.log("startDate: " , this.startDate);
    //   console.log("endDate: " , this.endDate);
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      let params: any = {
        // startDate: this.startDate,
        // endDate: this.endDate,
        page: this.currentPage.toString()
      };

      if (filterParams.subOrderId) {
        this.startDate = '';
        this.endDate = '';
        params.subOrderId = filterParams.subOrderId;
      }
      if (filterParams.mobile) {
        params.mobile = filterParams.mobile;
      }
      if (filterParams.paymentStatus) {
        params.paymentStatus = filterParams.paymentStatus;
      }
      if (filterParams.orderStatus) {
        params.orderStatus = filterParams.orderStatus;
      }
      if (filterParams.paymentMethod) {
        params.paymentMethod = filterParams.paymentMethod;
      }
      if(!filterParams.subOrderId){
        if(this.startDate && this.endDate){
          params.startDate = this.startDate;
          params.endDate = this.endDate;
        }
      }

      console.log("params: ", params);



this.spinnerForSearch = true;
      this.http
        .get(QS_ADMIN_URL + "/orderDetailsForDistrictAdminFilter", {
          headers,
          params,
        }) // Changed from post to get
        .subscribe(
          // (response: any) => {
          //   this.ara = []; // Clear the array before adding new items
          //   response.data.map((item: any) => item.subOrders.map((subItem:any) => {
          //     subItem.mobile = item.shippingAddress.mobile;
          //     console.log("subItem: ", subItem);
          //     if (filterParams.subOrderId) {
          //       if (subItem.subOrderId === filterParams.subOrderId) {
          //         console.log("subItem: ", subItem);
          //         this.ara.push(subItem);
          //       }
          //     }
          //     else {
          //       console.log("subItem: ", subItem);
          //       this.ara.push(subItem);
          //     }
          //   }));
          //   console.log("this.ara: ", this.ara);

          //   this.orderList = this.ara;
          //   console.log("the orderList is: " + this.orderList);
          //   this.currentPage = response.pagination.currentPage;
          //   this.totalPages = response.pagination.totalPages;
          //   this.totalItems = response.pagination.totalItems;
          //   this.itemsPerPage = response.pagination.itemsPerPage;
          //   this.generatePageArray();
          //   this.loading = false;
          //   this.spinnerForSearch = false;
          //   // Handle success response if needed
          // },
          (response: any) => {
            console.log("responserhes: ", response);
            this.ara = []; // Clear the array before adding new items
            response.data.map((item: any) => item.subOrders.map((subItem:any) => {
              subItem.mobile = item.shippingAddress.mobile;
              if (Object.keys(filterParams).length === 0) {
                console.log("nothing to see ghere")
                if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                  this.ara.push(subItem);
                }
              } else {
                console.log("hello hello")
                // if (filterParams.district) {
                //   console.log("data is comongh with distrcyt")
                //   if (subItem.product.userDistrict === filterParams.district) {
                //     console.log(subItem,"hello o im fine2")
                //     if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                //       this.ara.push(subItem);
                //     }
                //   }
                // }
                if (filterParams.orderStatus) {
                  console.log("data is comongh with suborder tstaus")
                  console.log("subOrderStatus: ", filterParams.orderStatus);
                  if (subItem.subOrderStatus === filterParams.orderStatus) {
                    console.log(subItem,"hello o im fine3")
                    console.log("maango", "blaaaa");
                    if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                      this.ara.push(subItem);
                    }
                  }
                }
                if (filterParams.subOrderId) {
                  console.log("data is comongh with suborder id")
                  if (subItem.subOrderId === filterParams.subOrderId) {
                    console.log(subItem,"hello o im fine4")

                    if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                      this.ara.push(subItem);
                    }
                  }
                }
                if (filterParams.paymentStatus) {
                  console.log("data is comongh with payment statsyu")
                  if (item.paymentStatus === filterParams.paymentStatus) {
                    console.log(subItem,"hello o im fine5")

                    if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                      this.ara.push(subItem);
                    }
                  }
                }
              }
              // if (filterParams.district) {
              //   this.ara = this.ara.filter(araitem =>
              //     araitem.product.userDistrict === filterParams.district &&
              //     (!filterParams.orderStatus || araitem.subOrderStatus === filterParams.orderStatus) &&
              //     (!filterParams.subOrderId || araitem.subOrderId === filterParams.subOrderId) &&
              //     (!filterParams.paymentStatus || item.paymentStatus === filterParams.paymentStatus)
              //   );
              // }
              if (filterParams.subOrderId) {
                this.ara = this.ara.filter(araitem =>
                  araitem.subOrderId === filterParams.subOrderId
                );
              }
            }));

            console.log("this.ara: ", this.ara);

            this.orderList = this.ara;
            console.log("this.orderList: ", this.orderList);
            this.currentPage = response.pagination.currentPage;
            this.totalPages = response.pagination.totalPages;
            this.totalItems = response.pagination.totalItems;
            this.itemsPerPage = response.pagination.itemsPerPage;
            this.generatePageArray();
            this.loading = false;
            this.spinnerForSearch = false;
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
            this.spinnerForSearch = false;
            // Handle error response
          }
        );
    // }
  }

  getOrderDetailsForMarketingDistrictAdmin(filterParams: any = {}) {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      console.log("startDate: " , this.startDate);
      console.log("endDate: " , this.endDate);
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
console.log("currentPage: ", this.currentPage);
      let params: any = {
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.currentPage.toString()
      };
      if (filterParams.subOrderId) {
        params.subOrderId = filterParams.subOrderId;
      }
      if (filterParams.mobile) {
        params.mobile = filterParams.mobile;
      }
      if (filterParams.paymentStatus) {
        params.paymentStatus = filterParams.paymentStatus;
      }
      if (filterParams.orderStatus) {
        params.orderStatus = filterParams.orderStatus;
      }
      if (filterParams.paymentMethod) {
        params.paymentMethod = filterParams.paymentMethod;
      }

      console.log("params: ", params);
      this.spinnerForSearch = true;
      this.http
        .get(QS_ADMIN_URL + "/orderDetailsForMarketingDistrictAdmin", {
          headers,
          params,
        }) // Changed from post to get
        .subscribe(
          // (response: any) => {
          //   console.log("response: ", response);
          //   this.ara = []; // Clear the array before adding new items
          //   response.data.map((item: any) => item.subOrders.map((subItem:any) => {
          //     subItem.mobile = item.shippingAddress.mobile;
          //     console.log("subItem: ", subItem);
          //     this.ara.push(subItem);
          //   }));
          //   console.log("this.ara: ", this.ara);

          //   this.orderList = this.ara;
          //   console.log("the orderList is: " + this.orderList);
          //   this.currentPage = response.pagination.currentPage;
          //   this.totalPages = response.pagination.totalPages;
          //   this.totalItems = response.pagination.totalItems;
          //   this.itemsPerPage = response.pagination.itemsPerPage;
          //   this.generatePageArray();
          //   this.loading = false;
          //   this.spinnerForSearch = false;
          //   // Handle success response if needed
          // },
          (response: any) => {
            console.log("responserhes: ", response);
            this.ara = []; // Clear the array before adding new items
            response.data.map((item: any) => item.subOrders.map((subItem:any) => {
              subItem.mobile = item.shippingAddress.mobile;
              if (Object.keys(filterParams).length === 0) {
                console.log("nothing to see ghere")
                if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                  this.ara.push(subItem);
                }
              } else {
                console.log("hello hello")
                // if (filterParams.district) {
                //   console.log("data is comongh with distrcyt")
                //   if (subItem.product.userDistrict === filterParams.district) {
                //     console.log(subItem,"hello o im fine2")
                //     if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                //       this.ara.push(subItem);
                //     }
                //   }
                // }
                if (filterParams.orderStatus) {
                  console.log("data is comongh with suborder tstaus")
                  console.log("subOrderStatus: ", filterParams.orderStatus);
                  if (subItem.subOrderStatus === filterParams.orderStatus) {
                    console.log(subItem,"hello o im fine3")
                    console.log("maango", "blaaaa");
                    if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                      this.ara.push(subItem);
                    }
                  }
                }
                if (filterParams.subOrderId) {
                  console.log("data is comongh with suborder id")
                  if (subItem.subOrderId === filterParams.subOrderId) {
                    console.log(subItem,"hello o im fine4")

                    if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                      this.ara.push(subItem);
                    }
                  }
                }
                if (filterParams.paymentStatus) {
                  console.log("data is comongh with payment statsyu")
                  if (item.paymentStatus === filterParams.paymentStatus) {
                    console.log(subItem,"hello o im fine5")

                    if (!this.ara.find(araitem => araitem._id === subItem._id)) {
                      this.ara.push(subItem);
                    }
                  }
                }
              }
              // if (filterParams.district) {
              //   this.ara = this.ara.filter(araitem =>
              //     araitem.product.userDistrict === filterParams.district &&
              //     (!filterParams.orderStatus || araitem.subOrderStatus === filterParams.orderStatus) &&
              //     (!filterParams.subOrderId || araitem.subOrderId === filterParams.subOrderId) &&
              //     (!filterParams.paymentStatus || item.paymentStatus === filterParams.paymentStatus)
              //   );
              // }
              if (filterParams.subOrderId) {
                this.ara = this.ara.filter(araitem =>
                  araitem.subOrderId === filterParams.subOrderId
                );
              }
            }));

            console.log("this.ara: ", this.ara);

            this.orderList = this.ara;
            console.log("this.orderList: ", this.orderList);
            this.currentPage = response.pagination.currentPage;
            this.totalPages = response.pagination.totalPages;
            this.totalItems = response.pagination.totalItems;
            this.itemsPerPage = response.pagination.itemsPerPage;
            this.generatePageArray();
            this.loading = false;
            this.spinnerForSearch = false;
          },
          (error) => {
            this.spinnerForSearch = false;
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
            this.loading = false;
            // Handle error response
          }
        );
    }
  }


  getOrderDetailsForMarketingDistrictAdminFilter(filterParams: any = {}) {
    // if (!this.startDate) {
    //   Swal.fire("Please select start date");
    //   return;
    // }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    // if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      let params: any = {
      //   startDate: this.startDate,
      //   endDate: this.endDate,
        page: this.currentPage.toString()
      };
      if (filterParams.subOrderId) {
        this.startDate = '';
        this.endDate = '';
        params.subOrderId = filterParams.subOrderId;
      }
      if (filterParams.mobile) {
        params.mobile = filterParams.mobile;
      }
      if (filterParams.paymentStatus) {
        params.paymentStatus = filterParams.paymentStatus;
      }
      if (filterParams.orderStatus) {
        params.orderStatus = filterParams.orderStatus;
      }
      if (filterParams.paymentMethod) {
        params.paymentMethod = filterParams.paymentMethod;
      }
      if(!filterParams.subOrderId){
        if(this.startDate && this.endDate){
          params.startDate = this.startDate;
          params.endDate = this.endDate;
        }
      }

      this.spinnerForSearch = true;
      console.log("params: ", params);
      this.spinnerForSearch = true;
      this.http
        .get(QS_ADMIN_URL + "/orderDetailsForMarketingDistrictAdminFilter", {
          headers,
          params,
        }) // Changed from post to get
        .subscribe(
          (response: any) => {
            this.ara = []; // Clear the array before adding new items
            response.data.map((item: any) => item.subOrders.map((subItem:any) => {
              subItem.mobile = item.shippingAddress.mobile;
              console.log("subItem: ", subItem);
              if (filterParams.subOrderId) {
                if (subItem.subOrderId === filterParams.subOrderId) {
                  console.log("subItem: ", subItem);
                  this.ara.push(subItem);
                }
              }
              else {
                console.log("subItem: ", subItem);
                this.ara.push(subItem);
              }

            }));
            console.log("this.ara: ", this.ara);

            this.orderList = this.ara;
            console.log("the orderList is: " + this.orderList);
            this.currentPage = response.pagination.currentPage;
            this.totalPages = response.pagination.totalPages;
            this.totalItems = response.pagination.totalItems;
            this.itemsPerPage = response.pagination.itemsPerPage;
            this.generatePageArray();
            this.loading = false;
            this.spinnerForSearch = false;
            // Handle success response if needed
          },
          (error) => {
            this.spinnerForSearch = false;
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
            this.spinnerForSearch = false;
            // Handle error response
          }
        );
    // }
  }
  viewdetail(subOrderId:string){
    this.router.navigate(['/onlineOrderDescription'], { queryParams: { subOrderId: subOrderId } });
  }



  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

}
