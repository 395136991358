<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Order Listing</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Products > order listings</p>
    </div>
  </div>

  <div class="row card headerCard p-1 m-1">
    <div class="col pt-2">
      <mat-form-field appearance="fill" style="margin-right: 10px">
        <mat-label>Enter your start date</mat-label>
        <input
          matInput
          [max]="maxDate"
          [(ngModel)]="startDate"
          [matDatepicker]="firstPicker"
          (dateChange)="onDateChange($event, 'First Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="firstPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #firstPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Enter your end date</mat-label>
        <input
          matInput
          [max]="maxDate"
          [matDatepicker]="secondPicker"
          [(ngModel)]="endDate"
          (dateChange)="onDateChange($event, 'Second Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="secondPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #secondPicker></mat-datepicker>
      </mat-form-field>

      <button
        *ngIf="!spinnerForSearch"
        (click)="checkUser()"
        style="margin-left: 15px"
        type="button"
        class="btn btn-success"
      >
        Search
      </button>

      <div
        *ngIf="spinnerForSearch"
        style="margin-left: 15px"
        class="spinner-border text-success"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date</th>
            <th scope="col">Order ID</th>
            <th scope="col">Name</th>
            <th scope="col">District</th>
            <th scope="col">Role</th>
            <th scope="col">Selling Price</th>
            <th scope="col">Quantity</th>
            <th scope="col">Total Price</th>
          </tr>
        </thead>
        <tbody *ngFor="let orderLists of orderList; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>
              {{ convertUtcToIst(orderLists.orderDate) }}
            </td>
            <td>
              {{ orderLists.orderID }}
            </td>
            <td>{{ orderLists.productName }}</td>
            <td>{{ orderLists.district }}</td>
            <td>{{ roleTypeToLabel(orderLists.userCategory) }}</td>
            <td>{{ orderLists.sellingPrice }}</td>

            <td>{{ orderLists.productQuantity }}</td>
            <td>
              {{ orderLists.totalPrice }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
