<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
    <!--header-->
    <div class="row">
      <h4 class="text-center mb-1 createULB">
          
        <b
          >Premium Cafe,Janakeeya Hotel,Kerala Chicken,Nano Market,Shopee Listing</b
        >
      </h4>
      <div class="col">
        <!-- <p class="createULB">Premium Cafe,Janakeeya Hotel,Kerala Chicken,Nano Market,Shopee listings</p> -->
      
      </div>
  
      <div class="col">
        <p class="pageReference">kfoodsList > kfoodsListing</p>
      </div>
    </div>
  
    <br>
   <!-- Add district filter -->
 <div class="card  mb-3" style="background-color: #0C4A6C;">
  <div class="card-body">
    <div class="row mb-3" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col-md-4">
        <label for="districtFilter" style="color: white;" class="form-label">Filter by District:</label>
        <select id="districtFilter" class="form-select" [(ngModel)]="selectedDistrict">
          <option value="" selected>All Districts</option>
          <option *ngFor="let district of districts" [value]="district.name">
            {{ district.name }}
          </option>
        </select>
      </div>

      <div class="col-md-4">
      
        
          <div class="mb-3">
            <label for="inputState" class="form-label" style="color: white;">
              Filter by Unit Type:
            </label>
           
            <select
              [(ngModel)]="type" 
              name="type"
              id="inputState"
             
              class="form-select inputBoxes"
            >
              <option value=""  selected>
                Select Unit Type
              </option>
              <option
                *ngFor="let option of unitTypeOptions"
                [value]="option.id"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        
      </div>

      <div class="col d-flex align-items-end">
        <div class="mb-3">
        <button (click)="getKfoodListings()" class="btn btn-light">Search</button>
      </div></div>
    </div>
  </div>
</div>


    <button *ngIf="spinnerForMEUnitListings" class="btn btn-primary" type="button" disabled>
      <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
      <span role="status">Loading...</span>
    </button>
  
    <div class="row" *ngIf="!spinnerForMEUnitListings">
      <div class="col">
        <table class="table table-bordered" >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">profileID</th>
             
              <th scope="col">Type</th>
              <th scope="col">Mobile</th>
              <!-- <th scope="col">District</th> -->
              <th scope="col">District</th>

              <th scope="col">Reg Date</th>
              <th scope="col">Approval Status</th>
              <th scope="col">Account Status</th>

              <th scope="col">Details</th>
            </tr>
          </thead>
           <tbody *ngFor="let MEUnitListing of MEUnitListings.slice().reverse(); let i = index">
           
            <!-- <tbody *ngFor="let MEUnitListing of getSortedMEUnitListings().reverse; let i = index">     -->
            <tr>
              <!-- <th scope="row">{{ i + 1 }}</th> -->
              <th scope="row">{{ (currentPage - 1) * 10 + (i + 1) }}</th>

              <td>{{MEUnitListing.meUnitName}}</td>
              <td>{{MEUnitListing.profileID}}</td>

              <td>{{getTypeLabel(MEUnitListing.type)}}</td>              
              <td><i class="fa-solid fa-phone" *ngIf="MEUnitListing.phoneNumber"></i> {{MEUnitListing.phoneNumber}}</td>
              <!-- <td><i class="fa-solid fa-calendar-days"></i>{{MEUnitListing.selectedDistrict}}</td> -->
              <td>{{MEUnitListing.address.districtName}}</td>

             
              <td><i class="fa-solid fa-calendar-days"></i> {{MEUnitListing.regDate | date:'dd-MM-yy'}}</td>
             

              <!-- <td>{{MEUnitListing. approvalstatus}}</td> -->
              
                <!-- <button *ngIf="MEUnitListing.approvalstatus === true" 
                        class="btn btn-success btn-sm">
                  Active
                </button>
                <span *ngIf="MEUnitListing.approvalstatus !== true">
                  {{MEUnitListing.approvalstatus}}
                </span>
              </td> -->
              <!-- <td>
                <span style="text-align: center;" [ngClass]="{'text-success': MEUnitListing.approvalstatus, 'text-warning': !MEUnitListing.approvalstatus}">
                  {{ MEUnitListing.approvalstatus ? 'Active' : 'Pending' }}
                </span>
              </td> -->
              <td>
                <span class="badge" [ngClass]="{'bg-success': MEUnitListing.approvalstatus, 'bg-warning': !MEUnitListing.approvalstatus}">
                  {{ MEUnitListing.approvalstatus ? 'Approved' : 'Pending' }}
                </span>
              </td>
              <td >
                <span class="badge" [ngClass]="{'bg-danger': MEUnitListing.isDelete, 'bg-success': !MEUnitListing.isDelete}">
                  {{ MEUnitListing.isDelete ? 'Deleted' : 'Active' }}
                </span>
              </td>
           
              <td>
                <button type="button" [disabled]="MEUnitListing.isDelete" (click)="goToMeUnitDescription(MEUnitListing.profileID)" class="btn btn-link">Edit Details</button>
              </td>
            </tr>
          </tbody> 
        </table>

        <nav aria-label="KFood listings pagination" *ngIf="totalPages > 1">
          <ul class="pagination justify-content-center">
            <li class="page-item" [class.disabled]="currentPage === 1">
              <a class="page-link" (click)="changePage(currentPage - 1)">Previous</a>
            </li>
            <li
              class="page-item"
              *ngFor="let page of getVisiblePages()"
              [class.active1]="page === currentPage"
            >
              <a class="page-link" (click)="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
              <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
            </li>
          </ul>
        </nav>

      </div>
    </div>
  </div>
