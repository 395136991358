import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { PasswordService } from '../password.service';
import { QsIDsService } from '../../services/qs-ids.service';
import { environment } from 'src/environments/environment';
const KS_ADMIN_URL = environment.qsAdminUrl;
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-service-provider-description',
  templateUrl: './service-provider-description.component.html',
  styleUrls: ['./service-provider-description.component.css'],
  providers: [DatePipe],
})
export class ServiceProviderDescriptionComponent implements OnInit {
  //variable
  // backendUrl: string = environment.qsAdminUrl;
  jwtToken: string = '';
  // pdfSrc: any = `http://localhost:3004/kpAdmin/proxy-pdf?key=product%2FSP_ID1004085643%2FkudumbashreeDocuments%2FSP_ID1004085643_cdsDocuments.pdf`;

  profileID: string = '';
  serviceProviderDescription: any;
  edit: boolean = false;
  email: string = '';
  nhgName: string = '';
  accountHolderName = '';
  upiID: string = '';
  sisdSubcategory: string = '';
  sisdSubcategoryLabel: string = '';
  fssaiExpiryDate: string = '';

  onlineSeller: boolean = false;

  products: any;
  adminCategory = [
    { value: 'type_1', label: 'District Farmlivelihood' },
    { value: 'type_2', label: 'District NULM' },
    { value: 'type_3', label: 'District ME' },
    { value: 'type_4', label: 'District SVEP' },
    { value: 'type_5', label: 'District SISD' },
    // { value: "type_6", label: "District SD" },
    { value: 'type_7', label: 'District AH' },
  ];

  districts = [
    { value: 'Alappuzha', label: 'Alappuzha' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Idukki', label: 'Idukki' },
    { value: 'Kannur', label: 'Kannur' },
    { value: 'Kasaragod ', label: 'Kasaragod' },
    { value: 'Kollam', label: 'Kollam' },
    { value: 'Kottayam', label: 'Kottayam' },
    { value: 'Kozhikode', label: 'Kozhikode' },
    { value: 'Malappuram', label: 'Malappuram' },
    { value: 'Palakkad', label: 'Palakkad' },
    { value: 'Pathanamthitta', label: 'Pathanamthitta' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Wayanad', label: 'Wayanad' },
  ];

  setCategoryLabel(): void {
    switch (this.category) {
      case 'type_1':
        this.categoryLabel = 'District Farmlivelihood';
        break;
      case 'type_2':
        this.categoryLabel = 'District NULM';
        break;
      case 'type_3':
        this.categoryLabel = 'District ME';
        break;
      case 'type_4':
        this.categoryLabel = 'District SVEP';
        break;
      case 'type_5':
        this.categoryLabel = 'District SISD';
        break;
      // case "type_6":
      //   this.categoryLabel = "District SD";
      //   break;
      case 'type_7':
        this.categoryLabel = 'District AH';
        break;
      default:
        this.categoryLabel = 'Unknown';
    }
  }

  //variables
  meUnitName: string = '';
  phoneNumber1: Number = null;
  whatsAppNumber: Number = null;
  localBodyType: string = '';
  localBodyName: string = '';
  wardName: string = '';
  wardNumber: string = '';
  districtName: string = '';
  accountDeleted: boolean = false;
  bankPassBookUrl: string[] = [];
  cdsDocumentUrl: string[] = [];
  fssaiDocumentUrl: string[] = [];

  gstDocumentUrl: string[] = [];
  GITagDocumentUrl: string[] = [];
  tradeMarkDocumentUrl: string[] = [];
  UdyamDocumentsProofUrls: string[] = [];

  category: string = '';
  bankIFSCcode: string = '';
  bankAccountNumber: string = '';
  bankBranch: string = '';
  bankName: string = '';
  meAppID: string = '';
  fssaiID: string = '';
  GSTNumber: string = '';
  CDSName: string = '';
  CDSNumber: string = '';
  buildingName: string = '';
  streetName: string = '';
  landmark: string = '';
  pincode: string = '';
  approvalStatus: string = '';
  categoryLabel: string = '';
  accountDelete: boolean = false;
  spinnerButtonForUpdate: boolean = false;
  orderList:any;
  spinnerForServiceProviderDescription: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private location: Location,
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService,
    private activatedRoute: ActivatedRoute,
    private QsIDsService: QsIDsService,
    private datePipe: DatePipe
  ) {}

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.jwtToken = this.Authservice.getToken(); // Assuming you have a method to get the JWT token

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.profileID = queryParams['profileID'];
      console.log('Profile ID:', this.profileID);
    });

    if (this.profileID) {
      console.log('Profile ID 2:', this.profileID);
      this.getServiceProviderDescription();
      this.getProductsByProfileID();
      this.getSellerDocuments();
      console.log(this.documentUrls, 'documentUrls');
    }
  }

  getProductsByProfileID() {

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    this.http
      .get(`${environment.qsAdminUrl}/products-by-profile`, { headers, params })
      .subscribe(
        (response: any) => {
          this.products = response.products;
          console.log('Products:', this.products);
        },
        (error) => {
          console.error('Error fetching products:', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          }
        }
      );
  }

  roleTypeLable: any = null;
  roleType: any = null;
  onAdminCategorySelected(event: any) {
    // Set the selected roleType Lable
    this.roleTypeLable = event.target.options[event.target.selectedIndex].text;
    console.log('the roleTypeLable is', this.roleTypeLable);
    // Set the selected roleType value
    this.roleType = event.target.value;
    console.log('the roleType is', this.roleType);
  }

  districtLabel: any = null;
  districtValue: any = null;
  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
    console.log('this.districtLabel', this.districtLabel);
    console.log('this.districtValue', this.districtValue);
    this.districtName = this.districtValue;
    console.log('this.districtName', this.districtName);
  }

  passwordReset() {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const profileID = this.profileID;

    Swal.fire({
      title: 'Are you sure you want to reset the password?',
      text: 'The new password is: ' + passwordReset,
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('newKeyword', passwordReset)
          .set('profileID', profileID);

        const options = { headers, params };
        this.http
          .put(KS_ADMIN_URL + '/passwordResetKPSeller', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Password reset cancelled', '', 'info');
      }
    });
  }

  deleteAccount() {
    const profileID = this.profileID;

    Swal.fire({
      title: 'Are you sure you want to delete the account?',
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams().set('profileID', profileID);

        const options = { headers, params };
        this.http
          .put(KS_ADMIN_URL + '/deleteAccountForSeller', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
              this.getServiceProviderDescription();
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Account delete cancelled', '', 'info');
      }
    });
  }

  goBack() {
    this.location.back();
  }

  enableEdit() {
    this.edit = !this.edit;
  }

  showProductDetails(productId: string) {
    this.router.navigate(['/productDescription'], {
      queryParams: { productID: productId, profileID: this.profileID },
    });
  }

  checkphone1(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber1 = parseInt(input.value, 10);
    }
  }

  checkAgeLength() {
    // Ensure age is within 0-99 range
    if (this.wardNumber.length > 6) {
      this.wardNumber = this.wardNumber.slice(0, 2);
    }
  }

  checkPincodeLength() {
    // Ensure age is within 0-99 range
    if (this.pincode.length > 7) {
      this.pincode = this.pincode.slice(0, 7);
    }
  }

  checkWhatsApp(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.whatsAppNumber = parseInt(input.value, 10);
    }
  }

  checkExperience(input: HTMLInputElement) {
    if (input.value.length > 2) {
      input.value = input.value.slice(0, 2);
      // this.experience = parseInt(input.value, 2);
    }
  }

  fassiDocumentDelete: string = '';
  gstDocumentDelete: string = '';
  gitagDocumentDelete: string = '';
  tradeMarkDocumentDelete: string = '';
  udyamDocumentDelete: string = '';

  //to get the details
  getServiceProviderDescription() {
    this.spinnerForServiceProviderDescription = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);
    this.http
      .get(KS_ADMIN_URL + '/kpSellerDescription', { headers, params })
      .subscribe(
        (data) => {
          this.spinnerForServiceProviderDescription = false;
          this.serviceProviderDescription = data;
          console.log(
            'serviceProviderDescription',
            this.serviceProviderDescription
          );

          this.fassiDocumentDelete =
            this.serviceProviderDescription.fssaiPDF[0];
          this.gstDocumentDelete =
            this.serviceProviderDescription.gstDocumentsPDF[0];
          this.gitagDocumentDelete =
            this.serviceProviderDescription.GITagPDF[0];
          this.tradeMarkDocumentDelete =
            this.serviceProviderDescription.tradeMarkPDF[0];
          this.udyamDocumentDelete =
            this.serviceProviderDescription.UdyamRegistrationPDF[0];

            this.onlineSeller = this.serviceProviderDescription.onlineSeller
          console.log('this.fassiDocumentDelete', this.fassiDocumentDelete);
          console.log('this.gstDocumentDelete', this.gstDocumentDelete);
          console.log('this.gitagDocumentDelete', this.gitagDocumentDelete);
          console.log(
            'this.tradeMarkDocumentDelete',
            this.tradeMarkDocumentDelete
          );
          console.log('this.udyamDocumentDelete', this.udyamDocumentDelete);

          this.accountDelete = this.serviceProviderDescription.accountSatus;

          this.meUnitName = this.serviceProviderDescription.meUnitName;
          this.phoneNumber1 = this.serviceProviderDescription.phoneNumber;
          this.whatsAppNumber = this.serviceProviderDescription.whatsappNumber;
          this.localBodyType = this.serviceProviderDescription.localBodyType;
          this.localBodyName = this.serviceProviderDescription.localBodyName;

          this.wardName = this.serviceProviderDescription.address.wardName;
          this.wardNumber = this.serviceProviderDescription.address.wardNumber;

          this.fssaiExpiryDate = this.datePipe.transform(
            this.serviceProviderDescription.fssaiExpiryDate,
            'yyyy-MM-dd'
          );
          console.log('this.fssaiExpiryDate', this.fssaiExpiryDate);

          this.accountDeleted = this.serviceProviderDescription.accountSatus;

          this.category = this.serviceProviderDescription.category;

          this.sisdSubcategory =
            this.serviceProviderDescription.sisdSubcategory || '';
          this.setSisdSubcategoryLabel();

          console.log('the category', this.category);

          if (this.category) {
            this.setCategoryLabel();
          }

          this.bankIFSCcode =
            this.serviceProviderDescription.bankDetails.ifscCode;
          this.bankAccountNumber =
            this.serviceProviderDescription.bankDetails.accountNumber;
          this.bankBranch =
            this.serviceProviderDescription.bankDetails.bankBranch;
          this.bankName = this.serviceProviderDescription.bankDetails.bankName;

          this.meAppID = this.serviceProviderDescription.meAppID;
          this.fssaiID = this.serviceProviderDescription.fssaiID;
          this.GSTNumber = this.serviceProviderDescription.gstNumber;
          this.CDSName = this.serviceProviderDescription.cdsName;
          this.CDSNumber =
            this.serviceProviderDescription.cdsRegistrationNumber;

          this.buildingName =
            this.serviceProviderDescription.address.buildingName;
          this.streetName = this.serviceProviderDescription.address.streetName;
          this.landmark = this.serviceProviderDescription.address.landMark;
          this.pincode = this.serviceProviderDescription.address.pinCode;
          this.districtName = this.serviceProviderDescription.address.district;

          this.approvalStatus = this.serviceProviderDescription.approvalStatus;
          this.cdsDocumentUrl =
            this.serviceProviderDescription.cdsDocumentsProofUrls;

          this.fssaiDocumentUrl =
            this.serviceProviderDescription.fssaiDocumentsProofUrls;

          this.bankPassBookUrl =
            this.serviceProviderDescription.bankDetails.bankPassBookUrl;
          this.gstDocumentUrl =
            this.serviceProviderDescription.gstDocumentsProofUrls;

          this.GITagDocumentUrl =
            this.serviceProviderDescription.GITagDocumentsProofUrls;
          this.tradeMarkDocumentUrl =
            this.serviceProviderDescription.tradeMarkDocumentsProofUrls;
          this.UdyamDocumentsProofUrls =
            this.serviceProviderDescription.UdyamDocumentsProofUrls;
          this.nhgName = this.serviceProviderDescription.nhgName;
          this.upiID = this.serviceProviderDescription.bankDetails.upiID;
          this.accountHolderName =
            this.serviceProviderDescription.bankDetails.accountHolderName;
          this.email = this.serviceProviderDescription.email;
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForServiceProviderDescription = false;
          }
          // Handle error response
        }
      );
  }

  setSisdSubcategoryLabel() {
    switch (this.sisdSubcategory) {
      case 'ID_SD':
        this.sisdSubcategoryLabel = 'SD';
        break;
      case 'ID_TRIL':
        this.sisdSubcategoryLabel = 'Tribal';
        break;
      default:
        this.sisdSubcategoryLabel = this.sisdSubcategory;
    }
  }

  //To check the user role

  showAlert(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  }

  updateSellerDetails() {
    if (!this.meUnitName) {
      this.showAlert('Please enter the unit name');
      return;
    }
    if (!this.phoneNumber1) {
      this.showAlert('Please enter your phone number');
      return;
    }
    if (this.phoneNumber1.toString().length !== 10) {
      this.showAlert('Phone number must be 10 characters long');
      return;
    }

    if (!this.whatsAppNumber) {
      this.showAlert('Please enter the WhatsApp number');
      return;
    }
    if (this.whatsAppNumber.toString().length !== 10) {
      this.showAlert('WhatsApp Number number must be 10 characters long');
      return;
    }

    if (!this.CDSName) {
      this.showAlert('Please enter CDS Name');
      return;
    }
    if (!this.CDSNumber) {
      this.showAlert('Please enter CDS registration number');
      return;
    }

    if (!this.nhgName) {
      this.showAlert('Please enter CDS NHG name');
      return;
    }
    if (!this.bankName) {
      this.showAlert('Please enter the bank name');
      return;
    }
    if (!this.bankBranch) {
      this.showAlert('Please enter the bank branch');
      return;
    }
    if (!this.bankAccountNumber) {
      this.showAlert('Please enter the bank account number');
      return;
    }
    if (!this.bankIFSCcode) {
      this.showAlert('Please enter the bank IFSC code');
      return;
    }

    if (!this.localBodyType) {
      this.showAlert('Please enter the local body type');
      return;
    }
    if (!this.localBodyName) {
      this.showAlert('Please enter the local body name');
      return;
    }
    if (!this.wardName) {
      this.showAlert('Please enter the ward name');
      return;
    }

    if (!this.districtName) {
      this.showAlert('Please enter the district name');
      return;
    }

    if (!this.buildingName) {
      this.showAlert('Please enter building name');
      return;
    }
    if (!this.streetName) {
      this.showAlert('Please enter street name');
      return;
    }
    if (!this.landmark) {
      this.showAlert('Please enter landmark');
      return;
    }
    if (!this.accountHolderName) {
      this.showAlert('Please enter Bank Account Holder Name');
      return;
    }

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const formData = new FormData();

    formData.append('fssaiExpiryDate', this.fssaiExpiryDate);
    formData.append('meUnitName', this.meUnitName);
    formData.append('phoneNumber', this.phoneNumber1.toString());
    formData.append('whatsAppNumber', this.whatsAppNumber.toString());
    formData.append('localBodyType', this.localBodyType);
    formData.append('localBodyName', this.localBodyName);
    formData.append('wardName', this.wardName);
    formData.append('wardNumber', this.wardNumber);
    formData.append('districtName', this.districtName);
    formData.append('category', this.category);
    formData.append('bankIFSCcode', this.bankIFSCcode);
    formData.append('bankAccountNumber', this.bankAccountNumber);
    formData.append('bankBranch', this.bankBranch);
    formData.append('bankName', this.bankName);
    formData.append('meAppID', this.meAppID);
    formData.append('fssaiID', this.fssaiID);
    formData.append('GSTNumber', this.GSTNumber);
    formData.append('CDSName', this.CDSName);
    formData.append('CDSNumber', this.CDSNumber);
    formData.append('buildingName', this.buildingName);
    formData.append('streetName', this.streetName);
    formData.append('landmark', this.landmark);
    formData.append('pincode', this.pincode);
    formData.append('approvalStatus', this.approvalStatus);
    formData.append('accountHolderName', this.accountHolderName);
    formData.append('email', this.email);
    formData.append('upiID', this.upiID);
    formData.append('nhgName', this.nhgName);


    formData.append('onlineSeller', this.onlineSeller.toString());

    if (this.bankProofFile) {
      formData.append(
        'bankPassBook',
        this.bankProofFile,
        this.bankProofFile.name
      );
    }

    if (this.cdsDocuments) {
      formData.append(
        'cdsDocuments',
        this.cdsDocuments,
        this.cdsDocuments.name
      );
    }

    if (this.gstDocuments) {
      formData.append(
        'gstDocuments',
        this.gstDocuments,
        this.gstDocuments.name
      );
    }

    if (this.fssai) {
      formData.append('fssai', this.fssai, this.fssai.name);
    }

    if (this.GITag) {
      formData.append('GITag', this.GITag, this.GITag.name);
    }

    if (this.tradeMark) {
      formData.append('tradeMark', this.tradeMark, this.tradeMark.name);
    }

    if (this.UdyamRegistration) {
      formData.append(
        'UdyamRegistration',
        this.UdyamRegistration,
        this.UdyamRegistration.name
      );
    }

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to update the seller details?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerButtonForUpdate = true;
        // If user clicks OK, execute the API request
        this.http
          .put(KS_ADMIN_URL + '/update/SellerDetails', formData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              Swal.fire(
                'Success!',
                'Seller description updated successfully.',
                'success'
              );
              this.getServiceProviderDescription();
              this.edit = false;
              this.spinnerButtonForUpdate = false;
            },
            (error) => {
              console.error('Error', error);
              Swal.fire(
                'Error!',
                'Failed to update seller description.',
                'error'
              );
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
                this.spinnerButtonForUpdate = false;
              }
            }
          );
      }
    });
  }

  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

  //to edit the pdf

  selectedImage: any | null = null;
  cdsDocuments: File | null = null;
  gstDocuments: File | null = null;
  defaultImage: string = '../../../assets/profileImage/profileImage.png';

  tradeMark: File | null = null;

  UdyamRegistration: File | null = null;
  GITag: File | null = null;
  fssai: File | null = null;
  bankProofFile: File | null = null;

  selectedIdProofFile: File | null = null;
  selectedAddressProofFile: File | null = null;

  onFileSelected(event: Event, proofType: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);

      // Check if the file is a PDF
      if (file.type !== 'application/pdf') {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File Type',
          text: 'Please upload a PDF file.',
        });
        // Clear the file input
        input.value = '';
        this.clearFileSelection(proofType);
        return;
      }

      // Check for file size limit (2MB)
      if (fileSizeInMB > 2) {
        Swal.fire({
          icon: 'error',
          title: 'File Size Exceeded',
          text: 'The selected file exceeds the maximum allowed size of 2MB. Please reduce the file size.',
        });
        // Clear the file input
        input.value = '';
        this.clearFileSelection(proofType);
      } else {
        // Construct the new file name with a .pdf extension
        const newFileName = `${this.profileID}_${proofType}.pdf`;

        // Create a new File object with the new name
        const renamedFile = new File([file], newFileName, { type: file.type });

        this.assignFileToProofType(proofType, renamedFile); // Assign the renamed file to the correct variable

        console.log('Selected file:', proofType, renamedFile);
      }
    }
  }

  clearFileSelection(proofType: string): void {
    if (proofType === 'addressProof') {
      this.selectedAddressProofFile = null;
    } else if (proofType === 'idProof') {
      this.selectedIdProofFile = null;
    } else if (proofType === 'bankPassBook') {
      this.bankProofFile = null;
    } else if (proofType === 'cdsDocuments') {
      this.cdsDocuments = null;
    } else if (proofType === 'gstDocuments') {
      this.gstDocuments = null;
    } else if (proofType === 'fssai') {
      this.fssai = null;
    } else if (proofType === 'GITag') {
      this.GITag = null;
    } else if (proofType === 'tradeMark') {
      this.tradeMark = null;
    } else if (proofType === 'UdyamRegistration') {
      this.UdyamRegistration = null;
    }
  }

  assignFileToProofType(proofType: string, file: File): void {
    if (proofType === 'addressProof') {
      this.selectedAddressProofFile = file;
    } else if (proofType === 'idProof') {
      this.selectedIdProofFile = file;
    } else if (proofType === 'bankPassBook') {
      this.bankProofFile = file;
    } else if (proofType === 'cdsDocuments') {
      this.cdsDocuments = file;
    } else if (proofType === 'gstDocuments') {
      this.gstDocuments = file;
    } else if (proofType === 'fssai') {
      this.fssai = file;
    } else if (proofType === 'GITag') {
      this.GITag = file;
    } else if (proofType === 'tradeMark') {
      this.tradeMark = file;
    } else if (proofType === 'UdyamRegistration') {
      this.UdyamRegistration = file;
    }
  }

  // preview pdf implementation start (dependency on ngoninit)

  showModal: boolean = false;
  pdfSrc: any = '';

  documentUrls: any;
  selectedPdfUrl: string;
  getSellerDocuments() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(
        `${environment.qsAdminUrl}/kpSellerDocuments?profileID=${this.profileID}`,
        { headers }
      )
      .subscribe(
        (response: any) => {
          this.documentUrls = response;
          console.log('Document URLs:', this.documentUrls);
        },
        (error) => {
          console.error('Error fetching seller documents:', error);
        }
      );
  }

  previewPdf(url: string) {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.jwtToken}`
    );

    this.http
      .get(environment.qsAdminUrl + url, {
        headers,
        responseType: 'arraybuffer',
      })
      .subscribe(
        (data: ArrayBuffer) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          this.pdfSrc = URL.createObjectURL(blob);
          this.showModal = true;
        },
        (error) => {
          console.error('Error loading PDF:', error);
          // Handle error (e.g., show error message to user)
        }
      );
  }

  closeModal(): void {
    this.showModal = false;
  }
  // preview pdf implementation end (dependency on ngoninit)

  deleteFssaiDocument() {
    console.log('delete fssai document');
    console.log('this.fassiDocumentDelete', this.fassiDocumentDelete);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the FSSAI document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('profileID', this.profileID)
          .set('documentKey', this.fassiDocumentDelete)
          .set('pdfField', 'fssaiPDF')
          .set('urlField', 'fssaiDocumentsProofUrls');

        this.http
          .delete(`${KS_ADMIN_URL}/delete-seller-document`, { headers, params })
          .subscribe(
            (response: any) => {
              Swal.fire(
                'Deleted!',
                'FSSAI document has been deleted successfully.',
                'success'
              );
              // Refresh the service provider description to update the UI
              window.location.reload();
              this.getServiceProviderDescription();
            },
            (error) => {
              console.error('Error deleting FSSAI document:', error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                Swal.fire(
                  'Error!',
                  'Failed to delete FSSAI document. Please try again.',
                  'error'
                );
              }
            }
          );
      }
    });
  }

  deleteGstDocument() {
    console.log('delete gst document');
    console.log('this.gstDocumentDelete', this.gstDocumentDelete);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the GST document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('profileID', this.profileID)
          .set('documentKey', this.gstDocumentDelete)
          .set('pdfField', 'gstDocumentsPDF')
          .set('urlField', 'gstDocumentsProofUrls');

        this.http
          .delete(`${KS_ADMIN_URL}/delete-seller-document`, { headers, params })
          .subscribe(
            (response: any) => {
              Swal.fire(
                'Deleted!',
                'GST document has been deleted successfully.',
                'success'
              );
              // Refresh the service provider description to update the UI
              window.location.reload();
              this.getServiceProviderDescription();
            },
            (error) => {
              console.error('Error deleting GST document:', error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                Swal.fire(
                  'Error!',
                  'Failed to delete GST document. Please try again.',
                  'error'
                );
              }
            }
          );
      }
    });
  }

  deleteGITagDocument() {
    console.log('delete gitag document');
    console.log('this.gitagDocumentDelete', this.gitagDocumentDelete);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the GI TAG document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('profileID', this.profileID)
          .set('documentKey', this.gitagDocumentDelete)
          .set('pdfField', 'GITagPDF')
          .set('urlField', 'GITagDocumentsProofUrls');

        this.http
          .delete(`${KS_ADMIN_URL}/delete-seller-document`, { headers, params })
          .subscribe(
            (response: any) => {
              Swal.fire(
                'Deleted!',
                'GI TAG document has been deleted successfully.',
                'success'
              );
              // Refresh the service provider description to update the UI
              window.location.reload();
              this.getServiceProviderDescription();
            },
            (error) => {
              console.error('Error deleting GI TAG document:', error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                Swal.fire(
                  'Error!',
                  'Failed to delete GI TAG document. Please try again.',
                  'error'
                );
              }
            }
          );
      }
    });
  }

  deleteTradeMarkDocument() {
    console.log('delete trade mark document');
    console.log('this.tradeMarkDocumentDelete', this.tradeMarkDocumentDelete);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the Trade Mark document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('profileID', this.profileID)
          .set('documentKey', this.tradeMarkDocumentDelete)
          .set('pdfField', 'tradeMarkPDF')
          .set('urlField', 'tradeMarkDocumentsProofUrls');

        this.http
          .delete(`${KS_ADMIN_URL}/delete-seller-document`, { headers, params })
          .subscribe(
            (response: any) => {
              Swal.fire(
                'Deleted!',
                'Trade Mark document has been deleted successfully.',
                'success'
              );
              window.location.reload();
              // Refresh the service provider description to update the UI
              this.getServiceProviderDescription();
            },
            (error) => {
              console.error('Error deleting Trade Mark document:', error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                Swal.fire(
                  'Error!',
                  'Failed to delete Trade Mark document. Please try again.',
                  'error'
                );
              }
            }
          );
      }
    });
  }
  deleteUdyamDocument() {
    console.log('delete udyam document');
    console.log('this.udyamDocumentDelete', this.udyamDocumentDelete);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the Udyam Registration document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('profileID', this.profileID)
          .set('documentKey', this.udyamDocumentDelete)
          .set('pdfField', 'UdyamRegistrationPDF')
          .set('urlField', 'UdyamDocumentsProofUrls');

        this.http
          .delete(`${KS_ADMIN_URL}/delete-seller-document`, { headers, params })
          .subscribe(
            (response: any) => {
              Swal.fire(
                'Deleted!',
                'Udyam Registration document has been deleted successfully.',
                'success'
              );
              // Refresh the service provider description to update the UI
              window.location.reload();
              this.getServiceProviderDescription();
            },
            (error) => {
              console.error(
                'Error deleting Udyam Registration document:',
                error
              );
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                Swal.fire(
                  'Error!',
                  'Failed to delete Udyam Registration document. Please try again.',
                  'error'
                );
              }
            }
          );
      }
    });
  }

  async onOnlineSellerToggle(event: any) {
    const newValue = event.target.checked;

    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      html: `
      <p>Do you want to ${newValue ? 'enable' : 'disable'} online seller status?</p>
      ${newValue ? `
        <p style="color: #dc3545; margin-top: 10px;" >
          <strong>Important:</strong> Please ensure you have thoroughly verified:
          <ul style="text-align: left; margin-top: 5px;">
            <li>All seller details</li>
            <li>Required documents (FSSAI, GST, etc.)</li>
            <li>Bank account information</li>
            <li>Address and contact details</li>
          </ul>
          It is your responsibility to verify all information before proceeding.
        </p>
      ` : ''}
    `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    // If user cancels, revert the toggle
    if (!result.isConfirmed) {
      this.onlineSeller = !newValue;
      return;
    }

    // If user confirms, show success message
    // Swal.fire({
    //   title: 'Updated!',
    //   text: `Online seller status has been ${
    //     newValue ? 'enabled' : 'disabled'
    //   }.`,
    //   icon: 'success',
    //   timer: 2000,
    //   showConfirmButton: false,
    // });

    Swal.fire({
      title: 'Please Note',
      text: 'Please click the "Update" button to save your changes.',
      icon: 'info',
      confirmButtonColor: '#3085d6',
    });
  }
  showOrderList(){
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    this.http.get(`${KS_ADMIN_URL}/sellerOrderDetails`, {headers,params}).subscribe((response:any)=>{
      console.log("response vannu: ",response);
     
      this.orderList = response.orderdetails;

    })

  }

}


