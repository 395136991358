<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <div class="row" *ngIf="orderDetails">
    <!-- Order Summary Card -->
    <div class="col-12 mb-4">
      <div class="card headerCard">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4 class="mb-2">
                Order #{{ orderDetails.subOrders[0].subOrderId }}
              </h4>
              <p class="mb-0 opacity-75">
                Ordered on: {{ orderDetails.createdAt | date : "medium" }}
              </p>
            </div>
            <div>
              <h4
                class="mb-2"
                (click)="
                  navigateToSellerDescription(
                    orderDetails.subOrders[0].vendorProfileID
                  )
                "
                style="cursor: pointer"
              >
                Seller details #{{ orderDetails.subOrders[0].vendorProfileID }}
              </h4>
            </div>
            <div
              class="payment-status"
              [ngClass]="{
                'payment-pending':
                  orderDetails.payment.paymentStatus === 'PENDING',
                'payment-success':
                  orderDetails.payment.paymentStatus === 'SUCCESS'
              }"
            >
              {{ orderDetails.payment.paymentStatus }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Shipping Address -->
    <div class="col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">Shipping Address</h5>
          <div class="address-details">
            <p class="mb-1 fw-bold">{{ orderDetails.shippingAddress.name }}</p>
            <p class="mb-1">{{ orderDetails.shippingAddress.houseName }}</p>
            <p class="mb-1">{{ orderDetails.shippingAddress.street }}</p>
            <p class="mb-1">
              {{ orderDetails.shippingAddress.city }},
              {{ orderDetails.shippingAddress.district }}
            </p>
            <p class="mb-1">
              {{ orderDetails.shippingAddress.state }} -
              {{ orderDetails.shippingAddress.pincode }}
            </p>
            <p class="mb-0">
              <i class="fas fa-phone-alt me-2"></i
              >{{ orderDetails.shippingAddress.mobile }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Payment Details -->
    <div class="col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">Payment Information</h5>
          <div class="payment-details">
            <p class="mb-3">
              <span class="text-muted">Payment Method:</span>
              <span class="ms-2 fw-medium">{{
                orderDetails.paymentMethod || "Not Selected"
              }}</span>
            </p>
            <p class="mb-3">
              <span class="text-muted">Payment Status:</span>
              <span class="ms-2 fw-medium">{{
                orderDetails.payment.paymentStatus
              }}</span>
            </p>

            <p
              class="mb-0"
              *ngIf="orderDetails.subOrders[0].replacementStatus !== 'None'"
            >
              <span class="text-muted">Replacement Status:</span>
              <span class="ms-2 fw-medium replacementStatus">{{
                orderDetails.subOrders[0].replacementStatus
              }}</span>
            </p>

            <p class="mb-0">
              <span class="text-muted">Currency:</span>
              <span class="ms-2 fw-medium">{{
                orderDetails.payment.paymentCurrency
              }}</span>
            </p>
            <p class="mb-0">
              <span class="text-muted">GST Amount:</span>
              <span class="ms-2 fw-medium">{{
                orderDetails.subOrders[0].gstAmount.toFixed(2)
              }}</span>
            </p>
            <p class="mb-0">
              <span class="text-muted">Price Excluding GST:</span>
              <span class="ms-2 fw-medium">{{
                orderDetails.subOrders[0].priceExcludingGST.toFixed(2)
              }}</span>
            </p>
            <p class="mb-0">
              <span class="text-muted">Price Including GST:</span>
              <span class="ms-2 fw-medium">{{
                orderDetails.subOrders[0].priceIncludingGST.toFixed(2)
              }}</span>
            </p>
            <p class="mb-0">
              <span class="text-muted">shipping charge</span>
              <span class="ms-2 fw-medium">{{
                orderDetails.subOrders[0].shippingChargeIncludingGST.toFixed(2)
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Order Items -->
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Order Items</h5>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price (Inc. GST)</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orderDetails.subOrders">
                  <td>
                    <span class="fw-medium">{{ item.productName }}</span>
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td>₹{{ item.priceIncludingGST }}</td>
                  <td>
                    <span
                      class="status-badge"
                      [ngClass]="{
                        'status-pending': item.subOrderStatus === 'Pending',
                        'status-completed': item.subOrderStatus === 'Completed'
                      }"
                    >
                      {{ item.subOrderStatus }}
                    </span>
                  </td>
                  <td>

                    <button
                      *ngIf="item.replacementStatus == 'None' && item.orderCompleted == false                      "
                      (click)="handleReplacement(item.subOrderId)"
                      class="btn btn-primary"
                    >
                      Replace
                    </button>

                    <button
                      *ngIf="item.replacementStatus == 'Started'"
                      (click)="handleReplacementCompleted(item.subOrderId)"
                      class="btn btn-success"
                    >
                      Completed
                    </button>
                    <button
                      *ngIf="item.replacementStatus == 'Completed'"
                      (click)="handleReplacementCompleted(item.subOrderId)"
                      disabled
                      class="btn btn-success"
                    >
                      Completed
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
