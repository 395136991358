<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Online Order Listing</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Products >Online order listings</p>
    </div>
  </div>

  <div class="row card headerCard p-1 m-1">
    <div class="col pt-2">
      <mat-form-field appearance="fill" style="margin-right: 10px">
        <mat-label>Enter your start date</mat-label>
        <input
          matInput
          [max]="maxDate"
          [(ngModel)]="startDate"
          [matDatepicker]="firstPicker"
          (dateChange)="onDateChange($event, 'First Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="firstPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #firstPicker
          [panelClass]="['datepicker-overlay']"
        ></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Enter your end date</mat-label>
        <input
          matInput
          [max]="maxDate"
          [matDatepicker]="secondPicker"
          [(ngModel)]="endDate"
          (dateChange)="onDateChange($event, 'Second Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="secondPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #secondPicker
          [panelClass]="['datepicker-overlay']"
        ></mat-datepicker>
      </mat-form-field>

      <button
        *ngIf="!spinnerForSearch"
        (click)="checkUser()"
        style="margin-left: 15px"
        type="button"
        class="btn btn-success"
      >
        Search
      </button>
      <button
        type="button"
        class="btn btn-primary ms-2"
        data-bs-toggle="modal"
        data-bs-target="#filterModal"
      >
        <i class="bi bi-funnel"></i> Filter
      </button>

      <!-- Active Filters Display -->
      <div class="d-inline-block ms-3">
        <span class="badge bg-light text-dark me-2" *ngIf="searchTerm">
          {{ searchType === "subOrderId" ? "Order ID" : "Phone" }}:
          {{ searchTerm }}
          <i
            class="bi bi-x-circle-fill ms-1"
            style="cursor: pointer"
            (click)="searchTerm = ''"
          ></i>
        </span>
        <span class="badge bg-light text-dark me-2" *ngIf="paymentStatus">
          Payment: {{ paymentStatus }}
          <i
            class="bi bi-x-circle-fill ms-1"
            style="cursor: pointer"
            (click)="paymentStatus = ''"
          ></i>
        </span>
        <span class="badge bg-light text-dark me-2" *ngIf="orderStatus">
          Order Status: {{ orderStatus }}
          <i
            class="bi bi-x-circle-fill ms-1"
            style="cursor: pointer"
            (click)="orderStatus = ''"
          ></i>
        </span>
        <span class="badge bg-light text-dark me-2" *ngIf="paymentMethod">
          Payment Method: {{ paymentMethod }}
          <i
            class="bi bi-x-circle-fill ms-1"
            style="cursor: pointer"
            (click)="paymentMethod = ''"
          ></i>
        </span>
        <span class="badge bg-light text-dark me-2" *ngIf="selectedDistrict">
          District: {{ selectedDistrict }}
          <i
            class="bi bi-x-circle-fill ms-1"
            style="cursor: pointer"
            (click)="selectedDistrict = ''"
          ></i>
        </span>
      </div>

      <!-- Filter Modal -->
      <div
        class="modal fade"
        id="filterModal"
        tabindex="-1"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">Filter Orders</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <!-- Search Filter -->
              <div class="mb-3">
                <label class="form-label">Search by</label>
                <div class="input-group">
                  <select
                    class="form-select"
                    [(ngModel)]="searchType"
                    (change)="currentPage = 1"
                  >
                    <option value="subOrderId">Order ID</option>
                    <option value="mobile">Phone Number</option>
                  </select>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="searchTerm"
                    placeholder="Enter search term"
                    (change)="currentPage = 1"
                  />
                </div>
              </div>
              <hr>

              <!-- district  -->

              <div class="md-3" *ngIf="!isMarketingAdmin()&&!isDistrictAdmin()">
                <label class="form-label">District</label>
                <label
                  for="districtFilter"
                  style="color: white"
                  class="form-label"
                  >Filter by District:</label
                >
                <select
                  id="districtFilter"
                  class="form-select"
                  [(ngModel)]="selectedDistrict"
                  (change)="currentPage = 1"
                >
                  <option value="" selected>All Districts</option>
                  <option
                    *ngFor="let district of districts"
                    [value]="district.name"
                  >
                    {{ district.name }}
                  </option>
                </select>
              </div>

              <!-- Payment Status -->
              <div class="mb-3">
                <label class="form-label">Payment Status</label>
                <select
                  class="form-select"
                  [(ngModel)]="paymentStatus"
                  (change)="currentPage = 1"
                >
                  <option value="">All</option>
                  <option value="PAID">Paid</option>
                  <option value="PEN">Pending</option>
                  <option value="FAIL">Failed</option>
                  <option value="NPD">Not Paid</option>
                  <option value="REFUND">Refunded</option>
                </select>
              </div>

              <!-- Order Status -->
              <div class="mb-3">
                <label class="form-label">Order Status</label>
                <select
                  class="form-select"
                  [(ngModel)]="orderStatus"
                  (change)="currentPage = 1"
                >
                  <option value="">All</option>
                  <option value="PEN">Pending</option>
                  <option value="ACC">Processing</option>
                  <option value="SHP">Shipped</option>
                  <option value="OFD">Out for Delivery</option>
                  <option value="DEL">Delivered</option>
                  <option value="CAN">Cancelled</option>
                </select>
              </div>

              <!-- district  -->

              <!-- <div class="md-3">
                <label for="districtFilter" style="color: white;" class="form-label">Filter by District:</label>
                <select id="districtFilter" class="form-select" [(ngModel)]="selectedDistrict" (change)="currentPage = 1">
                  <option value="" selected>All Districts</option>
                  <option *ngFor="let district of districts" [value]="district.name">
                    {{ district.name }}
                  </option>
                </select>
              </div> -->

              <!-- Payment Method -->
              <!-- <div class="mb-3">
                <label class="form-label">Payment Method</label>
                <select
                  class="form-select"
                  [(ngModel)]="paymentMethod"
                  (change)="currentPage = 1"
                >
                  <option value="">All</option>
                  <option value="ONLINE">Online</option>
                  <option value="COD">Cash on Delivery</option>
                </select>
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="resetFilters()"
                data-bs-dismiss="modal"
              >
                Reset
              </button>
              <button
                type="button"
                class="btn btn-primary"
                (click)="applyFilters()"
                data-bs-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
  <div
  class="d-flex justify-content-center my-4"
  *ngIf="spinnerForSearch"
>
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
  <div class="table-responsive mt-4" *ngIf="!spinnerForSearch">
    <table class="table table-striped table-hover shadow-sm">
      <thead class="bg-light">
        <tr>
          <th scope="col">Order ID</th>
          <th scope="col">Order Date</th>
          <th scope="col">Product Name</th>

          <th scope="col">Quantity</th>
          <th scope="col">Price (₹)</th>
          <!-- <th scope="col">GST Details</th> -->
          <!-- <th scope="col">Shipping</th> -->
          <th scope="col">Order Status</th>
          <th scope="col">Details</th>
          <!-- <th scope="col">Return/Refund</th> -->
        </tr>
      </thead>
      <tbody>

        <tr *ngIf="!orderList || orderList.length === 0">
          <td colspan="7" class="text-center py-4">No orders found</td>
        </tr>

        <tr *ngFor="let order of orderList">
          <td>{{ order.subOrderId }}</td>
          <td>{{ order.orderDate | date : "medium" }}</td>
          <td>
            {{ order.productName }}
            <br />
            <small class="text-muted">ID: {{ order.productID }}</small>
          </td>
          <td>{{ order.quantity }}</td>
          <td>
            <div>Total: ₹{{ order.priceIncludingGST }}</div>
            <!-- <small class="text-muted">Base: ₹{{order.priceExcludingGST}}</small> -->
          </td>
          <!-- <td>
            <div>CGST: {{order.cgst}}%</div>
            <div>SGST: {{order.sgst}}%</div>
            <div>IGST: {{order.igst}}%</div>
            <small class="text-muted">Amount: ₹{{order.gstAmount}}</small>
          </td> -->
          <!-- <td>
            <div>₹{{order.shippingChargeIncludingGST}}</div>
            <small class="text-muted">GST: {{order.shippingGstPercentage}}%</small>
          </td> -->
          <td>
            <span
              class="badge rounded-pill"
              [ngClass]="{
                'bg-warning text-dark': order.subOrderStatus === 'PEN',
                'bg-info': order.subOrderStatus === 'ACC',
                'bg-primary': order.subOrderStatus === 'SHP',
                'bg-success': order.subOrderStatus === 'OFD',
                'bg-danger': order.subOrderStatus === 'DEL'
              }"
            >
              {{ order.subOrderStatus }}
            </span>
          </td>

          <td>
            <button class="btn btn-primary" (click)="viewdetail(order.subOrderId)">View Details</button>
          </td>
          <!-- <td>
            <div>Return: {{order.returnStatus}}</div>
            <div>Refund: {{order.refundStatus}}</div>
            <small *ngIf="order.refundAmount > 0">Amount: ₹{{order.refundAmount}}</small>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>

  <div
    class="d-flex justify-content-center align-items-center mt-3"
    *ngIf="totalPages > 0"
  >
    <!-- <div class="text-muted">
      Showing {{(currentPage - 1) * itemsPerPage + 1}} to
      {{Math.min(currentPage * itemsPerPage, totalItems)}} of {{totalItems}} entries
    </div> -->

    <nav aria-label="Page navigation">
      <ul class="pagination mb-0">
        <!-- Previous button -->
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" (click)="previousPage()" role="button">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>

        <!-- Page numbers -->
        <li
          class="page-item"
          *ngFor="let page of pages"
          [class.active]="page === currentPage"
        >
          <a class="page-link" (click)="changePage(page)" role="button">{{
            page
          }}</a>
        </li>

        <!-- Next button -->
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" (click)="nextPage()" role="button">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
