import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.css'],
})
export class OrderDescriptionComponent implements OnInit {
  subOrderId: string;
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.subOrderId = params['subOrderId'];
      this.getOnlineOrderDetails();
    });

    console.log('orderId here..', this.subOrderId);
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private Authservice: AuthServiceService
  ) {}

  orderDetails: any;
  navigateToSellerDescription(profileId: string) {
    this.router.navigate(['/sellerDescription'], {
      queryParams: { profileID: profileId },
    });
  }
  // checkUser() {
  //   if (this.isSuperAdmin() || this.isStateAdmin()) {

  //   } else if (this.isDistrictAdmin()) {
  //     // this.getOrderDetailsForDistrictAdmin();
  //   } else if (this.isMarketingAdmin()) {
  //     // this.getOrderDetailsForMarketingDistrictAdmin();
  //   } else {
  //     console.log("please check login");
  //   }
  // }
  // checkUser() {
  //   if (this.isStateAdmin()) {
  //     this.user = 'State Admin';
  //   } else if (this.isSuperAdmin()) {
  //     this.user = 'Super Admin';
  //   } else if (this.isMarketingAdmin()) {

  //     const district = this.Authservice.getDecodedDistrict();
  //     this.user = 'Marketing Admin - '+ district;

  //     console.log('the district is',district);
  //   }
  //   else if (this.isDistrictAdmin()) {
  //     this.user = 'District Admin';

  //     const district = this.Authservice.getDecodedDistrict();
  //     if (district) {
  //       console.log('Decoded district value:', district);
  //       this.district = district

  //       // this.district = district;
  //     } else {
  //       console.log('District information not available.');
  //       this.onLogout();
  //     }

  //     const roleType = this.Authservice.getDecodedRoleType();
  //     if (roleType) {
  //       console.log('Decoded district value:', roleType);

  //       this.roleType = roleType;

  //     } else {
  //       console.log('District information not available.');

  //       // this.onLogout();
  //     }

  //     this.user = this.district

  //   } else if (this.isMEUnit()) {
  //     this.user = 'ME Unit';
  //   }
  // }
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }
  getOnlineOrderDetails() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams();
    if (!this.subOrderId) {
      console.log('No sub order ID');
      return;
    }
    console.log('inside function ', this.subOrderId);
    this.http
      .get(QS_ADMIN_URL + `/getOnlineOrderDescription/${this.subOrderId}`, {
        headers,
        params,
      })
      .subscribe(
        (data: any) => {
          console.log(data);

          this.orderDetails = data.order;
          console.log('order details ', this.orderDetails);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  handleReplacement(item: any) {
    // alert(`${this.orderDetails.mainOrderId} ${item}`);
    // SweetAlert confirmation before proceeding
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

        // New PUT request to send item and mainOrderId to the backend
        const body = {
          mainOrderId: this.orderDetails.mainOrderId,
          subOrderId: item,
        };

        console.log('the body is', body);

        this.http
          .put(QS_ADMIN_URL + `/updateReplacementStatus`, body, { headers })
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              Swal.fire({
                title: 'Success!',
                text: 'The replacement status has been updated successfully.',
                icon: 'success',
                confirmButtonText: 'OK'
              });
              this.getOnlineOrderDetails();
            },
            (error) => {
              console.error('Update failed', error);
              Swal.fire({
                title: 'Error!',
                text: error.error.message || 'An unexpected error occurred.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            }
          );
      }
    });
  }



  handleReplacementCompleted(item: any) {
    // alert(`${this.orderDetails.mainOrderId} ${item}`);
    // SweetAlert confirmation before proceeding
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

        // New PUT request to send item and mainOrderId to the backend
        const body = {
          mainOrderId: this.orderDetails.mainOrderId,
          subOrderId: item,
        };

        console.log('the body is', body);

        this.http
          .put(QS_ADMIN_URL + `/updateReplacementStatusCompleted`, body, { headers })
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              Swal.fire({
                title: 'Success!',
                text: 'The replacement status has been updated successfully.',
                icon: 'success',
                confirmButtonText: 'OK'
              });
              this.getOnlineOrderDetails();
            },
            (error) => {
              console.error('Update failed', error);
              Swal.fire({
                title: 'Error!',
                text: error.error.message || 'An unexpected error occurred.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            }
          );
      }
    });
  }
}
