import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { QsIDsService } from '../../services/qs-ids.service';
import { ServiceRequestListingsService } from '../../services/service-request-listings.service';
import { DateAdapter } from '@angular/material/core';

import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-service-request-listings',
  templateUrl: './service-request-listings.component.html',
  styleUrls: ['./service-request-listings.component.css'],
  providers: [DatePipe],
})
export class ServiceRequestListingsComponent implements OnInit {
  //variables
  startDate: any = '';
  endDate: any = '';
  productList: any;
  spinnerForSearch: boolean = false;

  pendingCheckBox: boolean = false;
  processingCheckBox: boolean = false;
  assignedCheckBox: boolean = false;
  acceptedCheckBox: boolean = false;
  completedCheckBox: boolean = false;
  cancelledCheckBox: boolean = false;
  activeTab: 'date' | 'filters' = 'date';
  maxDate: Date;
  selectOrderStatus:string=''
  
  setFiltersTab() {
    this.productList = [];
    this.activeTab = 'filters';
    this.startDate = '';
    this.endDate = '';
    // this.selectOrderStatus='';

    this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
    this.ServiceRequestListingsService.setActiveTab(this.activeTab);
  }

  setDateTab() {
    this.productList = [];
    this.activeTab = 'date';
    this.selectedDistrict = '';
    this.selectedRoleType = '';
    this.selectOrderStatus='';
  

    
    this.ServiceRequestListingsService.setActiveTab(this.activeTab);
  }

  roleTypeToLabel(roleType: string): string {
    switch (roleType) {
      case 'type_1':
        return 'District Farmlivelihood';
      case 'type_2':
        return 'District NULM';
      case 'type_3':
        return 'District ME';
      case 'type_4':
        return 'District SVEP';
      case 'type_5':
        return 'District SISD';
      // case "type_6":
      //   return "District SD";
      case 'type_7':
        return 'District AH';
      default:
        return 'Unknown Role';
    }
  }

  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private ServiceRequestListingsService: ServiceRequestListingsService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.maxDate = new Date();
    // Optional: set locale if needed
    this.dateAdapter.setLocale('en-GB');
  }

  get currentPage(): number {
    return this.ServiceRequestListingsService.getCurrentPage();
  }

  get totalPages(): number {
    return this.ServiceRequestListingsService.getTotalPages();
  }

  ngOnInit() {
    this.fetchDistricts();

    const activeTab = this.ServiceRequestListingsService.getActiveTab();
    this.activeTab =
      activeTab === 'date' || activeTab === 'filters' ? activeTab : 'date';

    console.log('the activeTab is', activeTab);

    // Get saved filters
    if (activeTab === 'filters') {
      console.log('the filter api is working');
      this.selectedDistrict =
        this.ServiceRequestListingsService.getSelectedDistrict();
      this.selectedRoleType =
        this.ServiceRequestListingsService.getSelectedRoleType();

      this.selectedRejectedStatus =
        this.ServiceRequestListingsService.getSelectedRejectedStatus();
      this.selectedApprovedStatus =
        this.ServiceRequestListingsService.getSelectedApprovedStatus();
      this.selectedVerification =
        this.ServiceRequestListingsService.getSelectedVerification();
this.selectOrderStatus=
this.ServiceRequestListingsService.getSelectedOrderStatus();
      console.log(
        'the selected districta and selectRoleType',
        this.selectedDistrict,
        this.selectedRoleType,this.selectOrderStatus
      );
      // if (this.selectedDistrict || this.selectedRoleType) {
      console.log('the filter api is actual working');
      const savedPage = this.ServiceRequestListingsService.getCurrentPage();
      if (savedPage > 1) {
        this.searchByFilters(savedPage);
      } else {
        this.searchByFilters();
      }

      return;
      // }
    }

    // Handle date-based search
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();
    console.log('the startDate and endDate is', startDate, endDate);
    console.log('the tab is', activeTab);
      // this.ServiceRequestListingsService.setSelectedOrderStatus("");
      if (startDate && activeTab === 'date') {
      this.startDate = startDate;
      this.endDate = endDate;

      this.checkUser();
    
    } else if (!startDate) {
      this.startDate = new Date();
      this.ServiceRequestListingsService.setDates(
        this.startDate,
        this.startDate
      );
      this.checkUser();
    }

    const savedPage = this.ServiceRequestListingsService.getCurrentPage();
    if (savedPage > 1) {
      this.searchForProductsListings(savedPage);
    }
  }

  isProductRejected(rejectStatus): boolean {
    return ['stateReject', 'districtReject', 'marketingReject'].includes(
      rejectStatus
    );
  }

  getReadableStatus(status: string): string {
    switch (status) {
      case 'districtReject':
        return 'Rejected by District Admin';
      case 'stateReject':
        return 'Rejected by State Admin';
      case 'marketingReject':
        return 'Rejected by Marketing Admin';
      case 'districtPending':
        return 'Pending District Admin Approval';
      case 'statePending':
        return 'Pending State Admin Approval';
      case 'marketingPending':
        return 'Pending Marketing Admin Approval';
      default:
        return status;
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
    this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
  }

  checkUser() {
    // If no cached data, fetch based on user role
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.searchForProductsListings();
    } else if (this.isDistrictAdmin()) {
      this.searchForProductsListingsForDistrictAdmin();
    } else if (this.isMarketingAdmin()) {
      this.searchForProductsListingsForMarketingDistrictAdmin();
    } else {
      console.log('Please check login');
      // Optionally, you can redirect to a login page or show an error message
    }
  }

  searchForProductsListings(page: number = 1) {
    console.log('the selectedVerification is ', this.selectedVerification);
    console.log('the selectedApprovedStatus is ', this.selectedApprovedStatus);
    console.log('the selectedRejectedStatus is ', this.selectedRejectedStatus);
    console.log("the selectedOrderStatus",this.selectOrderStatus)

    const { startDate, endDate } =

      this.ServiceRequestListingsService.getDates();

    if (!endDate && !(this.selectedDistrict || this.selectedRoleType)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);

    } else if (new Date(endDate) < new Date(startDate)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
      //  this.ServiceRequestListingsService.setSelectedOrderStatus("");
    } else if (this.selectedDistrict || this.selectedRoleType) {
      this.ServiceRequestListingsService.setDates('', '');
    }

    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    // Use the selected district and role type
    const districtName = this.selectedDistrict;
    const roleType = this.selectedRoleType;
    const selectedVerification = this.selectedVerification;
    const selectedApprovedStatus = this.selectedApprovedStatus;
    const selectedRejectedStatus = this.selectedRejectedStatus;
 const selectOrderStatus=this.selectOrderStatus

    this.ServiceRequestListingsService.searchForServiceListings(
      token,
      selectedVerification,
      selectedApprovedStatus,
      selectedRejectedStatus,
      districtName,
      roleType,
        selectOrderStatus,
      
      page
    ).subscribe(
      (response: any) => {
        console.log(response);
        this.productList = response.products;
        this.spinnerForSearch = false;
        console.log('the request list', this.productList);
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching product listings:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  //to get all the district admin  products
  searchForProductsListingsForDistrictAdmin() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      Swal.fire('Please select start date');
      return;
    }

    if (!endDate) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    } else if (new Date(endDate) < new Date(startDate)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    }

    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.ServiceRequestListingsService.searchForProductsListingsForDistrictAdmin(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.productList = data;
        this.ServiceRequestListingsService.setCachedProductList(data); // Cache the product list
        this.spinnerForSearch = false;
        console.log('the request list', this.productList);
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  // to get the details marketing admin
  searchForProductsListingsForMarketingDistrictAdmin() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      Swal.fire('Please select start date');
      return;
    }

    if (!endDate) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    } else if (new Date(endDate) < new Date(startDate)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    }

    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.ServiceRequestListingsService.searchForProductsListingsForMarketingDistrictAdmin(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.productList = data;
        this.ServiceRequestListingsService.setCachedProductList(data); // Cache the product list
        this.spinnerForSearch = false;
        console.log('the request list', this.productList);
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  goToProductDescription(ID: string) {
    this.router.navigate(['/productDescription'], {
      queryParams: {
        productID: ID,
      },
    });
  }

  convertUtcToIst(dateStr: string): string {
    let date = new Date(dateStr);
    let istDate = new Date(date.getTime() + (5 * 60 + 30) * 60000);
    return this.datePipe.transform(istDate, 'yyyy-MM-dd');
  }

  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

  selectedDistrict: string = '';
  selectedVerification: string = '';
  selectedApprovedStatus: string = '';
  selectedRejectedStatus: string = '';
  districtOFDistrictAdmin: any[] = [];
  districtName: string = '';
  districts: any[] = [];
  //  selectOrderStatus:string="";
  fetchDistricts(): void {
    this.http
      .get<any>('assets/cloudKitchenPlaces/districtBlocks/districts.json')
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
        this.districtOFDistrictAdmin = [];
        for (let i = 0; i < this.districts.length; i++) {
          if (this.districts[i].name === this.districtName) {
            this.districtOFDistrictAdmin[0] = this.districts[i];
            console.log(this.districtOFDistrictAdmin);
            break; // Stop the loop once you find the matching district
          }
        }
      });
  }

  selectedRoleType: string = '';

  adminCategory = [
    { value: 'type_1', label: 'District Farmlivelihood' },
    { value: 'type_2', label: 'District NULM' },
    { value: 'type_3', label: 'District ME' },
    { value: 'type_4', label: 'District SVEP' },
    { value: 'type_5', label: 'District SISD' },
    { value: 'type_7', label: 'District AH' },
  ];

  // ... existing methods ...

  getRoleTypeLabel(value: string): string {
    const category = this.adminCategory.find((cat) => cat.value === value);
    return category ? category.label : value;
  }

  searchByDate() {
    this.selectOrderStatus = '';
    this.selectedDistrict = '';
    this.selectedRoleType = '';
    this.selectedVerification = '';
    this.selectedApprovedStatus = '';
    this.selectedRejectedStatus = '';

    this.ServiceRequestListingsService.setSelectedOrderStatus('')
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.searchForProductsListings();
    } else if (this.isDistrictAdmin()) {
      this.searchForProductsListingsForDistrictAdmin();
    } else if (this.isMarketingAdmin()) {
      this.searchForProductsListingsForMarketingDistrictAdmin();
    } else {
      console.log('Please check login');
      // Optionally, you can redirect to a login page or show an error message
    }
  }

  // searchByFilters() {
  //   if (this.isSuperAdmin() || this.isStateAdmin()) {
  //     this.searchForProductsListings();
  //   } else {
  //     console.log("Filter search is only available for Super Admin and State Admin");
  //     // Optionally, show an error message to the user
  //   }
  // }

  searchByFilters(page: number = 1) {
    console.log('the search by filter is working');
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      // Save filter values before searching
      this.ServiceRequestListingsService.setSelectedDistrict(
        this.selectedDistrict
      );
      this.ServiceRequestListingsService.setSelectedRoleType(
        this.selectedRoleType
      );

      this.ServiceRequestListingsService.setSelectedRejectedStatus(
        this.selectedRejectedStatus
      );
      this.ServiceRequestListingsService.setSelectedApprovedStatus(
        this.selectedApprovedStatus
      );
      this.ServiceRequestListingsService.setSelectedVerification(
        this.selectedVerification
      );
       this.ServiceRequestListingsService.setSelectedOrderStatus(
        this.selectOrderStatus)
        
      
      this.searchForProductsListings(page);
    } else {
      console.log(
        'Filter search is only available for Super Admin and State Admin'
      );
    }
  }

  // Add a method to handle page changes
  onPageChange(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.ServiceRequestListingsService.setCurrentPage(page);
      this.searchForProductsListings(page);
    }
  }

  // Add a method to get visible page numbers for pagination UI
  getVisiblePages(): number[] {
    const visiblePages = [];
    let start = Math.max(1, this.currentPage - 2);
    let end = Math.min(this.totalPages, start + 4);

    if (end - start < 4) {
      start = Math.max(1, end - 4);
    }

    for (let i = start; i <= end; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  }

  limit: number = 25; // or any other default value you prefer
}
