<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="analytics"><u>Analytics</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Kudumbashree Products > Analytics</p>
    </div>
  </div>

  <!--calendar-->

  <div class="container card headerCard p-4">
    <!-- 
    <div class="row" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col">
        <select
          class="form-select"
          id="districtSelect"
          (change)="onDistrictSelected($event)"
          aria-label="Default select example"
        >
          <option value="" disabled selected>Select district</option>

          <option *ngFor="let district of districts" [value]="district.value">
            {{ district.label }}
          </option>
        </select>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
    </div> -->

    <div class="row" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col">
        <label class="form-label">
          <p class="labelText">Select local body</p>
        </label>
        <select
          class="form-select"
          [(ngModel)]="selectedLevel"
          (change)="onLevelSelection(selectedLevel)"
        >
          <option value="">All</option>
          <option value="blockLevel">Panchayat</option>
          <option value="municipalityLevel">Municipality</option>
          <option value="municipalCorporation">Municipal Corporation</option>
        </select>
      </div>
      <div class="col">
        <label for="district" class="form-label required">
          <p class="labelText">District</p>
        </label>
        <!-- Dropdown for District -->
        <select
          class="form-select inputBoxes"
          id="district"
          [(ngModel)]="selectedDistrict"
          name="district"
          (change)="onDistrictChange()"
        >
          <option value="" disabled selected>Select District</option>
          <option *ngFor="let district of districts" [ngValue]="district">
            {{ district.name }}
          </option>
        </select>
      </div>

      <div class="col">
        <div class="div" *ngIf="selectedMunicipality">
          <label for="municipality" class="form-label required">
            <p class="labelText">Municipality</p>
          </label>
          <select
            class="form-select inputBoxes"
            id="municipality"
            [(ngModel)]="selectedMunicipal"
            name="municipality"
            (click)="onMunicipalityChange()"
          >
            <option value="" disabled selected>Select Municipality</option>
            <option
              *ngFor="let municipality of selectedMunicipality"
              [ngValue]="municipality"
            >
              {{ municipality.name }}
            </option>
          </select>
        </div>
        <div class="div" *ngIf="selectedMunicipalCorporation">
          <label for="districtMunicipalCorporation" class="form-label required">
            <p class="labelText">District Municipal Corporation</p>
          </label>
          <select
            class="form-select inputBoxes"
            id="districtMunicipalCorporation"
            [(ngModel)]="selectedMunicipalCorp"
            name="districtMunicipalCorporation"
            (click)="onMunicipalCorporationChange()"
          >
            <option value="" disabled selected>
              Select District Municipal Corporation
            </option>
            <option
              *ngFor="let mnc of selectedMunicipalCorporation"
              [ngValue]="mnc"
            >
              {{ mnc.name }}
            </option>
          </select>
        </div>
        <div
          class="div"
          *ngIf="
            selectedDistrict && gramPanchayatsLoaded && localBodyType !== ''
          "
        >
          <label for="block" class="form-label required">
            <p class="labelText">Block</p>
          </label>
          <!-- Dropdown for Block -->
          <select
            class="form-select inputBoxes"
            id="block"
            [(ngModel)]="selectedBlk"
            name="block"
            (change)="onBlockChange()"
          >
            <option value="" disabled selected>Select Block</option>
            <option *ngFor="let block of blocks" [ngValue]="block">
              {{ block.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="selectedBlock">
        <label for="gramPanchayat" class="form-label required">
          <p class="labelText">Grama Panchayat</p>
        </label>
        <!-- Dropdown for Gram Panchayat -->
        <select
          class="form-select inputBoxes"
          id="gramPanchayat"
          [(ngModel)]="selectedGP"
          name="gramPanchayat"
          (change)="onPChange()"
        >
          <option value="" disabled selected>Select Grama Panchayat</option>
          <option *ngFor="let gp of gramPanchayats" [ngValue]="gp">
            {{ gp.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <mat-form-field appearance="fill" style="margin-right: 10px">
          <mat-label>Enter your start date</mat-label>
          <input
            matInput
            [matDatepicker]="firstPicker"
            [(ngModel)]="startDate"
            [max]="maxDate"
            (dateChange)="onDateChange($event, 'First Date')"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="firstPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #firstPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Enter your end date</mat-label>
          <input
            matInput
            [matDatepicker]="secondPicker"
            [(ngModel)]="endDate"
            [max]="maxDate"
            (dateChange)="onDateChange($event, 'Second Date')"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="secondPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #secondPicker></mat-datepicker>
        </mat-form-field>

        <button
          *ngIf="!spinnerForSearch"
          style="margin-left: 15px"
          type="button"
          (click)="search()"
          class="btn btn-success"
        >
          Search
        </button>
        <div
          *ngIf="spinnerForSearch"
          class="spinner-border text-success"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <!--cards-->
  <div class="row mt-3">
    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total ME Units Registered</p>
      <p class="cardHeading">{{ totalMECount }}</p>
      <div class="d-flex mt-2">
        <div class="row card">
            <p style="margin-top:5px; text-align: center;">Online</p>
            <p class="cardSubHeading" style="text-align: center;">{{ totalMECountOnline }}</p>
        </div>
        <div class="row card">
          <p style="margin-top:5px; text-align: center;">Offline</p>
          <p class="cardSubHeading" style="text-align: center;">{{ totalMECountOffline }}</p>
        </div>
      </div>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Not Verified Products</p>
      <p class="cardHeading">{{ notVerifiedProductsCount }}</p>
      <div class="d-flex mt-2">
        <div class="row card">
            <p style="margin-top:5px; text-align: center;">Online</p>
            <p class="cardSubHeading" style="text-align: center;">{{ notVerifiedProductsCountOnline }}</p>
        </div>
        <div class="row card">
          <p style="margin-top:5px; text-align: center;">Offline</p>
          <p class="cardSubHeading" style="text-align: center;">{{ notVerifiedProductsCountOffline }}</p>
        </div>
      </div>
      <div
        class="progress"
        role="progressbar"
        aria-label="Warning example"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-danger" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Not Approved Products</p>
      <p class="cardHeading">{{ pendingProductsCount }}</p>
      <div class="d-flex mt-2">
        <div class="row card">
            <p style="margin-top:5px; text-align: center;">Online</p>
            <p class="cardSubHeading" style="text-align: center;">{{ pendingProductsCountOnline }}</p>
        </div>
        <div class="row card">
          <p style="margin-top:5px; text-align: center;">Offline</p>
          <p class="cardSubHeading" style="text-align: center;">{{ pendingProductsCountOffline }}</p>
        </div>
      </div>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-danger" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total Products</p>
      <p class="cardHeading">{{ totalProducts }}</p>
      <div class="d-flex mt-2">
        <div class="row card">
            <p style="margin-top:5px; text-align: center;">Online</p>
            <p class="cardSubHeading" style="text-align: center;">{{ totalProductsOnline }}</p>
        </div>
        <div class="row card">
          <p style="margin-top:5px; text-align: center;">Offline</p>
          <p class="cardSubHeading" style="text-align: center;">{{ totalProductsOffline }}</p>
        </div>
      </div>
      <div
        class="progress"
        role="progressbar"
        aria-label="Info example"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-info" style="width: 100%"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col p-3 m-2"></div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Verified Products</p>
      <p class="cardHeading">{{ verifiedProductsCount }}</p>
      <div class="d-flex mt-2">
        <div class="row card" >
            <p style="margin-top:5px; text-align: center;">Online</p>
            <p class="cardSubHeading" style="text-align: center;">{{ verifiedProductsCountOnline }}</p>
        </div>
        <div class="row card" >
          <p style="margin-top:5px; text-align: center;">Offline</p>
          <p class="cardSubHeading" style="text-align: center;">{{ verifiedProductsCountOffline}}</p>
        </div>
      </div>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Approved Products</p>
      <p class="cardHeading">{{ approvedProductsCount }}</p>
      <div class="d-flex mt-2">
        <div class="row card">
            <p style="margin-top:5px; text-align: center;">Online</p>
            <p class="cardSubHeading" style="text-align: center;">{{ approvedProductsCountOnline }}</p>
        </div>
        <div class="row card">
          <p style="margin-top:5px; text-align: center;">Offline</p>
          <p class="cardSubHeading" style="text-align: center;">{{ approvedProductsCountOffline }}</p>
        </div>
      </div>
      <div
        class="progress"
        role="progressbar"
        aria-label="Warning example"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total online orders</p>
      <p class="cardHeading">{{ totalOrders }}</p>
      <div class="d-flex mt-2">
        <div class="row">
            <p style="margin-bottom: 5"></p>
            <p class="cardSubHeading"></p>
        </div>
        <div class="row">
          <p style="margin-bottom: 5 ; color: white">Offline</p>
          <p class="cardSubHeading" style="color: white"> Offline</p>
        </div>
      </div>
      <div
        class="progress"
        role="progressbar"
        aria-label="Info example"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-info" style="width: 100%"></div>
      </div>
    </div>
  </div>

  <hr />

  <p *ngIf="isStateAdmin() || isSuperAdmin()" class="analytics"><u>Detailed Analytics</u></p>
  <br />

  <!---->
  <div class="row"  *ngIf="isStateAdmin() || isSuperAdmin()">
    <div class="col">
      <button
        style="width: 100%" 
        type="button"
        (click)="toggleFilterTable1()"
        class="btn btn-primary"
      >
      Seller Unit Analytics
      </button>
    </div>
    <div class="col">
      <button
        style="width: 100%"
        type="button"
        (click)="toggleFilterTable2()"
        class="btn btn-primary"
      >
       Products Analytics
      </button>
    </div>
    <div class="col">
      <button
        style="width: 100%"
        type="button"
        (click)="toggleTable3()"
        class="btn btn-primary"
      >
        Products Analytics (Category Wise)
      </button>
    </div>
    <div class="col">
      <button
      style="width: 100%"
      type="button"
      (click)="toggleTable4()"
      class="btn btn-primary"
    >
     Cafes ,Hotels & Shopee 
    </button>
    </div>
  </div>

  <br />

  <!--table 1 for analytics of indivial district-->
  <div class="row mb-4" >
    <div class="col">
      <!-- <h4><u>Seller Unit Analytics</u></h4> -->

      <div class="row m-2 p-2" *ngIf="showFilterTable1" style="background-color: #0c4a6e; border-radius: 5px;">
        <div class="row">
          <select
          class="form-select m-2 mb-3"
          [(ngModel)]="selectedDistrictValue"
          >
          <option value="">All Districts</option>
          <option
            *ngFor="let district of districts"
            [value]="district.name"
            >
            {{ district.name }}
          </option>
        </select>
        </div>
        <div class="col m-2" style="flex:6">
          <div class="row">
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="me" name="me" value="me" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="me">ME</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="svep" name="svep" value="svep" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="svep">SVEP</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="farm" name="farm" value="farm" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="farm">Farmlivelihood</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="animalHusbandary" name="animalHusbandary" value="animalHusbandary" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="animalHusbandary">Animal Husbandary</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="sisd" name="sisd" value="sisd" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="sisd">SISD</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="urban" name="urban" value="urban" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style=" margin-left: 5px; font-size: 14px; color: #ffffff;" for="urban">Urban</label>
            </div>
            <!-- <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="all" name="all" value="all" (change)="toggleCheckbox($event)">
              <label class="form-check-label" style=" margin-left: 5px; font-size: 14px; color: #ffffff;" for="all">All</label>
            </div> -->
          </div>
        </div>
        <div class="col m-2" style="flex:1">
          <button class="btn btn-primary" (click)="toggleTable1()" *ngIf="!tableLoading">
            Search
          </button>
          <div *ngIf="tableLoading">
            <div class="spinner-border" role="status" style="color: white;"></div>
          </div>
        </div>
      </div>

      <div *ngIf="tableLoading">
        <p style=" font-size: 20px; text-align: center; margin-top: 50px;">Loading...</p>
      </div>
      <div *ngIf="showTable1 && !tableLoading">
        <div class="row mt-4 mb-2">
          <div class="col"><h4><u>Seller Unit Analytics</u></h4></div>
          <div class="col"><button style="float: right;" class="btn btn-secondary mb-2" (click)="exportTableToExcel()">Download List</button></div>
        </div>
        <div class="table-responsive pe-3 me-3">
          <table class="table table-bordered table-striped" border="1" id="tableToExport">
            <thead>
              <tr>
                <th scope="col"  rowspan="3">Sl No</th>
                <th scope="col"  rowspan="3">
                  District Name
                </th>
                <th scope="col" colspan="6" *ngIf="checkBoxMe" style="text-align: center;">ME</th>
                <th scope="col" colspan="6" *ngIf="checkBoxSvep" style="text-align: center;">SVEP</th>
                <th scope="col" colspan="6" *ngIf="checkBoxFarm" style="text-align: center;">Farmlivelihood</th>
                <th scope="col" colspan="6" *ngIf="checkBoxAnimalHusbandary" style="text-align: center;">Animal Husbandry</th>
                <th scope="col" colspan="6" *ngIf="checkBoxSISD" style="text-align: center;">SISD</th>
                <th scope="col" colspan="6" *ngIf="checkBoxUrban" style="text-align: center;">Urban</th>
                <th scope="col" colspan="6" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban" style="text-align: center;">Total</th>
              </tr>
              <tr>
                <th scope="col" colspan="3" *ngIf="checkBoxMe">Unit Registered</th>
                <th scope="col" colspan="3" *ngIf="checkBoxMe">No of Product</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSvep">Unit Registered</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSvep">No of Product</th>
                <th scope="col" colspan="3" *ngIf="checkBoxFarm">Unit Registered</th>
                <th scope="col" colspan="3" *ngIf="checkBoxFarm">No of Product</th>
                <th scope="col" colspan="3" *ngIf="checkBoxAnimalHusbandary">Unit Registered</th>
                <th scope="col" colspan="3" *ngIf="checkBoxAnimalHusbandary">No of Product</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSISD">Unit Registered</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSISD">No of Product</th>
                <th scope="col" colspan="3" *ngIf="checkBoxUrban">Unit Registered</th>
                <th scope="col" colspan="3" *ngIf="checkBoxUrban">No of Product</th>
                <th scope="col" colspan="3" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Unit Registered</th>
                <th scope="col" colspan="3" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">No of Product</th>
              </tr>
  
              <tr>
                <th scope="col" *ngIf="checkBoxMe">Online</th>
                <th scope="col" *ngIf="checkBoxMe">Offline</th>
                <th scope="col" *ngIf="checkBoxMe">Total</th>
                <th scope="col" *ngIf="checkBoxMe">Online</th>
                <th scope="col" *ngIf="checkBoxMe">Offline</th>
                <th scope="col" *ngIf="checkBoxMe">Total</th>
                <th scope="col" *ngIf="checkBoxSvep" >Online</th>
                <th scope="col" *ngIf="checkBoxSvep">Offline</th>
                <th scope="col" *ngIf="checkBoxSvep">Total</th>
                <th scope="col" *ngIf="checkBoxSvep">Online</th>
                <th scope="col" *ngIf="checkBoxSvep">Offline</th>
                <th scope="col" *ngIf="checkBoxSvep">Total</th>
                <th scope="col" *ngIf="checkBoxFarm">Online</th>
                <th scope="col" *ngIf="checkBoxFarm">Offline</th>
                <th scope="col" *ngIf="checkBoxFarm">Total</th>
                <th scope="col" *ngIf="checkBoxFarm">Online</th>
                <th scope="col" *ngIf="checkBoxFarm">Offline</th>
                <th scope="col" *ngIf="checkBoxFarm">Total</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Online</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Offline</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Total</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Online</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Offline</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Total</th>
                <th scope="col" *ngIf="checkBoxSISD">Online</th>
                <th scope="col" *ngIf="checkBoxSISD">Offline</th>
                <th scope="col" *ngIf="checkBoxSISD">Total</th>
                <th scope="col" *ngIf="checkBoxSISD">Online</th>
                <th scope="col" *ngIf="checkBoxSISD">Offline</th>
                <th scope="col" *ngIf="checkBoxSISD">Total</th>
                <th scope="col" *ngIf="checkBoxUrban">Online</th>
                <th scope="col" *ngIf="checkBoxUrban">Offline</th>
                <th scope="col" *ngIf="checkBoxUrban">Total</th>
                <th scope="col" *ngIf="checkBoxUrban">Online</th>
                <th scope="col" *ngIf="checkBoxUrban">Offline</th>
                <th scope="col" *ngIf="checkBoxUrban">Total</th>
                <th scope="col" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Online</th>
                <th scope="col" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Offline</th>
                <th scope="col" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Total</th>
                <th scope="col" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Online</th>
                <th scope="col" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Offline</th>
                <th scope="col" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Total</th>
              </tr>
             
            </thead>
            <tbody>
              <tr *ngFor="let districtData of districtAnalytics; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ districtData.district }}</td>
                <td *ngIf="checkBoxMe">
                  {{ getCategoryCount(districtData.sellerCategoriesOnline, "type_3") }}
                </td>
                <td *ngIf="checkBoxMe">
                  {{ getCategoryCount(districtData.sellerCategoriesOffline, "type_3") }}
                </td>
                <td *ngIf="checkBoxMe">
                  {{ getCategoryCount(districtData.sellerCategories, "type_3") }}
                </td>
                <td *ngIf="checkBoxMe">
                  {{ getCategoryCount(districtData.productCategoriesOnline, "type_3") }}
                </td>
                <td *ngIf="checkBoxMe">
                  {{ getCategoryCount(districtData.productCategoriesOffline, "type_3") }}
                </td>
                <td *ngIf="checkBoxMe">
                  {{ getCategoryCount(districtData.productCategories, "type_3") }}
                </td>
  
                <td *ngIf="checkBoxSvep">
                  {{ getCategoryCount(districtData.sellerCategoriesOnline, "type_4") }}
                </td>
                <td *ngIf="checkBoxSvep">
                  {{ getCategoryCount(districtData.sellerCategoriesOffline, "type_4") }}
                </td>
                <td *ngIf="checkBoxSvep">
                  {{ getCategoryCount(districtData.sellerCategories, "type_4") }}
                </td>
                <td *ngIf="checkBoxSvep">
                  {{ getCategoryCount(districtData.productCategoriesOnline, "type_4") }}
                </td>
                <td *ngIf="checkBoxSvep">
                  {{ getCategoryCount(districtData.productCategoriesOffline, "type_4") }}
                </td>
                <td *ngIf="checkBoxSvep">
                  {{ getCategoryCount(districtData.productCategories, "type_4") }}
                </td>
  
  
                <td *ngIf="checkBoxFarm">
                  {{ getCategoryCount(districtData.sellerCategoriesOnline, "type_1") }}
                </td>
                <td *ngIf="checkBoxFarm">
                  {{ getCategoryCount(districtData.sellerCategoriesOffline, "type_1") }}
                </td>
                <td *ngIf="checkBoxFarm">
                  {{ getCategoryCount(districtData.sellerCategories, "type_1") }}
                </td>
                <td *ngIf="checkBoxFarm">
                  {{ getCategoryCount(districtData.productCategoriesOnline, "type_1") }}
                </td>
                <td *ngIf="checkBoxFarm">
                  {{ getCategoryCount(districtData.productCategoriesOffline, "type_1") }}
                </td>
                <td *ngIf="checkBoxFarm">
                  {{ getCategoryCount(districtData.productCategories, "type_1") }}
                </td>
  
  
                <td *ngIf="checkBoxAnimalHusbandary">
                  {{ getCategoryCount(districtData.sellerCategoriesOnline, "type_7") }}
                </td>
                <td *ngIf="checkBoxAnimalHusbandary">
                  {{ getCategoryCount(districtData.sellerCategoriesOffline, "type_7") }}
                </td>
                <td *ngIf="checkBoxAnimalHusbandary">
                  {{ getCategoryCount(districtData.sellerCategories, "type_7") }}
                </td>
                <td *ngIf="checkBoxAnimalHusbandary">
                  {{ getCategoryCount(districtData.productCategoriesOnline, "type_7") }}
                </td>
                <td *ngIf="checkBoxAnimalHusbandary">
                  {{ getCategoryCount(districtData.productCategoriesOffline, "type_7") }}
                </td>
                <td *ngIf="checkBoxAnimalHusbandary">
                  {{ getCategoryCount(districtData.productCategories, "type_7") }}
                </td>
  
  
                <td *ngIf="checkBoxSISD">
                  {{ getCategoryCount(districtData.sellerCategoriesOnline, "type_5") }}
                </td>
                <td *ngIf="checkBoxSISD">
                  {{ getCategoryCount(districtData.sellerCategoriesOffline, "type_5") }}
                </td>
                <td *ngIf="checkBoxSISD">
                  {{ getCategoryCount(districtData.productCategories, "type_5") }}
                </td>
                <td *ngIf="checkBoxSISD">
                  {{ getCategoryCount(districtData.productCategoriesOnline, "type_5") }}
                </td>
                <td *ngIf="checkBoxSISD">
                  {{ getCategoryCount(districtData.productCategoriesOffline, "type_5") }}
                </td>
                <td *ngIf="checkBoxSISD">
                  {{ getCategoryCount(districtData.productCategories, "type_5") }}
                </td>
  
  
                <td *ngIf="checkBoxUrban">
                  {{ getCategoryCount(districtData.sellerCategoriesOnline, "type_2") }}
                </td>
                <td *ngIf="checkBoxUrban">
                  {{ getCategoryCount(districtData.sellerCategoriesOffline, "type_2") }}
                </td>
                <td *ngIf="checkBoxUrban">
                  {{ getCategoryCount(districtData.sellerCategories, "type_2") }}
                </td>
                <td *ngIf="checkBoxUrban">
                  {{ getCategoryCount(districtData.productCategoriesOnline, "type_2") }}
                </td>
                <td *ngIf="checkBoxUrban">
                  {{ getCategoryCount(districtData.productCategoriesOffline, "type_2") }}
                </td>
                <td *ngIf="checkBoxUrban">
                  {{ getCategoryCount(districtData.productCategories, "type_2") }}
                </td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.onlineSellerCount }}</td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.offlineSellerCount }}</td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.sellerCount }}</td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.onlineProductCount }}</td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.offlineProductCount }}</td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.productCount }}</td>
              </tr>
  
              <tr class="table-secondary fw-bold">
                <td colspan="2" style="text-align: center;" *ngIf="checkBoxMe || checkBoxSvep || checkBoxFarm || checkBoxAnimalHusbandary || checkBoxSISD || checkBoxUrban">Total</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxMe">{{ getTotalForColumn('sellerCategories', 'type_3') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxMe">{{ getTotalForColumn('productCategories', 'type_3') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxSvep">{{ getTotalForColumn('sellerCategories', 'type_4') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxSvep">{{ getTotalForColumn('productCategories', 'type_4') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxFarm">{{ getTotalForColumn('sellerCategories', 'type_1') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxFarm">{{ getTotalForColumn('productCategories', 'type_1') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxAnimalHusbandary">{{ getTotalForColumn('sellerCategories', 'type_7') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxAnimalHusbandary">{{ getTotalForColumn('productCategories', 'type_7') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxSISD">{{ getTotalForColumn('sellerCategories', 'type_5') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxSISD">{{ getTotalForColumn('productCategories', 'type_5') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxUrban">{{ getTotalForColumn('sellerCategories', 'type_2') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxUrban">{{ getTotalForColumn('productCategories', 'type_2') }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ getTotalSellerCount() }}</td>
                <td colspan="3" style="text-align: center;" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ getTotalProductCount() }}</td>
              </tr>
  
              
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>

  <!--table 2 for analytics of indivial district-->
  <div class="row mb-4" >
    <div class="col">
      <!-- <h4><u>Products Analytics</u></h4> -->
      <div class="row m-2 p-2" *ngIf="showFilterTable2" style="background-color: #0c4a6e; border-radius: 5px;">
        <div class="row " style="width: 400%;">
          <select
          class="form-select m-2 mb-3"
          [(ngModel)]="selectedDistrictValue"
          >
          <option value="">All Districts</option>
          
          <option
            *ngFor="let district of districts"
            [value]="district.name"
            >
            {{ district.name }}
          </option>
        </select>
        </div>
        <div class="col m-1" style="flex:6">
          <div class="row ">
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="me" name="me" value="me" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="me">ME</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="svep" name="svep" value="svep" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="svep">SVEP</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="farm" name="farm" value="farm" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="farm">Farmlivelihood</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="animalHusbandary" name="animalHusbandary" value="animalHusbandary" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="animalHusbandary">Animal Husbandary</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="sisd" name="sisd" value="sisd" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style="margin-left: 5px; font-size: 14px; color: #ffffff;" for="sisd">SISD</label>
            </div>
            <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="urban" name="urban" value="urban" (change)="checkBoxQuery($event)">
              <label class="form-check-label" style=" margin-left: 5px; font-size: 14px; color: #ffffff;" for="urban">Urban</label>
            </div>
            <!-- <div class="form-check col">
              <input class="form-check-input" type="checkbox" id="all" name="all" value="all" (change)="toggleCheckbox($event)">
              <label class="form-check-label" style=" margin-left: 5px; font-size: 14px; color: #ffffff;" for="all">All</label>
            </div> -->
          </div>
        </div>
        <div class="col m-2" style="flex:1">
          <button class="btn btn-primary" (click)="toggleTable2()">
            Search
          </button>
          <div *ngIf="tableLoading2" class="spinner-border" role="status" style="color: white;"></div>
        </div>
      </div>

      <div *ngIf="tableLoading2">
        <p style=" font-size: 20px; text-align: center; margin-top: 50px;">Loading...</p>
      </div>
      <div *ngIf="showTable2 && !tableLoading2">
        <div class="row mt-4 m-2" style="justify-content: space-between;">
          <div class="col"><h4><u>Products Analytics</u></h4></div>
          <div class="col"><button style="float: right;" class="btn btn-secondary mb-2" (click)="exportTableToExcel2()">Download List</button></div>
        </div>
        <div class="table-responsive pe-3 me-3" >
          <table class="table table-bordered table-striped" id="tableToExport2" style="border-width: 1px;">
            <thead>
              <tr>
                <th scope="col" rowspan="3">Sl No</th>
                <th scope="col" rowspan="3">District Name</th>
                <th scope="col" colspan="9" *ngIf="checkBoxMe">ME</th>
                <th scope="col" colspan="9" *ngIf="checkBoxSvep">SVEP</th>
                <th scope="col" colspan="9" *ngIf="checkBoxFarm">Farmlivelihood</th>
                <th scope="col" colspan="9" *ngIf="checkBoxAnimalHusbandary">Animal Husbandry</th>
                <th scope="col" colspan="9" *ngIf="checkBoxSISD">SISD</th>
                <th scope="col" colspan="9" *ngIf="checkBoxUrban">Urban</th>
                <th scope="col" colspan="3" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Total</th>
              </tr>
              <tr>
                <th scope="col" colspan="3" *ngIf="checkBoxMe">Product Approved</th>
                <th scope="col" colspan="3" *ngIf="checkBoxMe">Products Not verified by District</th>
                <th scope="col" colspan="3" *ngIf="checkBoxMe">Products Not Approved By State</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSvep">Product Approved</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSvep">Products Not verified by District</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSvep">Products Not Approved By State</th>
                <th scope="col" colspan="3" *ngIf="checkBoxFarm">Product Approved</th>
                <th scope="col" colspan="3" *ngIf="checkBoxFarm">Products Not verified by District</th>
                <th scope="col" colspan="3" *ngIf="checkBoxFarm">Products Not Approved By State</th>
                <th scope="col" colspan="3" *ngIf="checkBoxAnimalHusbandary">Product Approved</th>
                <th scope="col" colspan="3" *ngIf="checkBoxAnimalHusbandary">Products Not verified by District</th>
                <th scope="col" colspan="3" *ngIf="checkBoxAnimalHusbandary">Products Not Approved By State</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSISD">Product Approved</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSISD">Products Not verified by District</th>
                <th scope="col" colspan="3" *ngIf="checkBoxSISD">Products Not Approved By State</th>
                <th scope="col" colspan="3" *ngIf="checkBoxUrban">Product Approved</th>
                <th scope="col" colspan="3" *ngIf="checkBoxUrban">Products Not verified by District</th>
                <th scope="col" colspan="3" *ngIf="checkBoxUrban">Products Not Approved By State</th>
                <th scope="col" colspan="1" rowspan="2" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Product Approved</th>
                <th scope="col" colspan="1" rowspan="2" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Products Not verified by District</th>
                <th scope="col" colspan="1" rowspan="2" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">Products Not Approved By State</th>
              </tr>
              <tr>
                <th scope="col" *ngIf="checkBoxMe">Online</th>
                <th scope="col" *ngIf="checkBoxMe">Offline</th>
                <th scope="col" *ngIf="checkBoxMe">Total</th>
                <th scope="col" *ngIf="checkBoxMe">Online</th>
                <th scope="col" *ngIf="checkBoxMe">Offline</th>
                <th scope="col" *ngIf="checkBoxMe">Total</th>
                <th scope="col" *ngIf="checkBoxMe">Online</th>
                <th scope="col" *ngIf="checkBoxMe">Offline</th>
                <th scope="col" *ngIf="checkBoxMe">Total</th>
                <th scope="col" *ngIf="checkBoxSvep">Online</th>
                <th scope="col" *ngIf="checkBoxSvep">Offline</th>
                <th scope="col" *ngIf="checkBoxSvep">Total</th>
                <th scope="col" *ngIf="checkBoxSvep">Online</th>
                <th scope="col" *ngIf="checkBoxSvep">Offline</th>
                <th scope="col" *ngIf="checkBoxSvep">Total</th>
                <th scope="col" *ngIf="checkBoxSvep">Online</th>
                <th scope="col" *ngIf="checkBoxSvep">Offline</th>
                <th scope="col" *ngIf="checkBoxSvep">Total</th>
                <th scope="col" *ngIf="checkBoxFarm">Online</th>
                <th scope="col" *ngIf="checkBoxFarm">Offline</th>
                <th scope="col" *ngIf="checkBoxFarm">Total</th>
                <th scope="col" *ngIf="checkBoxFarm">Online</th>
                <th scope="col" *ngIf="checkBoxFarm">Offline</th>
                <th scope="col" *ngIf="checkBoxFarm">Total</th>
                <th scope="col" *ngIf="checkBoxFarm">Online</th>
                <th scope="col" *ngIf="checkBoxFarm">Offline</th>
                <th scope="col" *ngIf="checkBoxFarm">Total</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Online</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Offline</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Total</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Online</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Offline</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Total</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Online</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Offline</th>
                <th scope="col" *ngIf="checkBoxAnimalHusbandary">Total</th>
                <th scope="col" *ngIf="checkBoxSISD">Online</th>
                <th scope="col" *ngIf="checkBoxSISD">Offline</th>
                <th scope="col" *ngIf="checkBoxSISD">Total</th>
                <th scope="col" *ngIf="checkBoxSISD">Online</th>
                <th scope="col" *ngIf="checkBoxSISD">Offline</th>
                <th scope="col" *ngIf="checkBoxSISD">Total</th>
                <th scope="col" *ngIf="checkBoxSISD">Online</th>
                <th scope="col" *ngIf="checkBoxSISD">Offline</th>
                <th scope="col" *ngIf="checkBoxSISD">Total</th>
                <th scope="col" *ngIf="checkBoxUrban">Online</th>
                <th scope="col" *ngIf="checkBoxUrban">Offline</th>
                <th scope="col" *ngIf="checkBoxUrban">Total</th>
                <th scope="col" *ngIf="checkBoxUrban">Online</th>
                <th scope="col" *ngIf="checkBoxUrban">Offline</th>
                <th scope="col" *ngIf="checkBoxUrban">Total</th>
                <th scope="col" *ngIf="checkBoxUrban">Online</th>
                <th scope="col" *ngIf="checkBoxUrban">Offline</th>
                <th scope="col" *ngIf="checkBoxUrban">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let districtData of districtAnalytics2; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ districtData.district }}</td>
                <!-- ME -->
                <td *ngIf="checkBoxMe">{{ districtData.me.productApprovedOnline }}</td>
                <td *ngIf="checkBoxMe">{{ districtData.me.productApprovedOffline }}</td>
                <td *ngIf="checkBoxMe">{{ districtData.me.productApproved }}</td>
  
                <td *ngIf="checkBoxMe">{{ districtData.me.totalNotVerifiedOnline }}</td>
                <td *ngIf="checkBoxMe">{{ districtData.me.totalNotVerifiedOffline }}</td>
                <td *ngIf="checkBoxMe">{{ districtData.me.totalNotVerified }}</td>
  
                <td *ngIf="checkBoxMe">{{ districtData.me.productNotApprovedOnline }}</td>
                <td *ngIf="checkBoxMe">{{ districtData.me.productNotApprovedOffline }}</td>
                <td *ngIf="checkBoxMe">{{ districtData.me.productNotApproved }}</td>
  
                <!-- SVEP -->
                <td *ngIf="checkBoxSvep">{{ districtData.svep.productApprovedOnline }}</td>
                <td *ngIf="checkBoxSvep">{{ districtData.svep.productApprovedOffline }}</td>
                <td *ngIf="checkBoxSvep">{{ districtData.svep.productApproved }}</td>
  
                <td *ngIf="checkBoxSvep">{{ districtData.svep.totalNotVerifiedOnline }}</td>
                <td *ngIf="checkBoxSvep">{{ districtData.svep.totalNotVerifiedOffline }}</td>
                <td *ngIf="checkBoxSvep">{{ districtData.svep.totalNotVerified }}</td>
  
                <td *ngIf="checkBoxSvep">{{ districtData.svep.productNotApprovedOnline }}</td>
                <td *ngIf="checkBoxSvep">{{ districtData.svep.productNotApprovedOffline }}</td>
                <td *ngIf="checkBoxSvep">{{ districtData.svep.productNotApproved }}</td>
  
                <!-- Farmlivelihood -->
                <td *ngIf="checkBoxFarm">{{ districtData.farm.productApprovedOnline }}</td>
                <td *ngIf="checkBoxFarm">{{ districtData.farm.productApprovedOffline }}</td>
                <td *ngIf="checkBoxFarm">{{ districtData.farm.productApproved }}</td>
  
                <td *ngIf="checkBoxFarm">{{ districtData.farm.totalNotVerifiedOnline }}</td>
                <td *ngIf="checkBoxFarm">{{ districtData.farm.totalNotVerifiedOffline }}</td>
                <td *ngIf="checkBoxFarm">{{ districtData.farm.totalNotVerified }}</td>
  
                <td *ngIf="checkBoxFarm">{{ districtData.farm.productApproved }}</td>
                <td *ngIf="checkBoxFarm">{{ districtData.farm.totalNotVerified }}</td>
                <td *ngIf="checkBoxFarm">{{ districtData.farm.productNotApproved }}</td>
  
                <!-- Animal Husbandary -->
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.productApprovedOnline }}</td>
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.productApprovedOffline }}</td>
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.productApproved }}</td>
  
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.totalNotVerifiedOnline }}</td>
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.totalNotVerifiedOffline }}</td>
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.totalNotVerified }}</td>
  
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.productNotApprovedOnline }}</td>
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.productNotApprovedOffline }}</td>
                <td *ngIf="checkBoxAnimalHusbandary">{{ districtData.animalHusbandary.productNotApproved }}</td>
  
                <!-- SISD -->
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.productApprovedOnline }}</td>
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.productApprovedOffline }}</td>
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.productApproved }}</td>
  
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.totalNotVerifiedOnline }}</td>
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.totalNotVerifiedOffline }}</td>
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.totalNotVerified }}</td>
  
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.productNotApprovedOnline }}</td>
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.productNotApprovedOffline }}</td>
                <td *ngIf="checkBoxSISD">{{ districtData.sisd.productNotApproved }}</td>
  
                <!-- Urban -->
                <td *ngIf="checkBoxUrban">{{ districtData.urban.productApprovedOnline }}</td>
                <td *ngIf="checkBoxUrban">{{ districtData.urban.productApprovedOffline }}</td>
                <td *ngIf="checkBoxUrban">{{ districtData.urban.productApproved }}</td>
  
                <td *ngIf="checkBoxUrban">{{ districtData.urban.totalNotVerifiedOnline }}</td>
                <td *ngIf="checkBoxUrban">{{ districtData.urban.totalNotVerifiedOffline }}</td>
                <td *ngIf="checkBoxUrban">{{ districtData.urban.totalNotVerified }}</td>
  
                <td *ngIf="checkBoxUrban">{{ districtData.urban.productNotApprovedOnline }}</td>
                <td *ngIf="checkBoxUrban">{{ districtData.urban.productNotApprovedOffline }}</td>
                <td *ngIf="checkBoxUrban">{{ districtData.urban.productNotApproved }}</td>
  
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.total.productApproved }}</td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.total.totalNotVerified }}</td>
                <td *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban">{{ districtData.total.productNotApproved }}</td>
  
              <tr class="table-secondary fw-bold">
                <td colspan="2">Total</td>
                <td colspan="3" *ngIf="checkBoxMe" style="text-align: center;">{{ getTotalForTable2('me', 'productApproved') }}</td> 
                <td colspan="3" *ngIf="checkBoxMe" style="text-align: center;">{{ getTotalForTable2('me', 'totalNotVerified') }}</td>
                <td colspan="3" *ngIf="checkBoxMe" style="text-align: center;">{{ getTotalForTable2('me', 'productNotApproved') }}</td>
                
                <td colspan="3" *ngIf="checkBoxSvep" style="text-align: center;">{{ getTotalForTable2('svep', 'productApproved') }}</td>
                <td colspan="3" *ngIf="checkBoxSvep" style="text-align: center;">{{ getTotalForTable2('svep', 'totalNotVerified') }}</td>
                <td colspan="3" *ngIf="checkBoxSvep" style="text-align: center;">{{ getTotalForTable2('svep', 'productNotApproved') }}</td>
                
                <td colspan="3" *ngIf="checkBoxFarm" style="text-align: center;">{{ getTotalForTable2('farm', 'productApproved') }}</td>
                <td colspan="3" *ngIf="checkBoxFarm" style="text-align: center;">{{ getTotalForTable2('farm', 'totalNotVerified') }}</td>
                <td colspan="3" *ngIf="checkBoxFarm" style="text-align: center;">{{ getTotalForTable2('farm', 'productNotApproved') }}</td>
                
                <td colspan="3" *ngIf="checkBoxAnimalHusbandary" style="text-align: center;">{{ getTotalForTable2('animalHusbandary', 'productApproved') }}</td>
                <td colspan="3" *ngIf="checkBoxAnimalHusbandary" style="text-align: center;">{{ getTotalForTable2('animalHusbandary', 'totalNotVerified') }}</td>
                <td colspan="3" *ngIf="checkBoxAnimalHusbandary" style="text-align: center;">{{ getTotalForTable2('animalHusbandary', 'productNotApproved') }}</td>
                
                <td colspan="3" *ngIf="checkBoxSISD" style="text-align: center;">{{ getTotalForTable2('sisd', 'productApproved') }}</td>
                <td colspan="3" *ngIf="checkBoxSISD" style="text-align: center;">{{ getTotalForTable2('sisd', 'totalNotVerified') }}</td>
                <td colspan="3" *ngIf="checkBoxSISD" style="text-align: center;">{{ getTotalForTable2('sisd', 'productNotApproved') }}</td>
                
                <td colspan="3" *ngIf="checkBoxUrban" style="text-align: center;">{{ getTotalForTable2('urban', 'productApproved') }}</td>
                <td colspan="3" *ngIf="checkBoxUrban" style="text-align: center;">{{ getTotalForTable2('urban', 'totalNotVerified') }}</td>
                <td colspan="3" *ngIf="checkBoxUrban" style="text-align: center;">{{ getTotalForTable2('urban', 'productNotApproved') }}</td>
                
                <td colspan="1" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban" style="text-align: center;">{{ getTotalForTable2('total', 'productApproved') }}</td>
                <td colspan="1" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban" style="text-align: center;">{{ getTotalForTable2('total', 'totalNotVerified') }}</td>
                <td colspan="1" *ngIf="checkBoxMe && checkBoxSvep && checkBoxFarm && checkBoxAnimalHusbandary && checkBoxSISD && checkBoxUrban" style="text-align: center;">{{ getTotalForTable2('total', 'productNotApproved') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4" *ngIf="showTable3">
    <div class="col">
      <!-- <h4><u>Products Analytics (Category Wise)</u></h4> -->

      <div class="row">
        <div class="col"><h4><u>Products Analytics (Category Wise)</u></h4></div>
        <div class="col"><button style="float: right;" class="btn btn-secondary mb-2" (click)="downloadPdfForTable3()">Download Below List</button></div>
      </div>

      <div class="table-responsive pe-3 me-3">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col" rowspan="3">Sl No</th>
              <th scope="col" rowspan="3" style="min-width: 220px">
                District Name
                <select
                  class="form-select mt-2"
                  (change)="onDistrictFilterChange3($event)"
                >
                  <option value="">All Districts</option>
                  <option
                    *ngFor="let district of districts"
                    [value]="district.name"
                  >
                    {{ district.name }}
                  </option>
                </select>
              </th>
              <th scope="col">Total Count</th>
              <th scope="col">Food products</th>
              <th scope="col">Personal Care</th>
              <th scope="col">Fashion</th>
              <th scope="col">Gem and Jewellery</th>
              <th scope="col">Eco friendly products</th>
              <th scope="col">Herbal products</th>
              <th scope="col">Stationary</th>
              <th scope="col">Toys</th>
              <th scope="col">Electronics</th>
              <th scope="col">Fresh Vegetables & Fruits</th>
              <th scope="col">Fresh Fish & Meat</th>
              <th scope="col">Milk</th>
              <th scope="col">Millet</th>
              <th scope="col">Spices</th>
              <th scope="col">Home and Kitchen</th>
              <th scope="col">Others</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let district of districtAnalytics3; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ district.district }}</td>
              <td>{{ district.totalCount }}</td>
              <td>{{ district.groupedCategories["Food products"] }}</td>
              <td>{{ district.groupedCategories["Personal Care"] }}</td>
              <td>{{ district.groupedCategories["Fashion"] }}</td>
              <td>{{ district.groupedCategories["Gem and Jewellery"] }}</td>
              <td>{{ district.groupedCategories["Eco friendly products"] }}</td>
              <td>{{ district.groupedCategories["Herbal products"] }}</td>
              <td>{{ district.groupedCategories["Stationary"] }}</td>
              <td>{{ district.groupedCategories["Toys"] }}</td>
              <td>{{ district.groupedCategories["Electronics"] }}</td>
              <td>
                {{ district.groupedCategories["Fresh Vegetables & Fruits"] }}
              </td>
              <td>{{ district.groupedCategories["Fresh Fish & Meat"] }}</td>
              <td>{{ district.groupedCategories["Milk"] }}</td>
              <td>{{ district.groupedCategories["Millet"] }}</td>
              <td>{{ district.groupedCategories["Spices"] }}</td>
              <td>{{ district.groupedCategories["Home and Kitchen"] }}</td>
              <td>{{ district.groupedCategories["Others"] }}</td>
            </tr>

            <tr class="table-secondary fw-bold">
              <td colspan="2">Total</td>
              <td>{{ getTotalForTable3('totalCount') }}</td>
              <td>{{ getTotalForTable3Category('Food products') }}</td>
              <td>{{ getTotalForTable3Category('Personal Care') }}</td>
              <td>{{ getTotalForTable3Category('Fashion') }}</td>
              <td>{{ getTotalForTable3Category('Gem and Jewellery') }}</td>
              <td>{{ getTotalForTable3Category('Eco friendly products') }}</td>
              <td>{{ getTotalForTable3Category('Herbal products') }}</td>
              <td>{{ getTotalForTable3Category('Stationary') }}</td>
              <td>{{ getTotalForTable3Category('Toys') }}</td>
              <td>{{ getTotalForTable3Category('Electronics') }}</td>
              <td>{{ getTotalForTable3Category('Fresh Vegetables & Fruits') }}</td>
              <td>{{ getTotalForTable3Category('Fresh Fish & Meat') }}</td>
              <td>{{ getTotalForTable3Category('Milk') }}</td>
              <td>{{ getTotalForTable3Category('Millet') }}</td>
              <td>{{ getTotalForTable3Category('Spices') }}</td>
              <td>{{ getTotalForTable3Category('Home and Kitchen') }}</td>
              <td>{{ getTotalForTable3Category('Others') }}</td>
            </tr>



          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row mb-4" *ngIf="showTable4">
    <div class="col">
      <div class="row">
        <div class="col"><h4><u>Cafes, Hotels & Shopee</u></h4></div>
      </div>
      
      <div class="table-responsive pe-3 me-3">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">Sl No</th>
              <th scope="col" style="min-width: 220px">
                District Name
                <select
                  class="form-select mt-2"
                  (change)="onDistrictFilterChange4($event)"
                >
                  <option value="">All Districts</option>
                  <option
                    *ngFor="let district of districts"
                    [value]="district.name"
                  >
                    {{ district.name }}
                  </option>
                </select>
              </th>
              <th scope="col">Janakeeya Hotel</th>
              <th scope="col">Premium Cafe</th>
              <th scope="col">Kerala Chicken</th>
              <th scope="col">Nano Market</th>
              <th scope="col">Shopee</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let districtData of districtAnalytics4; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ districtData.district }}</td>
              <td>{{ districtData.typeCounts.ID_JAH  }}</td>
              <td>{{ districtData.typeCounts.ID_PMC  }}</td>
              <td>{{ districtData.typeCounts.ID_CHN }}</td>
              <td>{{ districtData.typeCounts.ID_NMA  }}</td>
              <td>{{ districtData.typeCounts.ID_SHO}}</td>
              <td>{{ districtData.totalCount }}</td>
            </tr>

            <tr class="table-secondary fw-bold">
              <td colspan="2">Total</td>
              <td>{{ getTotalForTable4('ID_JAH') }}</td>
              <td>{{ getTotalForTable4('ID_PMC') }}</td>
              <td>{{ getTotalForTable4('ID_CHN') }}</td>
              <td>{{ getTotalForTable4('ID_NMA') }}</td>
              <td>{{ getTotalForTable4('ID_SHO') }}</td>
              <td>{{ getTotalForTable4Count() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> 
  <br /><br />
</div>

