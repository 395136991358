import { Component, OnInit } from "@angular/core";
import { Route, Router } from "@angular/router";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import Swal from "sweetalert2";
import { QsIDsService } from "../../services/qs-ids.service";
import { ServiceRequestListingsService } from "../../services/service-request-listings.service";
import { DateAdapter } from "@angular/material/core";

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';


import { environment } from "src/environments/environment";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';


const KP_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: "app-analytics-page",
  templateUrl: "./analytics-page.component.html",
  styleUrls: ["./analytics-page.component.css"],
})
export class AnalyticsPageComponent implements OnInit {
  //variables
  startDate: any;
  endDate: any;
  approvedMEUnits: number = 0;
  notApprovedMEUnits: number = 0;
  totalServiceRequests: number = 0;
  // totalRevenue: number = 0;
  activeServiceProviders: number = 0;
  inActiveServiceProviders: number = 0;
  pendingServiceRequests: number = 0;
  progressServiceRequests: number = 0;
  assignedServiceRequests: number = 0;
  completedServiceRequests: number = 0;
  acceptedServiceRequests: number = 0;
  cancelledServiceRequests: number = 0;
  totalAmount: number = 0;

  spinnerForSearch: boolean = false;
  spinnerForExcelDownload: boolean = false;
  spinnerForPDFDownload: boolean = false;
  spinnerForServiceProvider: boolean = false;

  serviceProviderListing: any;

  totalMECount: any = 0;
  totalMECountOnline: any = 0;
  totalMECountOffline: any = 0;
  totalProducts: any = 0;
  totalProductsOnline: any = 0;
  totalProductsOffline: any = 0;
  pendingProductsCount: any = 0;
  pendingProductsCountOnline: any = 0;
  pendingProductsCountOffline: any = 0;
  pendingMEunitCount: any = 0;
  pendingMEunitCountOnline: any = 0;
  pendingMEunitCountOffline: any = 0;
  approvedProductsCount: any = 0;
  approvedProductsCountOnline: any = 0;
  approvedProductsCountOffline: any = 0;
  acceptedMEUnitCount: any = 0;
  acceptedMEUnitCountOnline: any = 0;
  acceptedMEUnitCountOffline: any = 0;
  totalOrders: any = 0;
  totalOrdersOnline: any = 0;
  totalOrdersOffline: any = 0;
  notVerifiedProductsCount: any = 0;
  notVerifiedProductsCountOnline: any = 0;
  notVerifiedProductsCountOffline: any = 0;
  verifiedProductsCount: any = 0;
  verifiedProductsCountOnline: any = 0;
  verifiedProductsCountOffline: any = 0;

  //detailed analytics
  showTable1: boolean = false;
  showTable2: boolean = false;
  showTable3: boolean = false;
  showTable4: boolean = false;

  tableLoading: boolean = false;
  tableLoading2: boolean = false;


  //filter
  showFilterTable1: boolean = false;
  showFilterTable2: boolean = false;
  showFilterTable3: boolean = false;
  showFilterTable4: boolean = false;

  //filter Checkbox
  checkBoxMe: boolean = false;
  checkBoxSvep: boolean = false;
  checkBoxFarm: boolean = false;
  checkBoxAnimalHusbandary: boolean = false;
  checkBoxSISD: boolean = false;
  checkBoxUrban: boolean = false;
  checkBoxAll: boolean = false;

  districtAnalytics: any[] = [];
  districtAnalytics2: any[] = [];
  districtAnalytics3: any[] = [];
  districtAnalytics4: any[] = [];


  // districts = [
  //   { value: '', label: 'All' },
  //   { value: 'Alappuzha', label: 'Alappuzha' },
  //   { value: 'Ernakulam', label: 'Ernakulam' },
  //   { value: 'Idukki', label: 'Idukki' },
  //   { value: 'Kannur', label: 'Kannur' },
  //   { value: 'Kasaragod ', label: 'Kasaragod' },
  //   { value: 'Kollam', label: 'Kollam' },
  //   { value: 'Kottayam', label: 'Kottayam' },
  //   { value: 'Kozhikode', label: 'Kozhikode' },
  //   { value: 'Malappuram', label: 'Malappuram' },
  //   { value: 'Palakkad', label: 'Palakkad' },
  //   { value: 'Pathanamthitta', label: 'Pathanamthitta' },
  //   { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
  //   { value: 'Thrissur', label: 'Thrissur' },
  //   { value: 'Wayanad', label: 'Wayanad' },
  // ];

  districts: any[] = [];
  districtLabel: string = "";
  districtValue: string = "";
  maxDate: Date;

  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private router: Router,
    private QsIDsService: QsIDsService,
    private ServiceRequestListingsService: ServiceRequestListingsService,
    private dateAdapter: DateAdapter<Date>,
  ) {
    this.maxDate = new Date();
    // Optional: set locale if needed
    this.dateAdapter.setLocale("en-GB");
  }

  ngOnInit() {
    this.fetchDistricts();

    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      // this.startDate = new Date();
      this.startDate = new Date(2024, 9, 1);
      this.endDate = new Date();
      
    }

    if (startDate) {
      this.startDate = startDate;
      // this.endDate = endDate;
       this.endDate = new Date();
    }

    if (this.startDate) {
      this.search();
    }
  }

  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
  }

  search() {
    if (this.isStateAdmin() || this.isSuperAdmin()) {
      this.searchForStateAdmin();

      this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
    } else if (this.isDistrictAdmin()) {
      this.searchForDistrictAdmin();
      this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
    } else if (this.isMarketingAdmin()) {
      this.searchForMarketingDistrictAdmin();
      this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
    } else {
      console.log("please check your login");
    }
  }

  searchForStateAdmin() {
    const localBodyType = this.localBodyType;
    const districtName = this.districtName;
    const blockName = this.blockName;
    const localBodyName = this.localBodyName;

    console.log(
      "the local body",
      localBodyType,
      districtName,
      blockName,
      localBodyName
    );

    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate)
        .set("districtValue", this.districtName)
        .set("localBodyType", this.localBodyType)
        .set("localBodyName", this.localBodyName)
        .set("blockName", this.blockName);
      this.http
        .get<any>(KP_ADMIN_URL + "/analyticsCounts", {
          headers,
          params,
        })
        .subscribe(
          (data: any) => {
            console.log(data);

            console.log("the result", data);
            this.spinnerForSearch = false;
            this.totalMECount = data.totalMECount;
            this.totalMECountOnline = data.totalMECountOnline;
            this.totalMECountOffline = data.totalMECount - data.totalMECountOnline;
            this.pendingMEunitCount = data.pendingMEunitCount;
            this.pendingMEunitCountOnline = data.pendingMEunitCountOnline;
            this.pendingMEunitCountOffline = data.pendingMEunitCount- data.pendingMEunitCountOnline;
            this.approvedProductsCount = data.approvedProductsCount;
            this.approvedProductsCountOnline = data.approvedProductsCountOnline;
            this.approvedProductsCountOffline = data.approvedProductsCount - data.approvedProductsCountOnline;
            this.totalProducts = data.totalProducts;
            this.totalProductsOnline = data.totalProductsOnline;
            this.totalProductsOffline = data.totalProducts - data.totalProductsOnline;
            this.totalOrders = data.totalOrders;
            this.totalOrdersOnline = data.totalOrdersOnline;
            this.totalOrdersOffline = data.totalOrders - data.totalOrdersOnline;
            this.notVerifiedProductsCount = data.notVerifiedProductsCount;
            this.notVerifiedProductsCountOnline = data.notVerifiedProductsCountOnline;
            this.notVerifiedProductsCountOffline = data.notVerifiedProductsCount - data.notVerifiedProductsCountOnline;
            this.pendingProductsCount = data.pendingProductsCount;
            this.pendingProductsCountOnline = data.pendingProductsCountOnline;
            this.pendingProductsCountOffline = data.pendingProductsCount - data.pendingProductsCountOnline;
            this.verifiedProductsCount = data.verifiedProductsCount;
            this.verifiedProductsCountOnline = data.verifiedProductsCountOnline;
            this.verifiedProductsCountOffline = data.verifiedProductsCount - data.verifiedProductsCountOnline;
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }

  searchForDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate);
      this.http
        .get<any>(KP_ADMIN_URL + "/analyticsCountsForDistrictAdmin", {
          headers,
          params,
        })
        .subscribe(
          (data: any) => {
            console.log("the result", data);
            this.spinnerForSearch = false;
            this.totalMECount = data.totalMECount;
            this.totalMECountOnline = data.totalMECountOnline;
            this.totalMECountOffline = data.totalMECount - data.totalMECountOnline;
            this.pendingMEunitCount = data.pendingMEunitCount;
            this.pendingMEunitCountOnline = data.pendingMEunitCountOnline;
            this.pendingMEunitCountOffline = data.pendingMEunitCount - data.pendingMEunitCountOnline;
            this.approvedProductsCount = data.approvedProductsCount;
            this.approvedProductsCountOnline = data.approvedProductsCountOnline;
            this.approvedProductsCountOffline = data.approvedProductsCount - data.approvedProductsCountOnline;
            this.totalProducts = data.totalProducts;
            this.totalProductsOnline = data.totalProductsOnline;
            this.totalProductsOffline = data.totalProducts - data.totalProductsOnline;
            this.totalOrders = data.totalOrders;
            this.totalOrdersOnline = data.totalOrdersOnline;
            this.totalOrdersOffline = data.totalOrders - data.totalOrdersOnline;
            this.notVerifiedProductsCount = data.notVerifiedProductsCount;
            this.notVerifiedProductsCountOnline = data.notVerifiedProductsCountOnline;
            this.notVerifiedProductsCountOffline = data.notVerifiedProductsCount - data.notVerifiedProductsCountOnline;
            this.pendingProductsCount = data.pendingProductsCount;
            this.verifiedProductsCount = data.VerifiedProductsCount;
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }

  searchForMarketingDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate);
      this.http
        .get<any>(KP_ADMIN_URL + "/analyticsCountsForMarketingDistrictAdmin", {
          headers,
          params,
        })
        .subscribe(
          (data: any) => {
            console.log("the result", data);
            this.spinnerForSearch = false;
            this.totalMECount = data.totalMECount;
            this.totalMECountOnline = data.totalMECountOnline;
            this.totalMECountOffline = data.totalMECount - data.totalMECountOnline;
            this.pendingMEunitCount = data.pendingMEunitCount;
            this.pendingMEunitCountOnline = data.pendingMEunitCountOnline;
            this.pendingMEunitCountOffline = data.pendingMEunitCount - data.pendingMEunitCountOnline;
            this.approvedProductsCount = data.approvedProductsCount;
            this.approvedProductsCountOnline = data.approvedProductsCountOnline;
            this.approvedProductsCountOffline = data.approvedProductsCount - data.approvedProductsCountOnline;
            this.totalProducts = data.totalProducts;
            this.totalProductsOnline = data.totalProductsOnline;
            this.totalProductsOffline = data.totalProducts - data.totalProductsOnline;
            this.totalOrders = data.totalOrders;
            this.totalOrdersOnline = data.totalOrdersOnline;
            this.totalOrdersOffline = data.totalOrders - data.totalOrdersOnline;
            this.notVerifiedProductsCount = data.notVerifiedProductsCount;
            this.notVerifiedProductsCountOnline = data.notVerifiedProductsCountOnline;
            this.notVerifiedProductsCountOffline = data.notVerifiedProductsCount - data.notVerifiedProductsCountOnline;
            this.pendingProductsCount = data.pendingProductsCount;
            this.pendingProductsCountOnline = data.pendingProductsCountOnline;
            this.pendingProductsCountOffline = data.pendingProductsCount - data.pendingProductsCountOnline;
            this.verifiedProductsCount = data.VerifiedProductsCount;
            this.verifiedProductsCountOnline = data.VerifiedProductsCountOnline;
            this.verifiedProductsCountOffline = data.VerifiedProductsCount - data.VerifiedProductsCountOnline;
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }
  

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ("0" + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ("0" + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

  localBodyName: string = "";
  districtName: string = "";
  blockName: string = "";
  localBodyType: string = ""; // Default value

  fetchDistricts(): void {
    this.http
      .get<any>("assets/cloudKitchenPlaces/districtBlocks/districts.json")
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
      });
  }

  fetchDistrictsforMunicipalCorporation(): void {
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/municipalCorporations/districtsCorp.json"
      )
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
      });
  }

  fetchBlocksForDistrict(districtId: string): void {
    console.log("districtId: ", districtId);
    this.http
      .get<any>("assets/cloudKitchenPlaces/districtBlocks/blocks.json")
      .subscribe((data) => {
        this.blocks = data[districtId];
        console.log(this.blocks);
      });
  }
  tempBlocks: any;
  fetchGramPanchayatsForDistrict(districtId: string): void {
    console.log(districtId);
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/gramaPanchayats/" + districtId + ".json"
      )
      .subscribe((data) => {
        console.log(data);

        this.tempBlocks = data[`${this.selectedBlock}`];
        console.log(this.tempBlocks);
        this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
        this.gramPanchayats = this.tempBlocks;
        console.log(this.gramPanchayats);
      });
  }

  onDistrictChange(): void {
    this.selectedBlock = ""; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = "";
    this.selectedMunicipalityId = "";
    this.selectedMunicipalCorporationId = "";
    this.selectedMunicipality = null;
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    if (this.selectedDistrict) {
      this.districtName = this.selectedDistrict.name;
      console.log("District Name: ", this.districtName);
      if (this.selectedLevel === "municipalityLevel") {
        this.selectedMunicipality =
          this.municipalities[this.selectedDistrict.id];
        console.log("municipality", this.selectedMunicipality);

        return;
      } else if (this.selectedLevel == "municipalCorporation") {
        this.selectedMunicipalCorporation =
          this.municipalCorporations[this.selectedDistrict.id];
        console.log("municipalCorporation", this.selectedMunicipalCorporation);

        return;
      } else {
        console.log("this.selectedDistrict", this.selectedDistrict.id);

        this.fetchBlocksForDistrict(this.selectedDistrict.id);
        // Preload gram panchayats data for the selected district
        this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
      }
    }
  }

  selectedBlk: any = "";
  onBlockChange(): void {
    this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
    this.selectedBlock = this.selectedBlk.id;
    this.blockName = this.selectedBlk.name;
    console.log(this.selectedBlock);

    console.log("onBlockchnge", this.selectedDistrict.id);
    this.selectedGramPanchayat = ""; // Reset selected gram panchayat when block changes
    if (this.selectedBlock) {
      this.selectedGramPanchayat = ""; // Reset selected gram panchayat when block changes
      // this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
    }
    // Set the gram panchayats data based on the selected block
    if (this.selectedBlock && this.blocks[this.selectedBlock]) {
      this.gramPanchayats =
        this.gramPanchayats[this.selectedDistrict][this.selectedBlock];
      console.log(this.gramPanchayats);
    }
  }

  selectedGP: any = "";
  onPChange(): void {
    this.selectedGramPanchayat = this.selectedGP.name;
    this.localBodyName = this.selectedGramPanchayat;
    // this.blockName=this.selectedBlk.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("blockName: ", this.blockName);
    console.log("Local body name: ", this.localBodyName);
    // console.log(
    //   this.districtName,
    //   this.blockName,

    //   this.selectedGramPanchayat
    // );
  }

  // For Municipal Level

  municipalities: any;
  selectedMunicipality: any;
  selectedMunicipalityId: any;

  fetchMunicipalitiesForDistrict(): void {
    this.http
      .get<any>("assets/cloudKitchenPlaces/municipalities/municipalities.json")
      .subscribe((data) => {
        this.municipalities = data.municipalities;
        console.log(data.municipalities);
      });
  }

  selectedLevel: string = ""; // Property to store the selected level
  legalBody: string;

  blocks: any;
  gramPanchayats: any = {};

  selectedDistrict: any = "";
  selectedBlock: string = "";
  selectedGramPanchayat: string = "";

  gramPanchayatsLoaded: boolean = false;

  // Method to handle radio button selection
  // For Municipal Level

  // Method to handle radio button selection
  onLevelSelection(selectedLevel: string): void {
    if (selectedLevel == "") {
      window.location.reload();
    }

    this.selectedDistrict = "";
    this.selectedBlock = ""; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = "";
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    this.selectedMunicipality = null;
    this.selectedMunicipalCorporation = null;
    this.selectedLevel = selectedLevel;
    this.selectedMunicipalCorp = "";

    console.log(this.selectedMunicipalityId);

    if (this.selectedLevel === "blockLevel") {
      this.legalBody = "PAN";
      this.localBodyType = "Panchayat";
      this.fetchDistricts();
      this.localBodyType = "Panchayat";
    }
    if (this.selectedLevel === "municipalityLevel") {
      this.legalBody = "MUN";
      this.localBodyType = "Municipality";
      this.fetchDistricts();
      this.fetchMunicipalitiesForDistrict();
    }
    if (this.selectedLevel === "municipalCorporation") {
      this.legalBody = "MNC";
      this.localBodyType = "Corporation";

      this.fetchDistrictsforMunicipalCorporation();
      this.fetchMunicipalCorporationsForDistrict();
    }
    console.log("legalBody", this.legalBody);
  }
  selectedMunicipal: any = "";

  onMunicipalityChange(): void {
    // Implement actions to handle the change in selected municipality
    console.log("Selected municipality:", this.selectedMunicipal.name);
    this.selectedMunicipalityId = this.selectedMunicipal.id;
    this.localBodyName = this.selectedMunicipal.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("Local body name: ", this.localBodyName);

    // You can perform additional actions here based on the selected municipality
  }

  selectedMunicipalCorp: any = "";
  onMunicipalCorporationChange(): void {
    this.selectedMunicipalCorporationId = this.selectedMunicipalCorp.id;
    this.localBodyName = this.selectedMunicipalCorp.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("Local body name: ", this.localBodyName);
    // Implement actions to handle the change in selected municipality
    //  console.log('Selected municipality:', this.selectedMunicipalCorporationId);
    // You can perform additional actions here based on the selected municipality
  }

  //For Municipal Corporation Level;
  municipalCorporations: any = {};
  selectedMunicipalCorporation: any;
  selectedMunicipalCorporationId: any;

  fetchMunicipalCorporationsForDistrict(): void {
    // Assuming you are fetching data from a JSON file named municipal_corporations.json
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/municipalCorporations/municipal_corporation.json"
      )
      .subscribe((data) => {
        this.municipalCorporations = data.municipal_corporations || {};
        console.log(this.municipalCorporations);
      });
  }

  //--------show filter----------------------------------------------------------
  toggleFilterTable1(){
    this.showFilterTable1 = !this.showFilterTable1;
    this.showFilterTable2 = false
    this.showFilterTable3 = false
    this.showFilterTable4 = false
    if(this.showTable1){
      this.showTable1 = false;
    }
    this.showTable2 = false;
    this.showTable3 = false;
    this.showTable4 = false;
    this.checkBoxMe = false;
    this.checkBoxSvep = false;
    this.checkBoxFarm = false;
    this.checkBoxAnimalHusbandary = false;
    this.checkBoxSISD = false;
    this.checkBoxUrban = false;
    this.checkBoxAll = false;

    this.selectedDistrictValue = '';
    this.filterCheckbox = [];
  }
  toggleFilterTable2(){
    this.showFilterTable1 = false
    this.showFilterTable2 = !this.showFilterTable2;
    this.showFilterTable3 = false
    this.showFilterTable4 = false
    if(this.showTable2){
      this.showTable2 = false;
    }
    this.showTable1 = false;
    this.showTable3 = false;
    this.showTable4 = false;
    this.checkBoxMe = false;
    this.checkBoxSvep = false;
    this.checkBoxFarm = false;
    this.checkBoxAnimalHusbandary = false;
    this.checkBoxSISD = false;
    this.checkBoxUrban = false;
    this.checkBoxAll = false;

    this.filterCheckbox = [];
    this.selectedDistrictValue = '';
  }
  toggleFilterTable3(){
    this.showFilterTable1 = false
    this.showFilterTable2 = false
    this.showFilterTable3 = !this.showFilterTable3;
    this.showFilterTable4 = false

    this.showTable1 = false;
    this.showTable2 = false;
    this.showTable4 = false;
    this.filterCheckbox = [];
  }
  toggleFilterTable4(){
    this.showFilterTable1 = false
    this.showFilterTable2 = false
    this.showFilterTable3 = false
    this.showFilterTable4 = !this.showFilterTable4;

    this.showTable1 = false;
    this.showTable2 = false;
    this.showTable3 = false;
    this.filterCheckbox = [];
  }

  filterCheckbox: any = [];
  selectedDistrictValue: string = '';
  checkBoxQuery(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const value = checkbox.value;
    if (checkbox.checked) {
      this.filterCheckbox.push(value); // Add value to filterCheckbox when checked
    } else {
      const index = this.filterCheckbox.indexOf(value);
      if (index > -1) {
        this.filterCheckbox.splice(index, 1); // Remove value from filterCheckbox when unchecked
      }
    }
    console.log(this.filterCheckbox);
  }
    //--------filter Checkbox-----------------------------------------------
  toggleCheckbox(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.value === 'me') {
      this.checkBoxMe = !this.checkBoxMe;
    }
    if (checkbox.value === 'svep') {
      this.checkBoxSvep = !this.checkBoxSvep;
    }
    if (checkbox.value === 'farm') {
      this.checkBoxFarm = !this.checkBoxFarm;
    }
    if (checkbox.value === 'animalHusbandary') {
      this.checkBoxAnimalHusbandary = !this.checkBoxAnimalHusbandary;
    }
    if (checkbox.value === 'sisd') {
      this.checkBoxSISD = !this.checkBoxSISD;
    }
    if (checkbox.value === 'urban') {
      this.checkBoxUrban = !this.checkBoxUrban;
    }
    if (checkbox.value === 'all') {
      this.checkBoxMe = !this.checkBoxMe;
      this.checkBoxSvep = !this.checkBoxSvep;
      this.checkBoxFarm = !this.checkBoxFarm;
      this.checkBoxAnimalHusbandary = !this.checkBoxAnimalHusbandary;
      this.checkBoxSISD = !this.checkBoxSISD;
      this.checkBoxUrban = !this.checkBoxUrban;
    }
    console.log("Checkbox value:", checkbox.value);
  }

  //detailed analytics code ------------------------------------------------

  //to show the detailed analytics tables
  toggleTable1() {
    console.log("filterCheckbox", this.filterCheckbox);
    // Reset checkboxes based on filterCheckbox
    this.checkBoxMe = this.filterCheckbox.includes('me');
    this.checkBoxSvep = this.filterCheckbox.includes('svep');
    this.checkBoxFarm = this.filterCheckbox.includes('farm');
    this.checkBoxAnimalHusbandary = this.filterCheckbox.includes('animalHusbandary');
    this.checkBoxSISD = this.filterCheckbox.includes('sisd');
    this.checkBoxUrban = this.filterCheckbox.includes('urban');

    if (!this.checkBoxMe && !this.checkBoxSvep && !this.checkBoxFarm && !this.checkBoxAnimalHusbandary && !this.checkBoxSISD && !this.checkBoxUrban) {
      Swal.fire({
        title: "Please select at least one filter",
        icon: 'error',
        confirmButtonText: "OK",
      })
    }
    else{
      this.tableLoading = true;
      setTimeout(() => {
        this.showTable1 = true;
        this.tableLoading = false;
      }, 800);
      this.showTable2 = false;
      this.showTable3 = false;
      this.showTable4 = false;
      this.getDistrictAnalytics(this.selectedDistrictValue);
      console.log("district value", this.selectedDistrictValue);
    }
  }

  toggleTable2() {
    // Reset checkboxes based on filterCheckbox
    this.checkBoxMe = this.filterCheckbox.includes('me');
    this.checkBoxSvep = this.filterCheckbox.includes('svep');
    this.checkBoxFarm = this.filterCheckbox.includes('farm');
    this.checkBoxAnimalHusbandary = this.filterCheckbox.includes('animalHusbandary');
    this.checkBoxSISD = this.filterCheckbox.includes('sisd');
    this.checkBoxUrban = this.filterCheckbox.includes('urban');

    if (!this.checkBoxMe && !this.checkBoxSvep && !this.checkBoxFarm && !this.checkBoxAnimalHusbandary && !this.checkBoxSISD && !this.checkBoxUrban) {
      Swal.fire({
        title: "Please select at least one filter",
        icon: 'error',
        confirmButtonText: "OK",
      });
    } else {
      this.tableLoading2 = true;
      setTimeout(() => {
        this.showTable2 = true;
        this.tableLoading2 = false;
      }, 800);
      this.showTable1 = false;
      this.showTable3 = false;
      this.showTable4 = false;
      this.getDistrictAnalyticsForTable2(this.selectedDistrictValue);
      console.log("district value", this.selectedDistrictValue);
    }
  }

  toggleTable3() {
    this.showTable3 = !this.showTable3;
    this.showTable1 = false;
    this.showTable2 = false;
    this.showTable4 = false;
    this.onDistrictFilterChange3(event);
    this.showFilterTable1 = false
    this.showFilterTable2 = false
  }

  toggleTable4() {
    this.showTable4 = !this.showTable4;
    this.showTable1 = false;
    this.showTable2 = false;
    this.showTable3 = false;
    this.onDistrictFilterChange4(event)
    this.showFilterTable1 = false
    this.showFilterTable2 = false
  }
//-------------------- for table 1 -------------------------------------------------------
  // to select the district from the dropdown and filter the table
  onDistrictFilterChange(event: Event) {
    const selectedDistrictName = (event.target as HTMLSelectElement).value;
    console.log("Selected District name: ", selectedDistrictName);
    this.getDistrictAnalytics(selectedDistrictName);
  }

  // api to get the district analytics from the backend
  getDistrictAnalytics(district: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("district", district);

    this.http
      .get<any>(`${KP_ADMIN_URL}/districtAnalytics`, { headers, params })
      .subscribe(
        (data) => {
          console.log("District Analytics:", data);
          this.districtAnalytics = data;
        },
        (error) => {
          console.error("Error fetching district analytics:", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
            
          }
        }
      );
  }

  // to get the count of the category
  getCategoryCount(categories: any[], categoryName: string): number {
    const category = categories.find(
      (c) => c.category.toLowerCase() === categoryName.toLowerCase()
    );
    return category ? category.count : 0;
  }


getTotalForColumn(category: string, type: string): number {
  return this.districtAnalytics.reduce((sum, district) => {
    return sum + this.getCategoryCount(district[category], type);
  }, 0);
}

getTotalSellerCount(): number {
  return this.districtAnalytics.reduce((sum, district) => {
    return sum + district.sellerCount;
  }, 0);
}

getTotalProductCount(): number {
  return this.districtAnalytics.reduce((sum, district) => {
    return sum + district.productCount;
  }, 0);
}


  
  //-------------------- for table 1 end-------------------------------------------------------

  //----------for table 2 -----------------------------------------------------------------
  // Select the district from the dropdown and filter the table2
  
  onDistrictFilterChange2(event: Event) {
    const selectedDistrictName = (event.target as HTMLSelectElement).value;
    console.log("Selected District name: ", selectedDistrictName);
    this.selectedDistrictValue = selectedDistrictName;
    this.getDistrictAnalyticsForTable2(selectedDistrictName);
  }

  // api to get the district analytics from the backend for table2
  getDistrictAnalyticsForTable2(district: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("district", district);

    this.http
      .get<any>(`${KP_ADMIN_URL}/districtAnalyticsForTable2`, {
        headers,
        params,
      })
      .subscribe(
        (data) => {
          console.log("District Analytics:", data);
          // this.districtAnalytics2 = data;
          this.districtAnalytics2 = this.processDistrictAnalytics2(data);
        },
        (error) => {
          console.error("Error fetching district analytics:", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
            
          }
        }
      );
  }

  processDistrictAnalytics2(data: any[]): any[] {
    return data.map((district) => {
      const processedDistrict: any = {
        district: district._id,
        me: {
          productApprovedOnline: 0,
          productApprovedOffline: 0,
          productApproved: 0,
          productNotVerified: 0,
          productNotVerifiedOnline: 0,
          productNotVerifiedOffline: 0,
          productNotApproved: 0,
          productNotApprovedOnline: 0,
          productNotApprovedOffline: 0,
          totalNotVerified: 0,
          totalNotVerifiedOnline: 0,
          totalNotVerifiedOffline: 0,
        },
        svep: {
          productApproved: 0,
          productApprovedOnline: 0,
          productApprovedOffline: 0,
          productNotVerified: 0,
          productNotVerifiedOnline: 0,
          productNotVerifiedOffline: 0,
          productNotApproved: 0,
          productNotApprovedOnline: 0,
          productNotApprovedOffline: 0,
          totalNotVerified: 0,
          totalNotVerifiedOnline: 0,
          totalNotVerifiedOffline: 0,
        },
        farm: {
          productApproved: 0,
          productApprovedOnline: 0,
          productApprovedOffline: 0,
          productNotVerified: 0,
          productNotVerifiedOnline: 0,
          productNotVerifiedOffline: 0,
          productNotApproved: 0,
          productNotApprovedOnline: 0,
          productNotApprovedOffline: 0,
          totalNotVerified: 0,
          totalNotVerifiedOnline: 0,
          totalNotVerifiedOffline: 0,
        },
        animalHusbandary: {
          productApproved: 0,
          productApprovedOnline: 0,
          productApprovedOffline: 0,
          productNotVerified: 0,
          productNotVerifiedOnline: 0,
          productNotVerifiedOffline: 0,
          productNotApproved: 0,
          productNotApprovedOnline: 0,
          productNotApprovedOffline: 0,
          totalNotVerified: 0,
          totalNotVerifiedOnline: 0,
          totalNotVerifiedOffline: 0,
        },
        sisd: {
          productApproved: 0,
          productApprovedOnline: 0,
          productApprovedOffline: 0,
          productNotVerified: 0,
          productNotVerifiedOnline: 0,
          productNotVerifiedOffline: 0,
          productNotApproved: 0,
          productNotApprovedOnline: 0,
          productNotApprovedOffline: 0,
          totalNotVerified: 0,
          totalNotVerifiedOnline: 0,
          totalNotVerifiedOffline: 0,
        },
        urban: {
          productApproved: 0,
          productApprovedOnline: 0,
          productApprovedOffline: 0,
          productNotVerified: 0,
          productNotVerifiedOnline: 0,
          productNotVerifiedOffline: 0,
          productNotApproved: 0,
          productNotApprovedOnline: 0,
          productNotApprovedOffline: 0,
          totalNotVerified: 0,
          totalNotVerifiedOnline: 0,
          totalNotVerifiedOffline: 0,
        },
        total: {
          productApproved: district.totalApproved,
          productNotVerified: district.totalPendingVerification,
          productNotApproved: district.totalPendingApproval,
          totalNotVerified: district.totalNotVerified,
        },
      };

      district.categories.forEach((category: any) => {
        switch (category.category) {
          case "type_3":
            processedDistrict.me = this.setCategoryData(category);
            break;
          case "type_4":
            processedDistrict.svep = this.setCategoryData(category);
            break;
          case "type_1":
            processedDistrict.farm = this.setCategoryData(category);
            break;
          case "type_7":
            processedDistrict.animalHusbandary = this.setCategoryData(category);
            break;
          case "type_5":
            processedDistrict.sisd = this.setCategoryData(category);
            break;
          case "type_2":
            processedDistrict.urban = this.setCategoryData(category);
            break;
        }
      });

      return processedDistrict;
    });
  }

  setCategoryData(category: any): any {
    return {
      productApproved: category.approved,
      productApprovedOnline: category.approvedOnline,
      productApprovedOffline: category.approved - category.approvedOnline,

      productNotVerified: category.pendingVerification,
      productNotVerifiedOnline: category.pendingVerificationOnline,
      productNotVerifiedOffline: category.pendingVerification - category.pendingVerificationOnline,
      
      productNotApproved: category.pendingApproval,
      productNotApprovedOnline: category.pendingApprovalOnline,
      productNotApprovedOffline: category.pendingApproval - category.pendingApprovalOnline,

      totalNotVerified: category.notVerified,
      totalNotVerifiedOnline: category.notVerifiedOnline,
      totalNotVerifiedOffline: category.notVerified - category.notVerifiedOnline,

      // totalNotApproved: category.notApproved,
      // totalNotApprovedOnline: category.notApprovedOnline,
      // totalNotApprovedOffline: category.notApproved - category.notApprovedOnline,

      // productNotVerified: category.pendingVerification,
      // productNotApproved: category.pendingApproval,
      // totalNotVerified: category.notVerified,
    };
  }

  getTotalForTable2(category: string, field: string): number {
    return this.districtAnalytics2.reduce((sum, district) => {
      return sum + district[category][field];
    }, 0);
  }

  //----------for table 2 end-----------------------------------------------------------------

  //----------for table 3 ------------------------------------------------------------------
   // Select the district from the dropdown and filter the table3
  onDistrictFilterChange3(event: Event) {
    const selectedDistrictName = (event.target as HTMLSelectElement).value;
    console.log("Selected District name: ", selectedDistrictName);
    this.getDistrictAnalyticsForTable3(selectedDistrictName);
  }

   // api to get the district analytics from the backend for table3
   getDistrictAnalyticsForTable3(district: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("district", district);

    this.http
      .get<any>(`${KP_ADMIN_URL}/districtAnalyticsForTable3`, {
        headers,
        params,
      })
      .subscribe(
        (data) => {
          console.log("District Analytics:", data);
          this.districtAnalytics3 = data;
          // this.districtAnalytics2 = data;
          // this.districtAnalytics3 = this.processDistrictAnalytics2(data);
        },
        (error) => {
          console.error("Error fetching district analytics:", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
            
          }
        }
      );
  }


  getTotalForTable3(field: string): number {
    return this.districtAnalytics3.reduce((sum, district) => sum + (district[field] || 0), 0);
  }
  
  getTotalForTable3Category(category: string): number {
    return this.districtAnalytics3.reduce((sum, district) => 
      sum + (district.groupedCategories[category] || 0), 0);
  }


  // to download the pdf tables
  downloadPdfForTable1() {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  
    const documentDefinition = {
      content: [
        { text: 'Analytics Report', style: 'header' },
        {
          table: {
            headerRows: 3,
            widths: Array(42).fill('auto'),
            body: this.getTableDataForPdf()
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
            }
          }
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        }
      },
      pageOrientation: 'landscape',
      pageSize: 'A3'
    };
  
    pdfMake.createPdf(documentDefinition).download('district_analytics_report.pdf');
  }
  
  getTableDataForPdf() {
    const tableData = [
      [
        { text: 'Sl No', rowSpan: 3, style: 'tableHeader' },
        { text: 'District Name', rowSpan: 3, style: 'tableHeader' },
        { text: 'ME', colSpan: 6, style: 'tableHeader' }, {},{},
        { text: 'SVEP', colSpan: 6, style: 'tableHeader' }, {},{},
        { text: 'Farmlivelihood', colSpan: 6, style: 'tableHeader' }, {},{},
        { text: 'Animal Husbandry', colSpan: 6, style: 'tableHeader' }, {},{},
        { text: 'SISD', colSpan: 6, style: 'tableHeader' }, {},{},
        { text: 'Urban', colSpan: 6, style: 'tableHeader' }, {},{},
        { text: 'Total', colSpan: 6, style: 'tableHeader' }, {},{},
      ],
      [
        {}, {},{},{},
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Unit Registered', style: 'tableHeader' }, { text: 'No of Product', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' }, { text: 'Offline', style: 'tableHeader' }, { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' }, { text: 'Offline', style: 'tableHeader' }, { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' }, { text: 'Offline', style: 'tableHeader' }, { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' }, { text: 'Offline', style: 'tableHeader' }, { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' }, { text: 'Offline', style: 'tableHeader' }, { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' }, { text: 'Offline', style: 'tableHeader' }, { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' }, { text: 'Offline', style: 'tableHeader' }, { text: 'Total', style: 'tableHeader' },
        
      ]
    ];
  
    this.districtAnalytics.forEach((districtData, index) => {
      tableData.push([
        index + 1,
        districtData.district,
        this.getCategoryCount(districtData.sellerCategoriesOnline, "type_3"),
        this.getCategoryCount(districtData.sellerCategoriesOffline, "type_3"),
        this.getCategoryCount(districtData.sellerCategories, "type_3"),
        this.getCategoryCount(districtData.productCategoriesOnline, "type_3"),
        this.getCategoryCount(districtData.productCategoriesOffline, "type_3"),
        this.getCategoryCount(districtData.productCategories, "type_3"),

        this.getCategoryCount(districtData.sellerCategoriesOnline, "type_4"),
        this.getCategoryCount(districtData.sellerCategoriesOffline, "type_4"),
        this.getCategoryCount(districtData.sellerCategories, "type_4"),
        this.getCategoryCount(districtData.productCategoriesOnline, "type_4"),
        this.getCategoryCount(districtData.productCategoriesOffline, "type_4"),
        this.getCategoryCount(districtData.productCategories, "type_4"),

        this.getCategoryCount(districtData.sellerCategoriesOnline, "type_1"),
        this.getCategoryCount(districtData.sellerCategoriesOffline, "type_1"),
        this.getCategoryCount(districtData.sellerCategories, "type_1"),
        this.getCategoryCount(districtData.productCategoriesOnline, "type_1"),
        this.getCategoryCount(districtData.productCategoriesOffline, "type_1"),
        this.getCategoryCount(districtData.productCategories, "type_1"),

        this.getCategoryCount(districtData.sellerCategoriesOnline, "type_7"),
        this.getCategoryCount(districtData.sellerCategoriesOffline, "type_7"),
        this.getCategoryCount(districtData.sellerCategories, "type_7"),
        this.getCategoryCount(districtData.productCategoriesOnline, "type_7"),
        this.getCategoryCount(districtData.productCategoriesOffline, "type_7"),
        this.getCategoryCount(districtData.productCategories, "type_7"),

        this.getCategoryCount(districtData.sellerCategoriesOnline, "type_5"),
        this.getCategoryCount(districtData.sellerCategoriesOffline, "type_5"),
        this.getCategoryCount(districtData.sellerCategories, "type_5"),
        this.getCategoryCount(districtData.productCategoriesOnline, "type_5"),
        this.getCategoryCount(districtData.productCategoriesOffline, "type_5"),
        this.getCategoryCount(districtData.productCategories, "type_5"),

        this.getCategoryCount(districtData.sellerCategoriesOnline, "type_2"),
        this.getCategoryCount(districtData.sellerCategoriesOffline, "type_2"),
        this.getCategoryCount(districtData.sellerCategories, "type_2"),
        this.getCategoryCount(districtData.productCategoriesOnline, "type_2"),
        this.getCategoryCount(districtData.productCategoriesOffline, "type_2"),
        this.getCategoryCount(districtData.productCategories, "type_2"),

        districtData.onlineSellerCount,
        districtData.offlineSellerCount,
        districtData.sellerCount,
        districtData.onlineProductCount,
        districtData.offlineProductCount,
        districtData.productCount
      ]);
    });

    
  
    return tableData;
  }

  exportToPDF() {
    const doc: any = new jsPDF();
    
    // Use autoTable plugin to convert the HTML table into a PDF
    doc.autoTable({ html: '#tableToExport' });
    doc.setProperties({
      orientation: 'landscape',
      pageSize: 'A2',
      pageMargins: [2, 2, 2, 2],
      pageBorder: true,
      lineWidth: 0.5,
      lineColor: 'black'
    });
    // Save the PDF
    doc.save('table.pdf');
  }
  

  exportTableToExcel(): void {
    const tableElement = document.getElementById('tableToExport') as HTMLTableElement;
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tableElement);
    Object.keys(worksheet).forEach((key) => {
      if (key.startsWith('!')) return;
    });

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, 'Seller_Unit_Analytics.xlsx');
  }

  exportTableToExcel2(): void {
    const tableElement = document.getElementById('tableToExport2') as HTMLTableElement;
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tableElement);

    Object.keys(worksheet).forEach((key) => {
      if (key.startsWith('!')) return; 
    });

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, 'Products_Analytics_Table.xlsx');
  }


  downloadPdfForTable2() {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  
    const documentDefinition = {
      content: [
        { text: 'Analytics Report - Table 2', style: 'header' },
        {
          table: {
            headerRows: 2,
            // Remove the widths property to let pdfMake calculate it automatically
            body: this.getTable2DataForPdf()
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
            },
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
            },
          },
          // Add right margin to the table
          margin: [0, 0, 0, 0]
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black'
        }
      },
      pageOrientation: 'landscape',
      pageSize: 'A2'
    };
  
    pdfMake.createPdf(documentDefinition).download('district_analytics_report_table2.pdf');
  }

  getTable2DataForPdf() {
    const tableData = [
      [
        { text: 'Sl No', rowSpan: 3, style: 'tableHeader' },
        { text: 'District Name', rowSpan: 3, style: 'tableHeader'  },
        { text: 'ME', colSpan: 9, style: 'tableHeader' },
        { text: 'SVEP', colSpan: 9, style: 'tableHeader' },
        { text: 'Farmlivelihood', colSpan: 9, style: 'tableHeader' },
        { text: 'Animal Husbandry', colSpan: 9, style: 'tableHeader' },
        { text: 'SISD', colSpan: 9, style: 'tableHeader' },
        { text: 'Urban', colSpan: 9, style: 'tableHeader' },
        { text: 'Total', colSpan: 3, style: 'tableHeader' },
      ],
      [
        { text: '', style: 'tableHeader' },
        { text: '', style: 'tableHeader' },
        { text: 'Product Approved', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not verified by District', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not Approved By State', colSpan: 3, style: 'tableHeader' },
        { text: 'Product Approved', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not verified by District', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not Approved By State', colSpan: 3, style: 'tableHeader' },
        { text: 'Product Approved', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not verified by District', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not Approved By State', colSpan: 3, style: 'tableHeader' },
        { text: 'Product Approved', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not verified by District', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not Approved By State', colSpan: 3, style: 'tableHeader' },
        { text: 'Product Approved', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not verified by District', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not Approved By State', colSpan: 3, style: 'tableHeader' },
        { text: 'Product Approved', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not verified by District', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not Approved By State', colSpan: 3, style: 'tableHeader' },
        { text: 'Product Approved', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not verified by District', colSpan: 3, style: 'tableHeader' },
        { text: 'Products Not Approved By State', colSpan: 3, style: 'tableHeader' }
      ],
      [
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
        { text: 'Online', style: 'tableHeader' },
        { text: 'Offline', style: 'tableHeader' },
        { text: 'Total', style: 'tableHeader' },
      ]
    ]
  
    this.districtAnalytics2.forEach((districtData, index) => {
      tableData.push([
        index + 1,
        districtData.district,
        districtData.me.productApproved, districtData.me.totalNotVerified, districtData.me.productNotApproved,
        districtData.svep.productApproved, districtData.svep.totalNotVerified, districtData.svep.productNotApproved,
        districtData.farm.productApproved, districtData.farm.totalNotVerified, districtData.farm.productNotApproved,
        districtData.animalHusbandary.productApproved, districtData.animalHusbandary.totalNotVerified, districtData.animalHusbandary.productNotApproved,
        districtData.sisd.productApproved, districtData.sisd.totalNotVerified, districtData.sisd.productNotApproved,
        districtData.urban.productApproved, districtData.urban.totalNotVerified, districtData.urban.productNotApproved,
        districtData.total.productApproved, districtData.total.totalNotVerified, districtData.total.productNotApproved
      ]);
    }),
    this.districtAnalytics2.forEach((districtData, index) => {

      tableData.push([
        index + 1,
        districtData.district,
        districtData.me.productApprovedOnline, districtData.me.productApprovedOffline, districtData.me.productApproved,
        districtData.me.totalNotVerifiedOnline, districtData.me.totalNotVerifiedOffline, districtData.me.totalNotVerified,
        districtData.me.productNotApprovedOnline, districtData.me.productNotApprovedOffline,districtData.me.productNotApproved,

        districtData.svep.productApprovedOnline, districtData.svep.productApprovedOffline, districtData.svep.productApproved,
        districtData.svep.totalNotVerifiedOnline, districtData.svep.totalNotVerifiedOffline, districtData.svep.totalNotVerified,
        districtData.svep.productNotApprovedOnline, districtData.svep.productNotApprovedOffline,districtData.svep.productNotApproved,

        districtData.farm.productApprovedOnline, districtData.farm.productApprovedOffline, districtData.farm.productApproved,
        districtData.farm.totalNotVerifiedOnline, districtData.farm.totalNotVerifiedOffline, districtData.farm.totalNotVerified,
        districtData.farm.productNotApprovedOnline, districtData.farm.productNotApprovedOffline,districtData.farm.productNotApproved,

        districtData.animalHusbandary.productApprovedOnline, districtData.animalHusbandary.productApprovedOffline, districtData.animalHusbandary.productApproved,
        districtData.animalHusbandary.totalNotVerifiedOnline, districtData.animalHusbandary.totalNotVerifiedOffline, districtData.animalHusbandary.totalNotVerified,
        districtData.animalHusbandary.productNotApprovedOnline, districtData.animalHusbandary.productNotApprovedOffline,districtData.animalHusbandary.productNotApproved,

        districtData.sisd.productApprovedOnline, districtData.sisd.productApprovedOffline, districtData.sisd.productApproved,
        districtData.sisd.totalNotVerifiedOnline, districtData.sisd.totalNotVerifiedOffline, districtData.sisd.totalNotVerified,
        districtData.sisd.productNotApprovedOnline, districtData.sisd.productNotApprovedOffline,districtData.sisd.productNotApproved,

        districtData.urban.productApprovedOnline, districtData.urban.productApprovedOffline, districtData.urban.productApproved,
        districtData.urban.totalNotVerifiedOnline, districtData.urban.totalNotVerifiedOffline, districtData.urban.totalNotVerified,
        districtData.urban.productNotApprovedOnline, districtData.urban.productNotApprovedOffline,districtData.urban.productNotApproved,

        districtData.total.productApprovedOnline, districtData.total.productApprovedOffline, districtData.total.productApproved,
        districtData.total.totalNotVerifiedOnline, districtData.total.totalNotVerifiedOffline, districtData.total.totalNotVerified,
        districtData.total.productNotApprovedOnline, districtData.total.productNotApprovedOffline,districtData.total.productNotApproved,

      ]);
    })
  
    return tableData;
  }

  downloadPdfForTable3() {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  
    const documentDefinition = {
      content: [
        { text: 'Analytics Report - Table 3', style: 'header' },
        {
          table: {
            headerRows: 1,
            widths: Array(19).fill('auto'), // 19 columns in total
            body: this.getTable3DataForPdf()
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
            }
          }
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        }
      },
      pageOrientation: 'landscape',
      pageSize: 'A3'
    };
  
    pdfMake.createPdf(documentDefinition).download('district_analytics_report_table3.pdf');
  }

  getTable3DataForPdf() {
    const tableData = [
      [
        'Sl No',
        'District Name',
        'Total Count',
        'Food products',
        'Personal Care',
        'Fashion',
        'Gem and Jewellery',
        'Eco friendly products',
        'Herbal products',
        'Stationary',
        'Toys',
        'Electronics',
        'Fresh Vegetables & Fruits',
        'Fresh Fish & Meat',
        'Milk',
        'Millet',
        'Spices',
        'Home and Kitchen',
        'Others'
      ]
    ];
  
    this.districtAnalytics3.forEach((district, index) => {
      tableData.push([
        index + 1,
        district.district,
        district.totalCount,
        district.groupedCategories["Food products"],
        district.groupedCategories["Personal Care"],
        district.groupedCategories["Fashion"],
        district.groupedCategories["Gem and Jewellery"],
        district.groupedCategories["Eco friendly products"],
        district.groupedCategories["Herbal products"],
        district.groupedCategories["Stationary"],
        district.groupedCategories["Toys"],
        district.groupedCategories["Electronics"],
        district.groupedCategories["Fresh Vegetables & Fruits"],
        district.groupedCategories["Fresh Fish & Meat"],
        district.groupedCategories["Milk"],
        district.groupedCategories["Millet"],
        district.groupedCategories["Spices"],
        district.groupedCategories["Home and Kitchen"],
        district.groupedCategories["Others"]
      ]);
    });
  
    return tableData;
  }



    //-------------------- for table 4 -------------------------------------------------------
  // // to select the district from the dropdown and filter the table
  onDistrictFilterChange4(event: Event) {
    const selectedDistrictName = (event.target as HTMLSelectElement).value;
    console.log("Selected District name: ", selectedDistrictName);
    this.getDistrictAnalyticsForTable4(selectedDistrictName);
  }

  // api to get the district analytics from the backend
  getDistrictAnalyticsForTable4(district: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("district", district);

    this.http
      .get<any>(`${KP_ADMIN_URL}/districtAnalyticsforKfood`, { headers, params })
      .subscribe(
        (data) => {
          console.log("District Analytics:", data);
          this.districtAnalytics4 = data;
          console.log('the districtAnalytics', this.districtAnalytics);
        },
        (error) => {
          console.error("Error fetching district analytics:", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
            
          }
        }
      );
  }

  getTotalForTable4(type: string): number {
    return this.districtAnalytics4.reduce((sum, district) => {
      return sum + (district.typeCounts[type] || 0);
    }, 0);
  }
  
  getTotalForTable4Count(): number {
    return this.districtAnalytics4.reduce((sum, district) => {
      return sum + (district.totalCount || 0);
    }, 0);
  }

 


}
