import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { AnalyticsPageComponent } from './components/analytics-page/analytics-page.component';
import { HeaderComponent } from './components/header/header.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { CreateMeUnitComponent } from './components/create-me-unit/create-me-unit.component';
import { CreateULBComponent } from './components/create-ulb/create-ulb.component';
import { MeUnitListingsComponent } from './components/me-unit-listings/me-unit-listings.component';
import { AddServiceProviderComponent } from './components/add-service-provider/add-service-provider.component';
import { ServiceProviderListingComponent } from './components/service-provider-listing/service-provider-listing.component';
import { ServiceProviderDescriptionComponent } from './components/service-provider-description/service-provider-description.component';
import { MeUnitDescriptionComponent } from './components/me-unit-description/me-unit-description.component';
import { HttpClientModule } from '@angular/common/http';
import { CreateDistrictAdminComponent } from './components/create-district-admin/create-district-admin.component';
import { UlbListingsComponent } from './components/ulb-listings/ulb-listings.component';
import { ServiceRequestListingsComponent } from './components/service-request-listings/service-request-listings.component';
import { ServiceRequestDescriptionComponent } from './components/service-request-description/service-request-description.component';
import { PlaceAutocompleteComponent } from './place-autocomplete/place-autocomplete.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BookServiceManuallyComponent } from './components/book-service-manually/book-service-manually.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { CreateMarketingDistrictAdminComponent } from './components/create-marketing-district-admin/create-marketing-district-admin.component';
// pdf viewer module
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AddKfoodDetailsComponent } from './add-kfood-details/add-kfood-details.component';
import { KfoodListComponent } from './kfood-list/kfood-list.component';
import { OnlineOrderListingComponent } from './online-order-listing/online-order-listing.component';
import { CommonModule } from '@angular/common';
import { OrderDescriptionComponent } from './order-description/order-description.component';
import { PayoutsComponent } from './payouts/payouts.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    SideBarComponent,
    AnalyticsPageComponent,
    HeaderComponent,
    ProfilePageComponent,
    ChangePasswordComponent,
    CreateMeUnitComponent,
    CreateULBComponent,
    MeUnitListingsComponent,
    AddServiceProviderComponent,
    ServiceRequestListingsComponent,
    ServiceProviderListingComponent,
    ServiceProviderDescriptionComponent,
    MeUnitDescriptionComponent,
    CreateDistrictAdminComponent,
    UlbListingsComponent,
    ServiceRequestDescriptionComponent,
    PlaceAutocompleteComponent,
    BookServiceManuallyComponent,
    OrderDetailsComponent,
    CreateMarketingDistrictAdminComponent,
    AddKfoodDetailsComponent,
    KfoodListComponent,
    OnlineOrderListingComponent,
    OrderDescriptionComponent,
    PayoutsComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    HttpClientModule,
    MatExpansionModule,
    PdfViewerModule,
    MatSelectModule,
    MatCheckboxModule,
    CommonModule,
  ],

  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
