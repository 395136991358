<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 pb-0 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Products Description</u></p> 
   
    </div>

    <div class="col">
      <p class="pageReference">Products Listings > Products Description</p>
    </div>
   
  </div>

  <!-- Add this at the top of your template -->
  <div *ngIf="isProductRejected()" class="alert alert-danger mt-3">
    <strong>Product Rejected:</strong> {{ getReadableStatus(rejectStatus) }}
    <p><strong>Reason:</strong> {{ rejectReason }}</p>
  </div>

  <div class="row">
    <div class="row" *ngIf="isDistrictAdmin() || isMarketingAdmin()">
      <div class="col">
        <div class="form-check form-switch">
          <input
            class="form-check-input instockToggle"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            [(ngModel)]="districtAdminVerification"
            [ngStyle]="{
              'background-color': districtAdminVerification ? 'green' : '',
              'border-color': districtAdminVerification ? 'green' : ''
            }"
          />

          <label
            class="form-check-label instockLabel"
            for="flexSwitchCheckChecked"
            >Verified by District Admin</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-3" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col" >
      
        
     
    </div>
      
      <div class="col">
        <div class="form-check form-switch">
          <input
            class="form-check-input instockToggle"
            type="checkbox"
            [disabled]="!districtAdminVerification"
            role="switch"
            id="flexSwitchCheckChecked"
            [(ngModel)]="stateAdminApproval"
            [ngStyle]="{
              'background-color': stateAdminApproval ? 'green' : '',
              'border-color': stateAdminApproval ? 'green' : ''
            }"
          />

          <label
            class="form-check-label instockLabel"
            for="flexSwitchCheckChecked"
            >Approved by State Admin</label
          >
        </div>
      </div>
    </div>
    
     <!-- <div class="ribbon" [ngClass]="{'red-ribbon': editProducts.sellOnline == 'true'}"> 
      <span class="popular" [ngClass]="{'Online': editProducts.sellOnline}">
       {{ editProducts.sellOnline == 'true' ? 'Online' : 'Offline' }}
     </span>  -->
    <!--first page starts-->
    <div class="div mt-4" *ngIf="!selectCatagory">
      <div class="row">
        <div class="col card p-4 m-3">
          <div class="row mb-3">
         <div class="col ps-0"> 
          <div class="col ribbon ps-0 " *ngIf="!editProducts.sellOnline" >
          <span class="popular2" >Offline</span>
      </div>
    
     
      <div class="col ribbon ps-0" *ngIf="editProducts.sellOnline">
          <span class="popular">Online</span>
      </div></div>
            <div class="col">
              <div class="form-check form-switch">
                <input
                  class="form-check-input instockToggle"
                  type="checkbox"
                  [disabled]="!edit"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  [(ngModel)]="instock"
                  [ngStyle]="{
                    'background-color': instock ? 'green' : '',
                    'border-color': instock ? 'green' : ''
                  }"
                />
                <label
                  class="form-check-label instockLabel"
                  for="flexSwitchCheckChecked"
                  >Instock</label
                >
              </div>
            </div>
          </div>
          <div class="row  mb-3 ">
            <div class="col">
              <div class="form-check form-switch">
                <input
                  class="form-check-input onlineToggle"
                  type="checkbox"
                  [disabled]="!edit"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  [(ngModel)]="isOnline"
                  [ngStyle]="{
                    'background-color': editProducts.sellOnline? 'green' : '',
                    'border-color': editProducts.sellOnline? 'green' : ''
                  }"
                />
                <label
                  class="form-check-label onlineLabel"
                  for="flexSwitchCheckChecked"
                  ><span>{{ editProducts.sellOnline ? 'Online' : 'Offline' }}</span>
                  </label
                >
              </div>
            </div>
          </div>
    
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for=" ProductName" class="form-label"
                  >Product Name <span style="color: red">*</span></label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="productName"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="productName"
                  maxlength="50"
                  placeholder="Enter Product Name"
                />
              </div>
            </div>
            
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for=" HSNcode" class="form-label">HSN code </label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="HSNcode"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="HSNcode"
                  placeholder="Enter HSNcode"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="productPrice" class="form-label"
                  >MRP Price<span style="color: red">*</span></label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="productPrice"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="productPrice"
                  maxlength="50"
                  placeholder="Enter MRP Price"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 4) this.value = this.value.slice(0, 4);"
                />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for=" sellingPrice" class="form-label"
                  >Selling Price <span style="color: red">*</span></label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="sellingPrice"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="sellingPrice"
                  placeholder="Enter Selling Price"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                />
              </div>
            </div>
            <div class="col">
              <label for="inputState" class="form-label"> Select Unit </label>
              <select
                id="inputState productUnit"
                class="form-select inputBoxes"
                [(ngModel)]="productUnitType"
                [disabled]="!edit"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="Nos" selected>Nos</option>
                <option value="gm">gm</option>
                <option value="Kg">Kg</option>
                <option value="Packet">Packet</option>
                <option value="Dozen">Dozen</option>
                <option value="litre">Litre</option>
                <option value="Bottle">Bottle</option>
                <option value="Bottle">Box</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="productDescription" class="form-label"
                  >Product Description <span style="color: red">*</span></label
                >
                <textarea
                  class="form-control"
                  rows="3"
                  [disabled]="!edit"
                  placeholder="Enter Product Description "
                  id="floatingTextarea"
                  [(ngModel)]="productDescription"
                  [ngModelOptions]="{ standalone: true }"
                  maxlength="2500"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="Product Ingredients" class="form-label"
                  >Product Ingredients / Additional Information
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  [disabled]="!edit"
                  placeholder="Enter Product Ingredients / Additional Information "
                  id="floatingTextarea"
                  [(ngModel)]="ingredients"
                  [ngModelOptions]="{ standalone: true }"
                  maxlength="1000"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-5">
              <button
                type="button"
                [disabled]="!edit"
                class="btn btn-primary"
                style="width: 100%"
                (click)="catagoryOnClick()"
              >
                Categories
              </button>
            </div>
            <div class="col pe-0">
              <label for="productCatagories" class="form-label"
                >{{ productCat }}<span style="color: red">*</span></label
              >
            </div>

            <div class="row pt-3 pe-0">
              <!-- First Dropdown -->
              <div class="col pt-0 align-items-center">
                <label for="inputState" class="form-label">
                  <p class="labelText">Select Variant</p>
                </label>
                <select
                  [disabled]="!edit"
                  id="inputState"
                  class="form-select inputBoxes"
                  [(ngModel)]="selectedVariant1"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let option of firstDropDown" [value]="option">
                    {{ option }}
                  </option>
                </select>
                <br />

                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="variant1"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control variantclass"
                  placeholder="Enter {{ selectedVariant1 }}"
                  maxlength="250"
                />
              </div>

              <!-- Second Dropdown -->
              <div class="col">
                <label for="inputStateDisplay" class="form-label">
                  <p class="labelText">Select Variant</p>
                </label>
                <select
                  [disabled]="!edit"
                  id="inputStateDisplay"
                  class="form-select inputBoxes"
                  [(ngModel)]="selectedVariant2"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option
                    *ngFor="let option of secondDropDown"
                    [value]="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <br />
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="variant2"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control variantclass"
                  placeholder="Enter {{ selectedVariant2 }}"
                  maxlength="250"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for=" availableQuantity" class="form-label"
                  >Available Quantity</label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="availableQuantity"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="availableQuantity"
                  placeholder="Enter Available Quantity"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 5) this.value = this.value.slice(0, 5);"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mb-3 d-flex align-items-center">
              <div class="form-check">
                <label class="form-check-label" for="defaultCheck1">
                  Is returnable?
                </label>
                <input
                  class="form-check-input returncheck"
                  [disabled]="!edit"
                  type="checkbox"
                  [(ngModel)]="returnable"
                  id="defaultCheck1"
                />
              </div>
            </div>

            <div class="col" *ngIf="returnable">
              <div class="mb-1">
                <label for="  returnPeriod" class="form-label"
                  >Return Period (in days)</label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="returnPeriod"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="returnPeriod"
                  placeholder="Enter Return Period"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 2) this.value = this.value.slice(0, 2);"
                />
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6"><label for="">Order Cancellation:</label></div>

            <div class="col">
              <div class="form-check">
                <input
                  [disabled]="!edit"
                  class="form-check-input"
                  [(ngModel)]="orderCancellation"
                  [ngModelOptions]="{ standalone: true }"
                  type="radio"
                  name="flexRadioDefaul3"
                  id="flexRadioDefault3"
                  value="Yes"
                />
                <label class="form-check-label" for="flexRadioDefault3"
                  >Yes
                </label>
              </div>
            </div>
            <div class="col">
              <div class="form-check">
                <input
                  [disabled]="!edit"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault3"
                  id="flexRadioDefault3"
                  [(ngModel)]="orderCancellation"
                  [ngModelOptions]="{ standalone: true }"
                  value="No"
                />
                <label class="form-check-label" for="flexRadioDefault3">
                  No
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="cgst" class="form-label">CGST (%) </label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="cgst"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="cgst"
                  maxlength="4"
                  placeholder="Enter CGST"
                  pattern="^\d+(\.\d{1,2})?$"
                  (keypress)="validateNumberInput($event)"
                />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for=" sgst" class="form-label">SGST (%)</label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="sgst"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="sgst"
                  maxlength="4"
                  placeholder="Enter SGST"
                  pattern="^\d+(\.\d{1,2})?$"
                  (keypress)="validateNumberInput($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col card p-4 m-3">
          <div class="card p-2 mb-2">
            <h6>Product Specification</h6>

            <div class="row ps-2">
              <div class="col">
                <div class="mb-3">
                  <label for="  productLength" class="form-label">
                    Length (cm)
                  </label>

                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productLength"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productLength"
                    maxlength="5"
                    placeholder="Enter length"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <label for="productBreadth" class="form-label"
                    >Breadth(cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productBreadth"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productBreadth"
                    maxlength="5"
                    placeholder="Enter Breadth"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="  ProductHeight" class="form-label"
                    >Height (cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productHeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productHeight"
                    maxlength="5"
                    placeholder="Enter Height"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>

            <div class="row p-2">
              <div class="col">
                <div class="mb-3">
                  <label for="  ProductWeight" class="form-label"
                    >Product Weight (kg)
                  </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productWeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productWeight"
                    maxlength="5"
                    placeholder="Enter Product Weight"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card p-2 mb-2">
            <h6>Package Specification</h6>
            <div class="row ps-2">
              <div class="col">
                <div class="mb-3">
                  <label for="packageLength" class="form-label">
                    Length (cm)</label
                  >

                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageLength"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageLength"
                    maxlength="5"
                    placeholder="Enter length"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <label for="packageBreadth" class="form-label"
                    >Breadth(cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageBreadth"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageBreadth"
                    maxlength="5"
                    placeholder="Enter Breadth"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for=" packageHeight" class="form-label"
                    >Height (cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageHeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageHeight"
                    maxlength="5"
                    placeholder="Enter Height"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>

            <div class="row p-2">
              <div class="col">
                <div class="mb-3">
                  <label for="  packageWeight" class="form-label"
                    >Package Weight (kg)
                  </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageWeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageWeight"
                    maxlength="5"
                    placeholder="Enter Package Weight"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card p-2 mb-3">
            <h6>Order Quantity</h6>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="  minQuantity" class="form-label">Minimum </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="minimumOrderQuantity"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="minimumOrderQuantity"
                    maxlength="50"
                    placeholder="Enter Minimum Order Quantity"
                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                  />
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <label for="  maxOrderQuantity" class="form-label"
                    >Maximum
                  </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="maximumOrderQuantity"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="maximumOrderQuantity"
                    maxlength="50"
                    placeholder="Enter Maximum Order Quantity"
                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card p-2 mb-3">
            <div class="row">
              <div class="col">
                <!--Image upload section starts-->
                <div
                  *ngIf="
                    (checkProductImage &&
                      checkProductImage.endsWith('_thumbnailImage.jpg')) ||
                    checkProductImage == ''
                  "
                >
                  <div class="row">
                    <label>
                      <h6>Upload Images:<span style="color: red">*</span></h6>
                    </label>
                  </div>
                  <div class="container p-3">
                    <div class="row">
                      <div class="col">
                        <div class="imagePreview">
                          <img
                            class="uploadedImage"
                            [src]="
                              selectedImageUrls[0] ||
                              productImage1 ||
                              defaultImages
                            "
                            alt="selected images"
                            (click)="
                              openImageModal(
                                selectedImageUrls[0] ||
                                  productImage1 ||
                                  defaultImages
                              )
                            "
                          />
                          <button
                            [disabled]="!edit"
                            type="button"
                            (click)="fileInput1.click()"
                            class="btn btn-light imageUploadButtons btn-sm transparent-button"
                          >
                            Front side
                            <span style="color: red">*</span>
                          </button>
                          <input
                            type="file"
                            accept="image/*"
                            #fileInput1
                            style="display: none"
                            (change)="onImageSelected($event, 0)"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <ng-container>
                          <!-- Show "+" sign when the first image is not selected -->
                          <div
                            class="add-image-placeholder"
                            *ngIf="!selectedImageUrls[0] && !productImage1"
                          >
                            <i class="fas fa-plus"></i>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="selectedImageUrls[0] || productImage1"
                        >
                          <div class="imagePreview">
                            <img
                              class="uploadedImage"
                              [src]="
                                selectedImageUrls[1] ||
                                productImage2 ||
                                defaultImages
                              "
                              alt="selected images"
                              (click)="
                                openImageModal(
                                  selectedImageUrls[1] ||
                                    productImage2 ||
                                    defaultImages
                                )
                              "
                            />
                            <button
                              [disabled]="!edit"
                              type="button"
                              (click)="fileInput2.click()"
                              class="btn btn-light imageUploadButtons btn-sm transparent-button"
                            >
                              Back side
                              <span style="color: red">*</span>
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              #fileInput2
                              style="display: none"
                              (change)="onImageSelected($event, 1)"
                            />
                          </div>
                        </ng-container>
                      </div>
                      <div class="col">
                        <ng-container>
                          <!-- Show "+" sign when the first image is not selected -->
                          <div
                            class="add-image-placeholder"
                            *ngIf="!selectedImageUrls[1] && !productImage2"
                          >
                            <i class="fas fa-plus"></i>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="selectedImageUrls[1] || productImage2"
                        >
                          <div class="imagePreview">
                            <img
                              class="uploadedImage"
                              [src]="
                                selectedImageUrls[2] ||
                                productImage3 ||
                                defaultImages
                              "
                              alt="selected images"
                              (click)="
                                openImageModal(
                                  selectedImageUrls[2] ||
                                    productImage3 ||
                                    defaultImages
                                )
                              "
                            />
                            <button
                              [disabled]="!edit"
                              type="button"
                              (click)="fileInput3.click()"
                              class="btn btn-light imageUploadButtons btn-sm transparent-button"
                            >
                              Select Image
                              <span style="color: red">*</span>
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              #fileInput3
                              style="display: none"
                              (change)="onImageSelected($event, 2)"
                            />
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Image upload section ends-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!selectCatagory">
      <div class="col"></div>
      <div class="col" style="text-align: right;">
        <div
          *ngIf="spinnerButton"
          class="spinner-border text-success"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>

        <button
          *ngIf="showRejectButton()"
          class="btn btn-danger"
          (click)="rejectProduct()"
        >
          Reject Product
        </button>
        &nbsp;&nbsp;&nbsp;
        <button type="button" (click)="editProduct()" class="btn btn-secondary">
          Edit product
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          *ngIf="!spinnerButton"
          (click)="updateProducts()"
          type="button"
          class="btn btn-success"
          style="width: 40%;"
        >
          Update
        </button>
      </div>
    </div>
    <!--first page ends-->

    <br />

    <!--second page starts-->
    <div class="row" *ngIf="selectCatagory">
      <div class="col p-0">
        <div class="d-flex">
          <div class="nav scrollable-container">
            <div
              class="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                class="nav-link selectCategoryTabs active"
                id="v-pills-FreshVegetables&Fruits-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-FreshVegetables&Fruits"
                type="button"
                role="tab"
                aria-controls="v-pills-FreshVegetables&Fruits"
                aria-selected="true"
                (click)="scrollToTop()"
              >
                Fresh Vegetables & Fruits
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-FreshFish&Meat-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-FreshFish&Meat"
                type="button"
                role="tab"
                aria-controls="v-pills-FreshFish&Meat"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Fresh Fish & Meat
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Foodproducts-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Foodproducts"
                type="button"
                role="tab"
                aria-controls="v-pills-Foodproducts"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Food products
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Milk-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Milk"
                type="button"
                role="tab"
                aria-controls="v-pills-Milk"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Milk
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Millet-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Millet"
                type="button"
                role="tab"
                aria-controls="v-pills-Millet"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Millet
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Spices-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Spices"
                type="button"
                role="tab"
                aria-controls="v-pills-Spices"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Spices
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-HomeandKitchen-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-HomeandKitchen"
                type="button"
                role="tab"
                aria-controls="v-pills-HomeandKitchen"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Home and Kitchen
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Fashion-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Fashion"
                type="button"
                role="tab"
                aria-controls="v-pills-Fashion"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Fashion
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Personalcare-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Personalcare"
                type="button"
                role="tab"
                aria-controls="v-pills-Personalcare"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Personal care
              </button>
  
              <!-- <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Tribalproducts-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Tribalproducts"
                type="button"
                role="tab"
                aria-controls="v-pills-Tribalproducts"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Tribal products
              </button> -->
  
              <!-- <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Budsproducts-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Budsproducts"
                type="button"
                role="tab"
                aria-controls="v-pills-Budsproducts"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Buds products
              </button> -->
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Ecofriendlyproducts-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Ecofriendlyproducts"
                type="button"
                role="tab"
                aria-controls="v-pills-Ecofriendlyproducts"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Eco friendly products
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Herbalproducts-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Herbalproducts"
                type="button"
                role="tab"
                aria-controls="v-pills-Herbalproducts"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Herbal products
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-GemandJewellery-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-GemandJewellery"
                type="button"
                role="tab"
                aria-controls="v-pills-GemandJewellery"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Gem and Jewellery
              </button>
  
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Stationary-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Stationary"
                type="button"
                role="tab"
                aria-controls="v-pills-Stationary"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Stationary
              </button>
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Toys-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Toys"
                type="button"
                role="tab"
                aria-controls="v-pills-Toys"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Toys
              </button>
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-Electronics-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Electronics"
                type="button"
                role="tab"
                aria-controls="v-pills-Electronics"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Electronics
              </button>
            </div>
          </div>
          <div class="tab-content" id="v-pills-tabContent">
            <!--fresh veg and fruits start-->
            <div
              class="tab-pane fade show active"
              id="v-pills-FreshVegetables&Fruits"
              role="tabpanel"
              aria-labelledby="v-pills-FreshVegetables&Fruits-tab"
            >
              <div class="container-fluid">
                <br />
  
                <div class="row producttextalign">
                  <div class="col">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/vegetables.png"
                              alt="Fresh Veg"
                              id="ID_FVG"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FVG'
                              }"
                              (click)="
                                selectButton('ID_FVG', 'Fresh Veg/Fresh Veg')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Fresh Veg</p>
                  </div>
                  <div class="col">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fruits.png"
                              alt="Fruits"
                              id="ID_FRU"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FRU'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FRU',
                                  'Fresh Vegetables & Fruits/Fruits'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Fruits</p>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- FreshFish&Meat -->
            <div
              class="tab-pane fade"
              id="v-pills-FreshFish&Meat"
              role="tabpanel"
              aria-labelledby="v-pills-FreshFish&Meat-tab"
            >
              <div class="container-fluid">
                <br />
                <div class="row producttextalign">
                  <div class="col">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fish.png"
                              alt="Fresh fish"
                              id="ID_FFS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FFS'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FFS',
                                  'Fresh Fish & Meat/Fresh fish'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Fresh fish</p>
                  </div>
                  <div class="col">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/meat.png"
                              alt="meat"
                              id="ID_FFM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FFM'
                              }"
                              (click)="
                                selectButton('ID_FFM', 'Fresh Fish & Meat/Meat')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Meat</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Food products -->
            <div
              class="tab-pane fade"
              id="v-pills-Foodproducts"
              role="tabpanel"
              aria-labelledby="v-pills-Foodproducts-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/chips.png"
                              alt="Snacks"
                              id="ID_CHI"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHI',
                              }"
                              (click)="
                                selectButton('ID_CHI', 'Food products/Snacks')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Snacks</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/cakemix.png"
                              alt="Cake & cookies"
                              id="ID_CAB"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CAB'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CAB',
                                  'Food products/Cake & cookies'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Cake & cookies</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Chocolate.png"
                              alt="Sweets & chocolates"
                              id="ID_SWT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SWT',
                              }"
                              (click)="
                                selectButton(
                                  'ID_SWT',
                                  'Food products/Sweets & chocolates'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Sweets & chocolates</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/idlidosamix.png"
                              alt="Instant mix"
                              id="ID_DIM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_DIM',
                              }"
                              (click)="
                                selectButton(
                                  'ID_DIM',
                                  'Food products/Instant mix'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Instant mixes</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/healthmix.png"
                              alt="Health mix"
                              id="ID_HEA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HEA'
                              }"
                              (click)="
                                selectButton('ID_HEA', 'Food products/Health mix')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Health mix</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/babyproduct.png"
                              alt="Baby foods"
                              id="ID_BAB"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BAB'
                              }"
                              (click)="
                                selectButton('ID_BAB', 'Food products/Baby foods')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Baby foods</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/mustardoil.png"
                              alt="Edible oils"
                              id="ID_COC"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COC',
                              }"
                              (click)="
                                selectButton(
                                  'ID_COC',
                                  'Food products/Edible oils'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Edible oils</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Milk.png"
                              alt="Diary products"
                              id="ID_MCH"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MCH'
                              }"
                              (click)="
                                selectButton(
                                  'ID_MCH',
                                  'Food products/Diary products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Diary products</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/kudumbashreeghee.png"
                              alt="Ghee"
                              id="ID_GHE"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_GHE'
                              }"
                              (click)="
                                selectButton('ID_GHE', 'Food products/Ghee')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Ghee</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/ragi.png"
                              alt="Pulses"
                              id="ID_GRP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_GRP',
                              }"
                              (click)="
                                selectButton('ID_GRP', 'Food products/Pulses')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Pulses</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/maidha.png"
                              alt="Flour"
                              id="ID_CFL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CFL'
                              }"
                              (click)="
                                selectButton('ID_CFL', 'Food products/Flours')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Flours</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/rice.png"
                              alt="Rice"
                              id="ID_MAT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MAT'
                              }"
                              (click)="
                                selectButton('ID_MAT', 'Food products/Rice')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Rice</p>
                  </div>
                </div>
  
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/honey.png"
                              alt="Honey"
                              id="ID_HON"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HON',
                              }"
                              (click)="
                                selectButton('ID_HON', 'Food products/Honey')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Honey</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/jams.png"
                              alt="Jam & Spreads"
                              id="ID_JAM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_JAM'
                              }"
                              (click)="
                                selectButton(
                                  'ID_JAM',
                                  'Food products/Jam & Spreads'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Jam & Spreads</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/cakeandbread.png"
                              alt="Bread"
                              id="ID_BRE"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BRE'
                              }"
                              (click)="
                                selectButton('ID_BRE', 'Food products/Bread')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Breads</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/eggs.png"
                              alt="Egg"
                              id="ID_EGG"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_EGG',
                              }"
                              (click)="
                                selectButton('ID_EGG', 'Food products/Egg')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Eggs</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/dryfish.png"
                              alt="Dry Fish"
                              id="ID_FIS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FIS'
                              }"
                              (click)="
                                selectButton('ID_FIS', 'Food products/Dry Fish')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Dry Fish</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/pappadam.png"
                              alt="Pappads"
                              id="ID_PPD"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PPD'
                              }"
                              (click)="
                                selectButton('ID_PPD', 'Food products/Pappads')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Pappads</p>
                  </div>
                </div>
                <div class="row producttextalign"></div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/coffee.png"
                              alt="Tea & Coffee"
                              id="ID_TEA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_TEA',
                              }"
                              (click)="
                                selectButton(
                                  'ID_TEA',
                                  'Food products/Tea & Coffee'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Tea & Coffee</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/squash.png"
                              alt="Squash & fruit pulp"
                              id="ID_SQA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SQA',
                              }"
                              (click)="
                                selectButton(
                                  'ID_SQA',
                                  'Food products/Squash & fruit pulp'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Squash & fruit pulp</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/beverages.png"
                              alt="Beverages"
                              id="ID_SQU"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SQU'
                              }"
                              (click)="
                                selectButton('ID_SQU', 'Food products/Beverages')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Beverages</p>
                  </div>
                  <div class="row producttextalign">
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/dates.png"
                                alt="dates"
                                id="ID_DAT"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_DAT'
                                }"
                                (click)="
                                  selectButton(
                                    'ID_DAT',
                                    'Food products/DryFruits'
                                  )
                                "
                            /></a>
                          </div>
                        </div>
                      </div>
                      <p>DryFruits</p>
                    </div>
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/noodles.png"
                                alt="Noodles"
                                id="ID_NOD"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_NOD'
                                }"
                                (click)="
                                  selectButton('ID_NOD', 'Food products/Noodles')
                                "
                            /></a>
                          </div>
                        </div>
                      </div>
                      <p>Noodles</p>
                    </div>
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/jaggerypowder.png"
                                alt="Jaggery"
                                id="ID_JAG"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_JAG'
                                }"
                                (click)="
                                  selectButton('ID_JAG', 'Food products/Jaggery')
                                "
                            /></a>
                          </div>
                        </div>
                      </div>
                      <p>Jaggery</p>
                    </div>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/moringa.png"
                              alt="Muringa Products"
                              id="ID_MUR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MUR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_MUR',
                                  'Food products/Muringa Products '
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Muringa Products</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/masala.png"
                              alt="Masalas"
                              id="ID_CHP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHP'
                              }"
                              (click)="
                                selectButton('ID_CHP', 'Food products/Masalas')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Masalas</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/mangopickle.png"
                              alt="Pickles, chaats & chutneys"
                              id="ID_CHA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHA'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CHA',
                                  'Food products/Pickles, chaats & chutneys'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Pickles, chaats & chutneys</p>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- kitchen -->
            <div
              class="tab-pane fade"
              id="v-pills-Milk"
              role="tabpanel"
              aria-labelledby="v-pills-Milk-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Milk</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Milk.png"
                              alt="Milk"
                              id="ID_MLK"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MLK'
                              }"
                              (click)="selectButton('ID_MLK', 'Milk /Milk')"
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Milk</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-Millet"
              role="tabpanel"
              aria-labelledby="v-pills-Millet-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Millet</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/millet.png"
                              alt="Millet"
                              id="ID_MLT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MLT'
                              }"
                              (click)="selectButton('ID_MLT', 'Millet /Millet')"
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Millet</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- spices -->
            <div
              class="tab-pane fade"
              id="v-pills-Spices"
              role="tabpanel"
              aria-labelledby="v-pills-Spices-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Spices</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/spices.png"
                              alt="Spices"
                              id="ID_PEP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PEP'
                              }"
                              (click)="selectButton('ID_PEP', 'Spices /Spices')"
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Spices</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- home n kitchen -->
            <div
              class="tab-pane fade"
              id="v-pills-HomeandKitchen"
              role="tabpanel"
              aria-labelledby="v-pills-HomeandKitchen-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Home Decor</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/furnitures.png"
                              alt="Furnitures"
                              id="ID_FUR"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_FUR',
                            }"
                              (click)="
                                selectButton(
                                  'ID_FUR',
                                  'Home & Kitchen/Furnitures'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Furnitures</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/wallShelves.png"
                              alt="Wall shelves and hooks"
                              id="ID_WAS"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_WAS',
                            }"
                              (click)="
                                selectButton(
                                  'ID_WAS',
                                  'Home & Kitchen/Wall shelves and hooks'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Wall shelves and hooks</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/paintings.png"
                              alt="Paintings and Hangings"
                              id="ID_PAH"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_PAH',
                            }"
                              (click)="
                                selectButton(
                                  'ID_PAH',
                                  'Home & Kitchen/Paintings and Hangings'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Paintings and Hangings</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Kitchen Accessories</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/iron.png"
                              alt="Iron & Steel"
                              id="ID_IAS"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_IAS',
                            }"
                              (click)="
                                selectButton(
                                  'ID_IAS',
                                  'Home & Kitchen/Iron & Steel'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Iron & Steel</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/crockery.png"
                              alt="Crockery Items"
                              id="ID_CRO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CRO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CRO',
                                  'Home & Kitchen/Crockery Items'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Crockery Items</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/racks.png"
                              alt=" Racks and Organisers"
                              id="ID_RAO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_RAO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_RAO',
                                  'Home & Kitchen/Racks and Organisers'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Racks and Organisers</p>
                  </div>
                </div>
  
                <div class="row producttextalign">
                  <h6>Home Essentials</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/umbrella.png"
                              alt="umbrella"
                              id="ID_UIB"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_UIB',
                            }"
                              (click)="
                                selectButton('ID_UIB', 'Home & Kitchen/umbrella')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Umbrella</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/lights.png"
                              alt="Lights and Candles"
                              id="ID_LAC"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_LAC'
                              }"
                              (click)="
                                selectButton(
                                  'ID_LAC',
                                  'Home & Kitchen/Lights, Candles, Agarbattis'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Lights, Candles, Agarbattis</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/carpets.png"
                              alt=" Carpets and Floormats"
                              id="ID_CAF"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CAF'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CAF',
                                  'Home & Kitchen/Carpets and Floormats'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Carpets and Floormats</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Handicrafts</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/wood.png"
                              alt="wooden"
                              id="ID_WUD"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_WUD',
                            }"
                              (click)="
                                selectButton(
                                  'ID_WUD',
                                  'Home & Kitchen/Wooden handicraft'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Wooden Handicraft</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/steelhandicraft.png"
                              alt="steel Handicraft"
                              id="ID_STL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_STL'
                              }"
                              (click)="
                                selectButton(
                                  'ID_STL',
                                  'Home & Kitchen/Steel Handicraft'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Steel Handicraft</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/clay.png"
                              alt="Clay products"
                              id="ID_CLY"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CLY'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CLY',
                                  'Home & Kitchen/Clay products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Clay products</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/elephant.png"
                              alt="elephantcaparison"
                              id="ID_NTP"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_NTP',
                            }"
                              (click)="
                                selectButton(
                                  'ID_NTP',
                                  'Home & Kitchen/Nettipattom'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Nettipattom</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/aaranmula.png"
                              alt="Aranmula Kannadi"
                              id="ID_ARN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_ARN'
                              }"
                              (click)="
                                selectButton(
                                  'ID_ARN',
                                  'Home & Kitchen/Aranmula Kannadi'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Aranmula Kannadi</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/paper.png"
                              alt="Paper Crafts"
                              id="ID_PAP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PAP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_PAP',
                                  'Home & Kitchen/Paper Crafts'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Paper Crafts</p>
                  </div>
                </div>
  
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/coir.png"
                              alt="Coir Crafts"
                              id="ID_COI"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_COI',
                            }"
                              (click)="
                                selectButton(
                                  'ID_COI',
                                  'Home & Kitchen/Coir Crafts'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Coir Crafts</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bamboo.png"
                              alt="Bamboo products"
                              id="ID_BAM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BAM'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BAM',
                                  'Home & Kitchen /Bamboo products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Bamboo products</p>
                  </div>
                </div>
  
                <div class="row producttextalign">
                  <h6>Lotions & Cleaning supplies</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/WashingSoap.png"
                              alt="cleaning"
                              id="ID_SOD"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_SOD',
                            }"
                              (click)="
                                selectButton(
                                  'ID_SOD',
                                  'Home & Kitchen/Washing soaps'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Washing soaps</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/floorcleaner.png"
                              alt="Floor cleaner"
                              id="ID_FLR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FLR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FLR',
                                  'Home & Kitchen/Floor cleaner'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Floor cleaner</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/lotions.png"
                              alt="Lotions"
                              id="ID_LOT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_LOT'
                              }"
                              (click)="
                                selectButton('ID_LOT', 'Home & Kitchen/Lotions')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Lotions</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/handwash.png"
                              alt="Hand wash"
                              id="ID_HAW"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_HAW',
                            }"
                              (click)="
                                selectButton('ID_HAW', 'Home & Kitchen/Hand wash')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Hand wash</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/carwash.png"
                              alt="Car wash"
                              id="ID_CAR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CAR'
                              }"
                              (click)="
                                selectButton('ID_CAR', 'Home & Kitchen/Car wash')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Car wash</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/detergents.png"
                              alt="Detergents"
                              id="ID_DET"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_DET'
                              }"
                              (click)="
                                selectButton(
                                  'ID_DET',
                                  'Home & Kitchen/Detergents'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Detergents</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/dishwash.png"
                              alt="Dish wash liquid and powders"
                              id="ID_DIS"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_DIS',
                            }"
                              (click)="
                                selectButton(
                                  'ID_DIS',
                                  'Home & Kitchen/Dish wash liquid and powders'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Dish wash liquid and powders</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Home Textiles</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/towel.png"
                              alt="Towels"
                              id="ID_TOW"
                              class="categoryLinks"
                              [ngClass]="{
                               selected: selectedCategoryID === 'ID_TOW',
                             }"
                              (click)="
                                selectButton('ID_TOW', 'Home & Kitchen/Towels')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Towels</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bedsheets.png"
                              alt="bedSheets"
                              id="ID_BED"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BED'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BED',
                                  'Home & Kitchen /Bed Sheets & Pillow Covers'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Bed Sheets & Pillow Covers</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Plants and Gardening</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/indoor.png"
                              alt="indoor"
                              id="ID_IND"
                              class="categoryLinks"
                              [ngClass]="{
                               selected: selectedCategoryID === 'ID_IND',
                             }"
                              (click)="
                                selectButton('ID_IND', 'Home & Kitchen/Indoor')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Indoor Plants</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/outdoor.png"
                              alt="Outdoor"
                              id="ID_OUT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_OUT'
                              }"
                              (click)="
                                selectButton(
                                  'ID_OUT',
                                  'Home & Kitchen /Outdoor Plants'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Outdoor Plants</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/seeds.png"
                              alt="seeds"
                              id="ID_SED"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SED'
                              }"
                              (click)="
                                selectButton('ID_SED', 'Home & Kitchen /Seeds')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Seeds</p>
                  </div>
                  <div class="row producttextalign">
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/fertilizers.png"
                                alt="Fertilizers"
                                id="ID_FER"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_FER'
                                }"
                                (click)="
                                  selectButton(
                                    'ID_FER',
                                    'Home & Kitchen /Fertilizers'
                                  )
                                "
                            /></a>
                          </div>
                        </div>
                      </div>
                      <p>Fertilizers</p>
                    </div>
  
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/pesticides.png"
                                alt="Pesticides and repellants"
                                id="ID_PES"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_PES'
                                }"
                                (click)="
                                  selectButton(
                                    'ID_PES',
                                    'Home & Kitchen /Pesticides and repellants'
                                  )
                                "
                            /></a>
                          </div>
                        </div>
                        <p>Pesticides and repellants</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- fashion -->
            <div
              class="tab-pane fade"
              id="v-pills-Fashion"
              role="tabpanel"
              aria-labelledby="v-pills-Fashion-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/menswear.png"
                              alt="Men's wear"
                              id="ID_MEN"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_MEN',
                            }"
                              (click)="
                                selectButton('ID_MEN', 'Fashion/Men\'s wear')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Men's wear</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/women.png"
                              alt="Ladies Wear"
                              id="ID_WOM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_WOM'
                              }"
                              (click)="
                                selectButton('ID_WOM', 'Fashion/Ladies Wear')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Ladies Wear</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/kids.png"
                              alt="Kids Wear"
                              id="ID_KID"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_KID'
                              }"
                              (click)="
                                selectButton('ID_KID', 'Fashion/Kids Wear')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Kids Wear</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/handloom.png"
                              alt="Men's wear"
                              id="ID_HLM"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_HLM',
                            }"
                              (click)="selectButton('ID_HLM', 'Fashion/HandLoom')"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>HandLoom</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sarees.png"
                              alt="Designer sarees"
                              id="ID_FDS"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_FDS',
                            }"
                              (click)="
                                selectButton('ID_FDS', 'Fashion/Designer sarees')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Designer sarees</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/footware.png"
                              alt="FootWear"
                              id="ID_FTW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FTW'
                              }"
                              (click)="
                                selectButton('ID_FTW', 'Fashion/FootWear')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>FootWear</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bags.png"
                              alt="Bags & Wallets"
                              id="ID_BAW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BAW'
                              }"
                              (click)="
                                selectButton('ID_BAW', 'Fashion/Bags & Wallets')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Bags & Wallets</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/suitcase.png"
                              alt="Suitcase"
                              id="ID_FST"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FST'
                              }"
                              (click)="
                                selectButton('ID_FST', 'Fashion/Suitcase')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Suitcase</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- personal care -->
            <div
              class="tab-pane fade"
              id="v-pills-Personalcare"
              role="tabpanel"
              aria-labelledby="v-pills-Personalcare-tab"
            >
              <div class="container-fluid">
                <br />
                <div class="row producttextalign">
                  <h6>Skin Care</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/facewashandscrub.png"
                              alt="facewash&scrubs"
                              id="ID_FAW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FAW'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FAW',
                                  'Personal Care/FaceWash & Scrubs'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>FaceWash & Scrubs</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/creamsandmoisturizer.png"
                              alt="Cream & Moisturizer"
                              id="ID_CRM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CRM'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CRM',
                                  'Personal Care/Cream & Moisturizer'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Creams & Moisturizers</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bodylotions.png"
                              alt="bodylotions and oils"
                              id="ID_BLO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BLO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BLO',
                                  'Personal Care/Body Lotion & Oils'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Body Lotion & Oils</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sunscreenlotions.png"
                              alt="sunscreens"
                              id="ID_SSR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SSR'
                              }"
                              (click)="
                                selectButton('ID_SSR', 'Personal Care/Sunscreens')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Sunscreens</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Hair Care</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/shampooandconditioner.png"
                              alt="Shampoos&Conditioner"
                              id="ID_SHA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SHA'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SHA',
                                  'Personal Care/Shampoos & Conditioners'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Shampoos & Conditioners</p>
                  </div>
  
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/hairoilserumandgel.png"
                              alt="HairOils Serums & Gels "
                              id="ID_HAO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HAO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_HAO',
                                  'Personal Care/HairOils Serums & Gels'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>HairOils Serums & Gels</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/hennapowder.png"
                              alt="Colors & Hennas"
                              id="ID_COL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COL'
                              }"
                              (click)="
                                selectButton(
                                  'ID_COL',
                                  'Personal Care/Colors & Hennas'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Colors & Hennas</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Makeup</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/foundationandconcealers.png"
                              alt="Foundation & Concealers"
                              id="ID_FON"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FON'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FON',
                                  'Personal Care/Foundation & Concealers'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Foundation & Concealers</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/compactandprimers.png"
                              alt="Compact & Primers"
                              id="ID_COP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_COP',
                                  'Personal Care/Compact & Primers'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Compact & Primers</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/eyeshadoweyeliner.png"
                              alt="Kajals & Eyeliners"
                              id="ID_KAJ"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_KAJ'
                              }"
                              (click)="
                                selectButton(
                                  'ID_KAJ',
                                  'Personal Care/Kajals & Eyeliners'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Kajals Eyeliners Eyeshadows</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/lipsticklipliner.png"
                              alt="Lipstick LipLiner LipBalm"
                              id="ID_LST"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_LST'
                              }"
                              (click)="
                                selectButton('ID_LST', 'Personal Care/Lipstick')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Lipsticks LipLiners LipBalms</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/nailpolish.png"
                              alt="Hair accessories & Nail Polish"
                              id="ID_NAP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_NAP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_NAP',
                                  'Personal Care/Hair accessories & Nail Polish'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Hair accessories & Nail Polish</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Fragrance</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/perfumes.png"
                              alt="Perfumes"
                              id="ID_PER"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PER'
                              }"
                              (click)="
                                selectButton('ID_PER', 'Personal Care/Perfumes')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Perfumes</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bodyrollons.png"
                              alt="Rollons"
                              id="ID_ROL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_ROL'
                              }"
                              (click)="
                                selectButton('ID_ROL', 'Personal Care/Rollons')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Rollons</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bodyspray.png"
                              alt="Body Spray"
                              id="ID_BOS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BOS'
                              }"
                              (click)="
                                selectButton('ID_BOS', 'Personal Care/Body Spray')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Body Spray</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Bath & Body</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bodywash.png"
                              alt="Body Wash"
                              id="ID_BOW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BOW'
                              }"
                              (click)="
                                selectButton('ID_BOW', 'Personal Care/Body Wash')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Body Wash</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/soaps.png"
                              alt="Soaps"
                              id="ID_SOA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SOA'
                              }"
                              (click)="
                                selectButton('ID_SOA', 'Personal Care/Soaps')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Soaps</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bodyoils.png"
                              alt="Body Oils"
                              id="ID_BOL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BOL'
                              }"
                              (click)="
                                selectButton('ID_BOL', 'Personal Care/Body Oils')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Body Oils</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bathaccessories.png"
                              alt="Bathing Accessories"
                              id="ID_BAC"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BAC'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BAC',
                                  'Personal Care/Bathing Accessories'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Bathing Accessories</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Sanitary Napkins</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sanitary.png"
                              alt="Sanitary Pads"
                              id="ID_SAP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SAP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SAP',
                                  'Personal Care/Sanitary Napkins'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Sanitary Napkins</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- tribal -->
            <!-- <div
          class="tab-pane fade"
          id="v-pills-Tribalproducts"
          role="tabpanel"
          aria-labelledby="v-pills-Tribalproducts-tab"
        >
          <div class="container-fluid">
        
          
      
              <div class="row producttextalign">
                <h6>Tribalproducts</h6>
                <hr />
                <div class="col-4">
                  <div class="productButtons">
                    <div class="container-grid">
                      <div class="grid-column">
                        <a
                          ><img
                            src="../../assets/subcategoryImages/home&kitchen/tribalproducts.png"
                            alt="Tribal products"
                            id="ID_TRI"
                            class="categoryLinks"
                            [ngClass]="{
                              selected: selectedCategoryID === 'ID_TRI'
                            }"
                            (click)="
                              selectButton(
                                'ID_TRI',
                                'Tribal products /Tribal products'
                              )
                            "
                        /></a>
                      </div>
                    </div>
                  </div>
                  <p>Tribal products</p>
                </div>
              
              </div>
            </div>
          </div> -->
            <!-- buds -->
            <!-- <div
  class="tab-pane fade"
  id="v-pills-Budsproducts"
  role="tabpanel"
  aria-labelledby="v-pills-Budsproducts-tab"
  >
  <div class="container-fluid">
  
  
  
      <div class="row producttextalign">
        <h6>Buds products</h6>
        <hr />
        <div class="col-4">
          <div class="productButtons">
            <div class="container-grid">
              <div class="grid-column">
                <a
                  ><img
                    src="../../assets/subcategoryImages/stationary&toys/buds.png"
                    alt="Buds product"
                    id="ID_BUD"
                    class="categoryLinks"
                    [ngClass]="{
                      selected: selectedCategoryID === 'ID_BUD'
                    }"
                    (click)="
                      selectButton(
                        'ID_BUD',
                        'Buds products/Buds Products'
                      )
                    "
                /></a>
              </div>
            </div>
          </div>
          <p>Buds Products</p>
        </div>
      
      </div>
    </div>
  </div> -->
            <!-- Ecofriendlyproducts -->
            <div
              class="tab-pane fade"
              id="v-pills-Ecofriendlyproducts"
              role="tabpanel"
              aria-labelledby="v-pills-Ecofriendlyproducts-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Eco friendly products</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/paperProducts.png"
                              alt="Paper products"
                              id="ID_PPR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PPR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_PPR',
                                  'Eco friendly products/Paper products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Paper products</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/clothproducts.png"
                              alt="Cloth products"
                              id="ID_CLH"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CLH'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CLH',
                                  'Eco friendly products/Cloth products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Cloth products</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- herbal -->
            <div
              class="tab-pane fade"
              id="v-pills-Herbalproducts"
              role="tabpanel"
              aria-labelledby="v-pills-Herbalproducts-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Herbal products</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/herbalproducts.png"
                              alt="Herbal products"
                              id="ID_HER"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HER'
                              }"
                              (click)="
                                selectButton(
                                  'ID_HER',
                                  'Herbal products/Herbal products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Herbal products</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- jewel -->
            <div
              class="tab-pane fade"
              id="v-pills-GemandJewellery"
              role="tabpanel"
              aria-labelledby="v-pills-GemandJewellery-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Gem and Jewellery</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bangles.png"
                              alt="Bangles"
                              id="ID_BAN"
                              class="categoryLinks"
                              [ngClass]="{
                            selected: selectedCategoryID === 'ID_BAN',
                          }"
                              (click)="
                                selectButton(
                                  'ID_BAN',
                                  'Jewellery & Accessories/Bangles'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Bangles</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/earrings.png"
                              alt="Earings"
                              id="ID_EAR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_EAR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_EAR',
                                  'Jewellery & Accessories/Earings'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Earings</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/nosepins.png"
                              alt="Nosepins"
                              id="ID_NOP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_NOP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_NOP',
                                  'Jewellery & Accessories/Nosepins'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Nosepins</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/anklets.png"
                              alt="Anklets"
                              id="ID_ANK"
                              class="categoryLinks"
                              [ngClass]="{
                            selected: selectedCategoryID === 'ID_ANK',
                          }"
                              (click)="
                                selectButton(
                                  'ID_ANK',
                                  'Jewellery & Accessories/Anklets'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Anklets</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/rings.png"
                              alt="Rings"
                              id="ID_RIN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_RIN'
                              }"
                              (click)="
                                selectButton(
                                  'ID_RIN',
                                  'Jewellery & Accessories/Rings'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Rings</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/chains.png"
                              alt="Chains"
                              id="ID_CHN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHN'
                              }"
                              (click)="selectButton('ID_CHN', 'Fashion/Chains')"
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Chains</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- stationary -->
            <div
              class="tab-pane fade"
              id="v-pills-Stationary"
              role="tabpanel"
              aria-labelledby="v-pills-Stationary-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Stationary</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/pens.png"
                              alt="Pens"
                              id="ID_PEN"
                              class="categoryLinks"
                              [ngClass]="{
                          selected: selectedCategoryID === 'ID_PEN',
                        }"
                              (click)="selectButton('ID_PEN', 'Stationary/Pens')"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Pens</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/pencils.png"
                              alt="pencils"
                              id="ID_PEC"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PEC'
                              }"
                              (click)="
                                selectButton('ID_PEC', 'Stationary/Pencils')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Pencils</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/books.png"
                              alt="books"
                              id="ID_BOK"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BOK'
                              }"
                              (click)="
                                selectButton('ID_BOK', 'Stationary/Books')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Books</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/stationary.png"
                              alt="Stationary"
                              id="ID_STA"
                              class="categoryLinks"
                              [ngClass]="{
                          selected: selectedCategoryID === 'ID_STA',
                        }"
                              (click)="
                                selectButton('ID_STA', 'Stationary/Stationary')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Stationary</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- toys -->
            <div
              class="tab-pane fade"
              id="v-pills-Toys"
              role="tabpanel"
              aria-labelledby="v-pills-Toys-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Electronics</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/toys.png"
                              alt="toys"
                              id="ID_TOY"
                              class="categoryLinks"
                              [ngClass]="{
                      selected: selectedCategoryID === 'ID_TOY',
                    }"
                              (click)="selectButton('ID_TOY', 'Toys/Toys')"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Toys</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- electronics -->
            <div
              class="tab-pane fade"
              id="v-pills-Electronics"
              role="tabpanel"
              aria-labelledby="v-pills-Electronics-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Electronics</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/electronics.png"
                              alt="Electronics"
                              id="ID_ELE"
                              class="categoryLinks"
                              [ngClass]="{
                        selected: selectedCategoryID === 'ID_ELE',
                      }"
                              (click)="
                                selectButton('ID_ELE', 'Electronics/Electronics')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Electronics</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--second page ends-->
  </div>
</div>

<div *ngIf="sellerProfileID">
  <app-service-provider-description></app-service-provider-description>
</div>

<!-- Add this at the end of the file -->
<div *ngIf="showModal" class="modal-overlay" (click)="closeImageModal()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <button class="close-button" (click)="closeImageModal()">&times;</button>
    <img [src]="modalImageUrl" alt="Enlarged image" />
  </div>
</div>
